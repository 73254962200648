/** @format */

import { PropertyMap } from 'model-mapper';
import { EquipmentFamily } from '../_constants/equipment-family';
import { EquipmentStatus } from '../_constants/equipment-status';
import { Searchable, SearchableClass } from '../_decorators/searchable-class.decorator';
import { S3File } from './s3-file.class';

class EquipmentCategoryIcon {
  @PropertyMap({ type: S3File })
  [EquipmentStatus.OK]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.OUT_OF_ORDER]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.STOPPED]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.MAINTENANCE]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.REPARATION]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.NOFLOW]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.INITIALIZING]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.UNKNOWN]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.ND]: S3File;
}

@Searchable({ sortFields: 'family name' })
export class EmbeddedEquipmentCategory extends SearchableClass {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  family: EquipmentFamily;

  @PropertyMap()
  name: string;

  @PropertyMap({ type: EquipmentCategoryIcon })
  icons: EquipmentCategoryIcon;

  // TODO cleanup ?
  @PropertyMap()
  isConsumptionEquipment: boolean;

  // TODO cleanup ?
  @PropertyMap()
  isProductionEquipment: boolean;

  // TODO cleanup ?
  @PropertyMap()
  isEmissionEquipment: boolean;

  // TODO cleanup ?
  @PropertyMap()
  hasModes: boolean;
}
