/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createEntityProperty = /* GraphQL */ `mutation CreateEntityProperty(
  $input: CreateEntityPropertyInput!
  $condition: ModelEntityPropertyConditionInput
) {
  createEntityProperty(input: $input, condition: $condition) {
    id
    entityID
    entityModel
    time
    propertyName
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEntityPropertyMutationVariables,
  APITypes.CreateEntityPropertyMutation
>;
export const updateEntityProperty = /* GraphQL */ `mutation UpdateEntityProperty(
  $input: UpdateEntityPropertyInput!
  $condition: ModelEntityPropertyConditionInput
) {
  updateEntityProperty(input: $input, condition: $condition) {
    id
    entityID
    entityModel
    time
    propertyName
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEntityPropertyMutationVariables,
  APITypes.UpdateEntityPropertyMutation
>;
export const deleteEntityProperty = /* GraphQL */ `mutation DeleteEntityProperty(
  $input: DeleteEntityPropertyInput!
  $condition: ModelEntityPropertyConditionInput
) {
  deleteEntityProperty(input: $input, condition: $condition) {
    id
    entityID
    entityModel
    time
    propertyName
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEntityPropertyMutationVariables,
  APITypes.DeleteEntityPropertyMutation
>;
export const createCacheChanged = /* GraphQL */ `mutation CreateCacheChanged(
  $input: CreateCacheChangedInput!
  $condition: ModelCacheChangedConditionInput
) {
  createCacheChanged(input: $input, condition: $condition) {
    id
    name
    time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCacheChangedMutationVariables,
  APITypes.CreateCacheChangedMutation
>;
export const updateCacheChanged = /* GraphQL */ `mutation UpdateCacheChanged(
  $input: UpdateCacheChangedInput!
  $condition: ModelCacheChangedConditionInput
) {
  updateCacheChanged(input: $input, condition: $condition) {
    id
    name
    time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCacheChangedMutationVariables,
  APITypes.UpdateCacheChangedMutation
>;
export const deleteCacheChanged = /* GraphQL */ `mutation DeleteCacheChanged(
  $input: DeleteCacheChangedInput!
  $condition: ModelCacheChangedConditionInput
) {
  deleteCacheChanged(input: $input, condition: $condition) {
    id
    name
    time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCacheChangedMutationVariables,
  APITypes.DeleteCacheChangedMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    id
    owner
    time
    kind
    read
    context
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    id
    owner
    time
    kind
    read
    context
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    id
    owner
    time
    kind
    read
    context
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
