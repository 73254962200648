import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileUrl',
})
export class FileUrlPipe implements PipeTransform {
  async transform(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (e) => resolve(e.target?.result as string);
      reader.readAsDataURL(file);
    });
  }
}
