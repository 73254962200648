/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatagridModule } from '../../datagrid/datagrid.module';
import { MouvementDataDatagridComponent } from './mouvement-data-datagrid.component';

@NgModule({
  declarations: [MouvementDataDatagridComponent],
  imports: [CommonModule, DatagridModule],
  exports: [MouvementDataDatagridComponent],
})
export class MouvementDataDatagridModule {}
