/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationAddressCellComponent } from './location-address-cell.component';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';

@NgModule({
  declarations: [LocationAddressCellComponent],
  imports: [CommonModule, AppFlexLayoutModule],
  exports: [LocationAddressCellComponent],
})
export class LocationAddressCellModule {}
