/** @format */

import { filter, keys, map, uniq } from 'lodash-es';
import { PropertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { EquipmentCabinCallName } from '../../_constants/equipment-cabin-call-name';
import { EquipmentDoorName } from '../../_constants/equipment-door/equipment-door-name';
import { EquipmentDoorState } from '../../_constants/equipment-door/equipment-door-state';
import { EquipmentFloorKind } from '../../_constants/equipment-floor-kind';
import { Searchable } from '../../_decorators/searchable-class.decorator';
import { User } from '../user.class';
import { Equipment } from './equipment.class';
import { ElevatorSignature } from './equipment.elevator-signature.class';

export class ElevatorLastDoorState {
  @PropertyMap()
  doorStateDataId: string;

  @PropertyMap()
  sesioNodeComponentId: string;

  @PropertyMap()
  doorName: EquipmentDoorName;

  @PropertyMap({ type: Date })
  date: Date;

  @PropertyMap()
  state: EquipmentDoorState;
}

export class ElevatorLastDoorStates {
  @PropertyMap({ type: ElevatorLastDoorState })
  [EquipmentDoorName.DOOR_1]: ElevatorLastDoorState;

  @PropertyMap({ type: ElevatorLastDoorState })
  [EquipmentDoorName.DOOR_2]: ElevatorLastDoorState;

  getLastDoorState(): ElevatorLastDoorState | null {
    let lastDoorState: ElevatorLastDoorState | null = null;
    for (let key of Object.values(EquipmentDoorName)) {
      const doorState = this[key as EquipmentDoorName];
      if (!lastDoorState) lastDoorState = doorState;
      else if (doorState && lastDoorState.date.valueOf() < doorState.date.valueOf()) {
        lastDoorState = doorState;
      }
    }
    return lastDoorState;
  }

  toArray(doorNames?: EquipmentDoorName[]): ElevatorLastDoorState[] {
    doorNames = uniq(doorNames) || (keys(this) as EquipmentDoorName[]);
    return filter(
      map(doorNames, (key: EquipmentDoorName) => this[key]),
      (d) => !!d,
    );
  }
}

export class ElevatorLastMouvement {
  @PropertyMap({ type: 'Moment' })
  start: Moment;

  @PropertyMap({ type: 'Moment', default: null })
  end: Moment;

  @PropertyMap()
  startDistance: number;

  @PropertyMap()
  endDistance: number;
}

export class ElevatorFloorInitializationStop {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  avgDistance: number;

  @PropertyMap()
  minDistance: number;

  @PropertyMap()
  maxDistance: number;

  @PropertyMap()
  occurrence: number;

  @PropertyMap()
  floorId?: string;
}

export class ElevatorFloorInitialization {
  @PropertyMap()
  active: boolean;

  @PropertyMap({ type: User })
  triggeredBy: User;

  @PropertyMap({ type: User })
  canceledBy: User;

  @PropertyMap()
  startDate: Date;

  @PropertyMap()
  endDate: Date;

  @PropertyMap({ type: [ElevatorFloorInitializationStop] })
  stops: ElevatorFloorInitializationStop[];
}

export class ElevatorFloor {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  name: string;

  @PropertyMap()
  shortName: string;

  @PropertyMap()
  distance: number | null;

  @PropertyMap()
  minDistance: number | null;

  @PropertyMap()
  maxDistance: number | null;

  @PropertyMap()
  kind: EquipmentFloorKind;

  @PropertyMap()
  sesioNodeComponentId?: string | null;

  @PropertyMap()
  cabinCall: EquipmentCabinCallName | null;

  @PropertyMap()
  groundDistance: number | null;

  @PropertyMap()
  height: number | null;
}

export class ElevatorCabin {
  @PropertyMap()
  nominalSpeed?: number;

  @PropertyMap()
  blockingSpeed?: number;

  @PropertyMap()
  maximunCharge?: number;
}

export class ElevatorMetadata {
  @PropertyMap()
  prom?: string;

  @PropertyMap()
  monitoringProtocol?: string;

  @PropertyMap()
  cabinPhoneNumber?: string;

  @PropertyMap()
  maintenanceTeam?: string;

  @PropertyMap({ type: ElevatorFloorInitialization })
  floorInitialization?: ElevatorFloorInitialization;

  @PropertyMap({ type: [ElevatorFloor] })
  floors?: ElevatorFloor[];

  @PropertyMap({ type: ElevatorLastMouvement })
  lastMouvement?: ElevatorLastMouvement;

  @PropertyMap({ type: ElevatorLastDoorStates })
  lastDoorStates?: ElevatorLastDoorStates;

  @PropertyMap()
  movementCount: number;

  @PropertyMap()
  distanceCount: number;

  @PropertyMap()
  movementDailyAvg: number;

  @PropertyMap()
  distanceDailyAvg: number;

  @PropertyMap({ type: ElevatorSignature })
  signature?: ElevatorSignature;

  @PropertyMap({ type: ElevatorCabin })
  cabin?: ElevatorCabin;
}

@Searchable({ sortFields: 'reference name' })
export class Elevator extends Equipment {
  @PropertyMap({ type: ElevatorMetadata })
  override metadata?: ElevatorMetadata;
}
