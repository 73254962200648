/** @format */

import { map, values } from 'lodash-es';

export enum EquipmentDoorName {
  DOOR_1 = 'door-1',
  DOOR_2 = 'door-2',
}

export const EquipmentDoorNameName: { [key: string]: string } = {
  [EquipmentDoorName.DOOR_1]: $localize`Porte de service 1`,
  [EquipmentDoorName.DOOR_2]: $localize`Porte de service 2`,
};

export const EquipmentDoorNameOptions = map(values(EquipmentDoorName), (value) => ({
  value,
  name: EquipmentDoorNameName[value],
}));

export const EquipmentDoorNameDatagridOptions = EquipmentDoorNameOptions;
