/** @format */

import { PipeTransform, Pipe } from '@angular/core';
import { last, reduce } from 'lodash-es';

@Pipe({
  name: 'splitInRows',
})
export class SplitInRowsPipe implements PipeTransform {
  transform(data: any[], width = 4): any[][] {
    return reduce(
      data,
      (pv, cv) => {
        const lastEntry = last(pv);
        if (!lastEntry || lastEntry.length >= width) pv.push([cv]);
        else lastEntry.push(cv);
        return pv;
      },
      [] as any[][]
    );
  }
}
