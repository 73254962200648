/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { RealEstateStructureCellComponent } from './real-estate-structure-cell.component';

@NgModule({
  declarations: [RealEstateStructureCellComponent],
  imports: [CommonModule, AppFlexLayoutModule],
  exports: [RealEstateStructureCellComponent],
})
export class RealEstateStructureCellModule {}
