/** @format */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Highcharts from 'highcharts';
import { lastValueFrom } from 'rxjs';
import { SubSink } from 'subsink';
import { Elevator } from '../../../_classes/equipment/equipment.elevator.class';
import { EquipmentDoorState } from '../../../_constants/equipment-door/equipment-door-state';
import { EquipmentStatus } from '../../../_constants/equipment-status';
import { ChartComponent } from '../../chart/chart/chart.component';
import { ElevatorSceneFullscreenDialogDialog } from '../elevator-scene-fullscreen-dialog/elevator-scene-fullscreen-dialog.dialog';

declare const IVSPlayer: any;

@Component({
  selector: 'app-elevator-scene',
  templateUrl: './elevator-scene.component.html',
  styleUrls: ['./elevator-scene.component.scss'],
})
export class ElevatorSceneComponent implements OnInit, AfterViewInit, OnDestroy {
  EquipmentDoorState = EquipmentDoorState;
  EquipmentStatus = EquipmentStatus;
  BoxColor: { [status: string]: string } = {
    [EquipmentStatus.OUT_OF_ORDER]: 'red',
    [EquipmentStatus.MAINTENANCE]: '#ffd238',
    [EquipmentStatus.REPARATION]: '#ffd238',
    [EquipmentStatus.NOFLOW]: '#9564e2',
    [EquipmentStatus.OK]: '#006400',
    [EquipmentStatus.STOPPED]: '#000000',
  };
  BoxSpotColor: { [status: string]: string } = {
    [EquipmentStatus.OUT_OF_ORDER]: 'red',
    [EquipmentStatus.MAINTENANCE]: '#ffd238',
    [EquipmentStatus.REPARATION]: '#ffd238',
    [EquipmentStatus.NOFLOW]: '#9564e2',
    [EquipmentStatus.OK]: '#006400',
    [EquipmentStatus.STOPPED]: '#ffffff',
  };

  @Input()
  elevator: Elevator;

  @Input()
  currentSpeed: number;

  @Input()
  direction: number;

  @Input()
  currentFloorIndex: number | undefined;

  @Input()
  doorState: EquipmentDoorState;

  @Input()
  fullscreen = false;

  @Output('exitFullscreen')
  exitFullscreenEmitter = new EventEmitter();

  hide = false;
  videoZoomed = false;
  ready = false;
  loading = true;

  // camera?: SesioNodeComponent;
  @ViewChild('video')
  videoRef: ElementRef;
  videoStatus: 'stopped' | 'loading' | 'playing' | 'error' = 'stopped';
  latency: number | null;
  // private loadVideoRetry = 3;
  // private liveInterval: any;
  // private url =
  //   'https://de2de120fad9.eu-west-1.playback.live-video.net/api/video/v1/eu-west-1.709389331805.channel.raXsrCfujc2z.m3u8'; // TODO config
  // private player: any;

  speedChartOptions: Highcharts.Options = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: 'transparent',
      plotBorderWidth: 0,
      plotShadow: false,
      height: 200,
      width: 360,
    },
    credits: { enabled: false },
    title: { text: '' },
    exporting: { enabled: false },
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: [{ backgroundColor: 'transparent', borderWidth: 0 }],
      center: ['50%', '100%'],
      size: 300,
    },
    yAxis: {
      min: 0,
      max: 2.6,
      tickPixelInterval: 72,
      tickPosition: 'inside',
      tickColor: '#FFFFFF',
      tickLength: 20,
      tickWidth: 2,
      labels: {
        distance: 18,
        style: {
          fontSize: '14px',
          color: '#ffffff',
        },
      },
      plotBands: [
        {
          from: 0,
          to: 1,
          color: '#DDDF0D', // yellow
          thickness: 20,
        },
        {
          from: 1,
          to: 1.6,
          color: '#55BF3B', // green
          thickness: 20,
        },
        {
          from: 1.6,
          to: 2.6,
          color: '#DF5353', // red
          thickness: 20,
        },
      ],
    },
    plotOptions: {
      gauge: {
        dataLabels: {
          format: '{y:.2f} m/s',
          borderWidth: 0,
          borderRadius: 0,
          color: '#ffffff',
          style: {
            fontSize: '18px',
            textOutline: 'none',
          },
          y: 0,
          x: 70,
        },
        dial: {
          radius: '80%',
          backgroundColor: 'gray',
          baseWidth: 12,
          baseLength: '0%',
          rearLength: '0%',
        },
        pivot: {
          backgroundColor: 'gray',
          radius: 6,
        },
      },
    },
  };

  private subsink = new SubSink();

  constructor(
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // this.sesioNodeComponentService
    //   .list({ equipmentId: this.elevator._id, kinds: [SesioNodeComponentKind.CAMERA] })
    //   .then((camera) => (this.camera = head(camera)));
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.ready = true), 300);
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
    // if (this.liveInterval) clearInterval(this.liveInterval);
    // if (this.player) this.player.delete();
    // if (this.visibilityTM) clearTimeout(this.visibilityTM);
    // if (this.onVisibilityChange) document.removeEventListener('visibilitychange', this.onVisibilityChange, false);
  }

  loaded($event: any) {
    setTimeout(() => {
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    }, 3200);
  }

  async openFullscreenScene() {
    if (this.fullscreen) return this.exitFullscreenEmitter.emit();
    const dialogRef = this.dialog.open(ElevatorSceneFullscreenDialogDialog, {
      height: '100vh',
      maxHeight: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      panelClass: ['dialog-no-padding', 'dialog-no-border-radius'],
      exitAnimationDuration: '300ms',
      data: { elevator: this.elevator, fullscreen: true },
    });
    this.hide = true;
    await lastValueFrom(dialogRef.beforeClosed());
    this.hide = false;
  }

  async loadSpeedChartData(chart: ChartComponent) {
    chart.setSeries([
      {
        name: 'Speed',
        data: [this.currentSpeed],
        tooltip: { valueSuffix: ' m/s' },
      } as any,
    ]);
    chart.setLoading(false);
  }

  // async startVideo() {
  //   this.videoStatus = 'loading';
  //   this.loadVideoRetry = 4;
  //   await this.mqttService.publishToTopic(this.camera!.commandTopic, { action: 'start' });
  //   await this.mqttService.publishToTopic(this.camera!.commandTopic, { action: 'live' });
  //   this.player.load(this.url);
  //   this.player.play();
  //   this.changeDetectorRef.detectChanges();
  // }

  // stopVideo() {
  //   this.loadVideoRetry = 0;
  //   this.videoStatus = 'stopped';
  //   if (this.player.getState() === 'Playing') this.player.pause();
  //   this.changeDetectorRef.detectChanges();
  // }

  // async loadVideo() {
  //   if (IVSPlayer.isPlayerSupported) {
  //     this.visible = document.visibilityState === 'visible';
  //     this.videoStatus = 'stopped';
  //     this.player = IVSPlayer.create();
  //     this.player.attachHTMLVideoElement(this.videoRef?.nativeElement);

  //     this.player.addEventListener('Playing', async () => {
  //       this.logger.debug('Playing');
  //       this.videoStatus = 'playing';
  //       this.latency = this.player.getLiveLatency();
  //       this.changeDetectorRef.detectChanges();
  //     });

  //     this.player.addEventListener('Ended', async (event: any) => {
  //       this.logger.debug('Ended', event);
  //       this.videoStatus = 'stopped';
  //       this.latency = null;
  //       this.changeDetectorRef.detectChanges();
  //     });

  //     this.player.addEventListener('PlayerError', async (event: any) => {
  //       this.logger.debug('PlayerError', event);
  //       if ([101, 404].includes(event.code) && this.loadVideoRetry > 0) {
  //         this.logger.debug('Reload', this.loadVideoRetry, this.loadVideoRetry * 5000);
  //         setTimeout(() => (this.player.load(this.url), this.player.play()), this.loadVideoRetry * 5000);
  //         this.loadVideoRetry -= 1;
  //       } else {
  //         this.videoStatus = 'error';
  //         this.changeDetectorRef.detectChanges();
  //       }
  //     });

  //     this.liveInterval = setInterval(() => {
  //       if (this.visible) {
  //         if (this.player.getState() === 'Playing') {
  //           this.latency = this.player.getLiveLatency();
  //           this.mqttService.publishToTopic(this.camera!.commandTopic, { action: 'live' });
  //         }
  //       }
  //     }, 10000);

  //     document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this), false);
  //   } else {
  //     // TODO hide video
  //   }
  // }

  // private visibilityTM: any = null;
  // private visible = true;
  // private onVisibilityChange() {
  //   if (document.visibilityState === 'hidden') {
  //     this.visibilityTM = setTimeout(() => {
  //       this.visibilityTM = null;
  //       this.visible = false;
  //       this.stopVideo();
  //     }, 8000);
  //   } else {
  //     if (this.visibilityTM) {
  //       clearTimeout(this.visibilityTM);
  //       this.visibilityTM = null;
  //     }
  //     this.visible = true;
  //   }
  // }
}
