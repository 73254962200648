/** @format */

import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { find, forEach, map, split } from 'lodash-es';

type FlexLayoutType = 'row' | 'column' | 'row wrap';
type FlexLayoutAlignType =
  | 'start start'
  | 'start center'
  | 'start end'
  | 'start stretch'
  | 'center start'
  | 'center center'
  | 'center end'
  | 'center stretch'
  | 'end start'
  | 'end center'
  | 'end end'
  | 'end stretch'
  | 'space-between start'
  | 'space-between center'
  | 'space-between end'
  | 'space-between stretch'
  | 'space-around start'
  | 'space-around center'
  | 'space-around end'
  | 'space-around stretch'
  | 'space-evenly start'
  | 'space-evenly center'
  | 'space-evenly end'
  | 'space-evenly stretch'
  | string;

@Directive({
  selector: '[fxLayout], [fxLayoutAlign], [fxLayoutGap]',
})
export class FlexLayoutDirective implements AfterViewInit, OnDestroy {
  @Input('fxLayout')
  set setFxLayout(fxLayout: FlexLayoutType) {
    this.fxLayout = fxLayout;
    this.updateFlexDirection();
    this.updateFlexWrap();
  }
  fxLayout: FlexLayoutType = 'row';

  @Input('fxLayoutAlign')
  set setFxLayoutAlign(fxLayoutAlign: string) {
    this.fxLayoutAlign = fxLayoutAlign;
    this.updatePlaceContent();
    this.updateAlignItems();
  }
  fxLayoutAlign: FlexLayoutAlignType;

  @Input('fxLayoutGap')
  set setFxLayoutGap(fxLayoutGap: string) {
    this.fxLayoutGap = fxLayoutGap;
    this.updateGap();
  }
  fxLayoutGap: string;

  display = 'flex';
  boxSizing = 'border-box';
  flexDirection: string = 'row';
  flexWrap: string | undefined;
  placeContent: string;
  alignItems: string;

  private changeObserver: MutationObserver;

  constructor(private elementRef: ElementRef) {
    this.elementRef.nativeElement.style.setProperty('display', 'flex');
    this.elementRef.nativeElement.style.setProperty('box-sizing', 'border-box');
  }

  ngAfterViewInit(): void {
    this.updateFlexDirection();
    this.updateFlexWrap();
    this.updateGap();
    this.changeObserver = new MutationObserver((mutations) => {
      const childListUpdated = find(
        mutations,
        (mutation) => !!mutation.addedNodes.length || !!mutation.removedNodes.length
      );
      if (childListUpdated) this.updateGap();
    });
    this.changeObserver.observe(this.elementRef.nativeElement, { childList: true });
  }

  ngOnDestroy(): void {
    if (this.changeObserver) this.changeObserver.disconnect();
  }

  private updateFlexDirection() {
    this.flexDirection = this.fxLayout.startsWith('column') ? 'column' : 'row';
    this.elementRef.nativeElement.style.setProperty('flex-direction', this.flexDirection);
  }

  private updateFlexWrap() {
    this.flexWrap = this.fxLayout.includes('wrap') ? 'wrap' : undefined;
    this.elementRef.nativeElement.style.setProperty('flex-wrap', this.flexWrap);
  }

  private updatePlaceContent() {
    const values = map(split(this.fxLayoutAlign, ' '), (value) => this.prefixAlignType(value));
    if (values.length) {
      this.placeContent = `${values[1]} ${values[0]}`;
    } else this.placeContent = this.elementRef.nativeElement.style['place-content'];
    this.elementRef.nativeElement.style.setProperty('place-content', this.placeContent);
  }

  private updateAlignItems() {
    let value = split(this.fxLayoutAlign, ' ')[1];
    this.alignItems = value ? this.prefixAlignType(value) : this.elementRef.nativeElement.style['align-items'];
    this.elementRef.nativeElement.style.setProperty('align-items', this.alignItems);
  }

  private async updateGap() {
    forEach(this.elementRef.nativeElement.children, async (child: HTMLElement, index: number) => {
      if (this.fxLayoutGap && index > 0) {
        const hasStyle = await this.hasGapStyle(child);
        if (!hasStyle) {
          child.style.setProperty(this.getStyleEntry(), this.fxLayoutGap);
        }
      }
    });
  }

  private async hasGapStyle(child: HTMLElement): Promise<boolean> {
    if (typeof child.computedStyleMap === 'function') {
      const style = child.computedStyleMap().get(this.getStyleEntry());
      return !!style && style.toString() !== '0px';
    }
    return new Promise((resolve) =>
      setTimeout(() => {
        const style = getComputedStyle(child).getPropertyValue(this.getStyleEntry());
        return !!style && style !== '0px';
      })
    );
  }

  private getStyleEntry(): string {
    return this.fxLayout.startsWith('row') ? 'margin-left' : 'margin-top';
  }

  private prefixAlignType(type: string): string {
    if (type === 'start') return 'flex-start';
    if (type === 'end') return 'flex-end';
    return type;
  }
}
