/** @format */

import { ModelMapper, PropertyMap } from 'model-mapper';
import { Moment } from 'moment';

export class S3File {
  static getFileType(file: File): string | null {
    if (file.type) return file.type;
    switch (file.name.split('.').pop()) {
      case 'gltf':
        return 'model/gltf+json';
      case 'gltf':
        return 'model/glb+json';
    }
    return null;
  }

  static fromFile(file: File): S3File {
    return new ModelMapper(S3File).map({
      filename: file.name,
      name: file.name,
      size: file.size,
      type: S3File.getFileType(file),
      file,
    });
  }

  @PropertyMap()
  public key: string;

  @PropertyMap()
  public filename: string;

  @PropertyMap()
  public name: string;

  @PropertyMap()
  public size: number;

  @PropertyMap({ type: 'Moment' })
  public uploadDate: Moment;

  @PropertyMap()
  public type: string;

  @PropertyMap()
  public file?: File;
}
