/** @format */

import { Injectable } from '@angular/core';
import { deburr, isNil, kebabCase } from 'lodash-es';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public async set(key: string, value: any): Promise<void> {
    if (isNil(value)) localStorage.removeItem(this.key(key));
    else localStorage.setItem(this.key(key), JSON.stringify(value));
    return Promise.resolve();
  }

  public async get(key: string): Promise<any> {
    const data = localStorage.getItem(this.key(key));
    const res = data ? JSON.parse(data) : null;
    return Promise.resolve(res);
  }

  public async remove(key: string): Promise<void> {
    localStorage.removeItem(this.key(key));
    return Promise.resolve();
  }

  private key(key: string): string {
    return `${kebabCase(deburr(environment.appName))}.${key}`;
  }
}
