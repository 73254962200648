/** @format */

import { Injectable } from '@angular/core';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { User } from '@sentry/angular-ivy';
import { API, graphqlOperation } from 'aws-amplify';
import { get, toLower } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import { NGXLogger } from 'ngx-logger';
import { updateNotification } from '../../graphql/mutations';
import { notificationsByUser } from '../../graphql/queries';
import { onCreateNotification } from '../../graphql/subscriptions';
import { UserNotification, UserNotificationKind } from '../_classes/user-notification.class';
import { NotificationService } from '../_components/notification/notification.service';
import { EquipmentStatusColorName, EquipmentStatusIcon, EquipmentStatusName } from '../_constants/equipment-status';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationService {
  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private logger: NGXLogger,
  ) {
    if (authService.user) this.load(authService.user);
    authService.$user.subscribe(async (user) => this.load(user));
  }

  public async read(notification: UserNotification): Promise<void> {
    const variables = { input: { id: notification.id, read: true } };
    const res = (await API.graphql(graphqlOperation(updateNotification, variables))) as GraphQLResult<any>;
    if (!res.errors?.length) notification.read = true;
  }

  public async list(nextToken?: string): Promise<{ data: UserNotification[]; nextToken?: string }> {
    const variables: any = {
      owner: `${this.authService.user?.sub}::${this.authService.user?.sub}`,
      sortDirection: 'DESC',
      nextToken,
    };
    let data: any[] = [];
    this.logger.debug('notificationsByUser', variables);
    const res = (await API.graphql(graphqlOperation(notificationsByUser, variables))) as any;

    return {
      data: this.buildNotifications(get(res, `data.notificationsByUser.items`, [])),
      nextToken: get(res, 'data.notificationsByUser.nextToken'),
    };
    // do {
    //   const res = (await API.graphql(graphqlOperation(notificationsByUser, variables))) as any;
    //   if (res?.data) {
    //     data = concat(data, this.buildNotifications(get(res, `data.notificationsByUser.items`, []))) as any[];
    //   }
    //   variables.nextToken = get(res, 'data.notificationsByUser.nextToken');
    // } while (variables.nextToken);
    // this.logger.debug('notificationsByUser', data);
    // return data;
  }

  private onCreateSubscription: any;
  private onCreate(): void {
    if (this.onCreateSubscription) this.onCreateSubscription.unsubscribe();
    const variables: any = {};
    this.onCreateSubscription = (API.graphql(graphqlOperation(onCreateNotification, variables)) as any).subscribe({
      next: (data: any) => {
        this.logger.debug('onCreateNotification', data);
        this.notify(this.buildNotification(get(data, 'value.data.onCreateNotification')));
      },
      error: (err: any) => this.logger.error(err),
    });
  }

  private async load(user: User | null | undefined) {
    if (user) this.onCreate();
  }

  private buildNotifications(data: any[]): UserNotification[] {
    return data.map((d: any) => this.buildNotification(d));
  }

  private buildNotification(data: any): UserNotification {
    return new ModelMapper(UserNotification).map(data);
  }

  private notify(notification: UserNotification) {
    switch (notification.kind) {
      case UserNotificationKind.EQUIPMENT_STATUS:
        this.notifyEquipmentStatus(notification);
        break;
    }
  }

  private notifyEquipmentStatus(notification: UserNotification) {
    const message = $localize`${notification.context.kindName} ${notification.context.reference} est ${toLower(
      EquipmentStatusName[notification.context.status],
    )}`;
    this.notificationService.open(message, {
      icon: EquipmentStatusIcon[notification.context.status],
      color: EquipmentStatusColorName[notification.context.status],
    });
  }
}
