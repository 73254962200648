/** @format */

import { PropertyMap } from 'model-mapper';
import { EquipmentSignatureStatus } from '../../_constants/equipment-signature-status';
import { FieldUpdate } from '../field-update.class';

export class ElevatorDoubtRemovalTrigger {
  @PropertyMap({ type: Date })
  date: Date;

  @PropertyMap()
  sesioNodeComponentId: string;

  @PropertyMap()
  cabinCall: string;

  @PropertyMap()
  cabinCallDataId: string;
}

export class ElevatorScheduledDoubtRemoval {
  @PropertyMap()
  days: number[];

  @PropertyMap()
  hours: number[];
}

export class ElevatorSuspiciousThreshold {
  @PropertyMap({ default: false })
  override: boolean;

  @PropertyMap()
  value: number;
}

export class ElevatorFailureDelay {
  @PropertyMap({ default: false })
  override: boolean;

  @PropertyMap()
  value: number; // in minutes
}

export class ElevatorSignature {
  @PropertyMap()
  status: EquipmentSignatureStatus;

  @PropertyMap({ type: FieldUpdate })
  statusInfo: FieldUpdate<EquipmentSignatureStatus>;

  @PropertyMap({ type: Date })
  activationDate: Date;

  @PropertyMap({ type: ElevatorSuspiciousThreshold })
  suspiciousThreshold: ElevatorSuspiciousThreshold;

  @PropertyMap({ type: ElevatorFailureDelay })
  failureDelay: ElevatorFailureDelay;

  @PropertyMap()
  failureProbability: number;

  @PropertyMap({ type: [ElevatorDoubtRemovalTrigger] })
  triggeredDoubtRemovals: ElevatorDoubtRemovalTrigger[];

  @PropertyMap({ type: ElevatorScheduledDoubtRemoval })
  scheduledDoubtRemoval: ElevatorScheduledDoubtRemoval;
}
