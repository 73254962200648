import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { StarRateComponent } from './star-rate.component';
import { StarFillPipe } from './star-fill.pipe';



@NgModule({
  declarations: [StarRateComponent, StarFillPipe],
  imports: [
    CommonModule,
    AppFlexLayoutModule
  ],
  exports: [StarRateComponent]
})
export class StarRateModule { }
