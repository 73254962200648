/** @format */

import { EventEmitter } from '@angular/core';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { Hub } from 'aws-amplify';
import { environment } from '../../environments/environment';
import { AppLogger } from '../_services/logger.service';

export type SesioIoTProviderState = 'Disconnected' | 'Connecting' | 'Connected';

export class SesioIotProvider extends AWSIoTProvider {
  public state: SesioIoTProviderState = 'Disconnected';
  public onStateChange = new EventEmitter<SesioIoTProviderState>();

  private retryTm: any;
  private logger = new AppLogger('SesioIotProvider');

  // constructor(options: AWSIoTProviderOptions) {
  constructor() {
    super({
      aws_pubsub_region: environment.region,
      aws_pubsub_endpoint: `wss://a2cxyg0a7pd2x5-ats.iot.${environment.region}.amazonaws.com/mqtt`,
    });
    Hub.listen('pubsub', ({ payload }) => {
      if (payload.data.connectionState === 'ConnectionDisrupted') {
        this.logger.warn(payload.message!, payload);
        if (!this.retryTm) this.retryTm = setTimeout(() => this.initializeConnection(), 3000);
      } else {
        if (this.retryTm) {
          clearTimeout(this.retryTm);
          this.retryTm = null;
        }
        this.logger.debug(payload.message!);
      }
      this.onStateChange.next((this.state = payload.data.connectionState));
    });
    this.initializeConnection();
  }

  private async initializeConnection() {
    const options = { url: await this.endpoint };
    const client = await this.connect(this.clientId, options);
    this.logger.debug(`initializeConnection`, { client, options, clientsQueue: this.clientsQueue });
    if (client?.isConnected()) this.onStateChange.next((this.state = 'Connected'));
  }
}
