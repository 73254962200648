import { get } from 'lodash-es';
import { PropertyMap } from 'model-mapper';
import moment, { Moment } from 'moment';

export class Extremes {
  constructor(start?: Moment | Date | string, end?: Moment | Date | string) {
    if (start) this.start = moment.isMoment(start) ? start : moment(start);
    if (end) this.end = moment.isMoment(end) ? end : moment(end);
  }

  @PropertyMap({ type: 'Moment' })
  start: Moment;

  @PropertyMap({ type: 'Moment' })
  end: Moment;

  public toQueryStringParameters(options?: { startFieldName?: string; endFieldName?: string }): {
    [key: string]: string;
  } {
    return {
      [get(options, 'startFieldName', 'start')]: this.start?.toISOString(),
      [get(options, 'endFieldName', 'end')]: this.end?.toISOString(),
    };
  }
}
