/** @format */

import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { each, find, isEqual } from 'lodash-es';
import { UserNotification, UserNotificationKind } from '../../_classes/user-notification.class';
import { fadeOut } from '../../_constants/animations';
import { EquipmentFamily, EquipmentFamilyName } from '../../_constants/equipment-family';
import {
  EquipmentStatusColor,
  EquipmentStatusColorName,
  EquipmentStatusIcon,
  EquipmentStatusName,
} from '../../_constants/equipment-status';
import { NavigationService } from '../../_services/navigation.service';
import { SessionService } from '../../_services/session.service';
import { UserNotificationService } from '../../_services/user-notification.service';
import { UserService } from '../../_services/user.service';
import { NotificationService } from '../notification/notification.service';
import { EquipmentDoorStateName } from '../../_constants/equipment-door/equipment-door-state';
import { EquipmentDoorNameName } from '../../_constants/equipment-door/equipment-door-name';

@Component({
  selector: 'app-user-notifications-dialog',
  templateUrl: './user-notifications-dialog.component.html',
  styleUrls: ['./user-notifications-dialog.component.scss'],
  animations: [fadeOut],
})
export class UserNotificationsDialogComponent implements OnInit, OnDestroy {
  NotificationKind = UserNotificationKind;
  EquipmentDoorStateName = EquipmentDoorStateName;
  EquipmentDoorNameName = EquipmentDoorNameName;
  EquipmentStatusName = EquipmentStatusName;
  EquipmentStatusColor = EquipmentStatusColor;
  EquipmentStatusColorName = EquipmentStatusColorName;
  EquipmentStatusIcon = EquipmentStatusIcon;
  EquipmentFamily = EquipmentFamily;
  EquipmentFamilyName = EquipmentFamilyName;

  notifications: UserNotification[];
  nextToken?: string;

  loading = true;

  constructor(
    public location: Location,
    public userNotificationService: UserNotificationService,
    public userService: UserService,
    private navigationService: NavigationService,
    private sessionService: SessionService,
    private notificationService: NotificationService,
  ) {}

  async ngOnInit(): Promise<void> {
    const res = await this.userNotificationService.list();
    this.notifications = res.data;
    this.nextToken = res.nextToken;
    this.loading = false;
  }

  async ngOnDestroy(): Promise<void> {
    const promises: Promise<void>[] = [];
    each(this.notifications, (notification) => {
      if (!notification.read) promises.push(this.userNotificationService.read(notification));
    });
    await Promise.all(promises);
    await this.userService.setAllNotificationsRead();
  }

  async loadMore() {
    if (!this.nextToken) return;
    this.loading = true;
    const res = await this.userNotificationService.list(this.nextToken);
    this.notifications.push(...res.data);
    this.nextToken = res.nextToken;
    this.loading = false;
  }

  click(notification: UserNotification) {
    switch (notification.kind) {
      case UserNotificationKind.EQUIPMENT_STATUS:
        this.openEquipment(notification.context.organization, notification.context.family, notification.context._id);
        break;
    }
  }

  private openEquipment(organizationId: string, family: EquipmentFamily, _id: string): any {
    if (organizationId && !isEqual(organizationId, this.sessionService.$organization.value?._id)) {
      const organization = find(this.sessionService.$organizations.value, { _id: organizationId });
      if (!organization) {
        return this.notificationService.error($localize`Vous ne faîtes pas partie de l'organisation de cet équipement`);
      }
      this.sessionService.setOrganization(organization);
    }
    this.navigationService.navigate([family, _id]);
  }
}
