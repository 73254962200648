/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';
import { FailureCountLabelModule } from '../failure-count-label/failure-count-label.module';
import { AppHumanizeDurationModule } from '../humanize-duration/humanize-duration.module';
import { AppLoaderModule } from '../loader/loader.module';
import { EquipmentFailureInfoComponent } from './equipment-failure-info.component';

@NgModule({
  declarations: [EquipmentFailureInfoComponent],
  imports: [
    CommonModule,
    AppFlexLayoutModule,
    ExtentedPipesModule,
    AppLoaderModule,
    FailureCountLabelModule,
    AppHumanizeDurationModule,
  ],
  exports: [EquipmentFailureInfoComponent],
})
export class EquipmentFailureInfoModule {}
