<amplify-authenticator *ngIf="sessionService.$locale | async; else loadingTmpl" [loginMechanisms]="['email']"
  usernameAlias="email" [signUpAttributes]="['given_name', 'family_name']" [hideSignUp]="true">

  <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut">
    <mat-drawer-container>

      <mat-drawer id="menu" mode="side" opened class="active-{{active}}"
        [style.--menu-border-color]="'var(--app-color-' + active + ')'">
        <ng-scrollbar visibility="hover">
          <mat-list fxLayout="column">

            <ng-container *ngFor="let module of organizationModules">
              <ng-container *ngTemplateOutlet="moduleMenuTmpl; context: { module: module }"></ng-container>
            </ng-container>

            <mat-divider [style.margin-top]="'auto'"></mat-divider>

            <mat-list-item tooltip="help" intersectionObserver (intersection)="loadMenu($event)">
              <button mat-icon-button color="medium" routerLink="/help" routerLinkActive
                (isActiveChange)="$event ? active = 'help' : null">
                <mat-icon fontSet="material-icons-outlined">help_outline</mat-icon>
              </button>
            </mat-list-item>

            <ng-container *ngIf="hasAdministrationAccess">
              <mat-divider></mat-divider>
              <mat-list-item tooltip="administration" intersectionObserver (intersection)="loadMenu($event)">
                <button mat-icon-button color="dark" routerLink="/administration" routerLinkActive
                  (isActiveChange)="$event ? active = 'administration' : null">
                  <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                </button>
              </mat-list-item>
            </ng-container>

          </mat-list>
        </ng-scrollbar>
      </mat-drawer>

      <mat-drawer-content id="content">

        <mat-toolbar id="app-header" color="light" fxLayoutAlign="space-between center" fxLayoutGap="16px">

          <div id="left-actions" fxLayoutAlign="start center" fxLayoutGap="16px">
            <button class="action" mat-button i18n-matTooltip matTooltip="Statistiques" color="primary" disabled>
              <div fxLayoutAlign="center center" fxLayoutGap="8px">
                <mat-icon>insights</mat-icon>
                <span i18n>Statistiques</span>
              </div>
            </button>
            <button class="action" mat-button i18n-matTooltip matTooltip="Rapports" color="primary" disabled>
              <div fxLayoutAlign="center center" fxLayoutGap="8px">
                <mat-icon>picture_as_pdf</mat-icon>
                <span i18n>Rapports</span>
              </div>
            </button>
            <button class="action" mat-button i18n-matTooltip matTooltip="Messagerie" color="primary" disabled>
              <div fxLayoutAlign="center center" fxLayoutGap="8px">
                <mat-icon>speaker_notes</mat-icon>
                <span i18n>Messagerie</span>
              </div>
            </button>
            <button class="action" mat-button i18n-matTooltip matTooltip="Notifications" color="primary"
              (click)="openUserNotifications()">
              <div fxLayoutAlign="center center" fxLayoutGap="8px">
                <mat-icon>notifications</mat-icon>
                <span matBadgeSize="small" matBadgeColor="warn" matBadgeOverlap="false"
                  [matBadgeHidden]="!authService.user?.unreadNotifications"
                  [matBadge]="authService.user?.unreadNotifications" i18n>
                  Notifications
                </span>
              </div>
            </button>
          </div>

          <div id="right-actions" fxLayoutAlign="start center">
            <div id="user-organization" fxLayoutAlign="start center" [matMenuTriggerFor]="organizationMenu">
              <img id="logo" *ngIf="sessionService.$organization.value?.logo?.key" [style.height.px]="34"
                [src]="sessionService.$organization.value!.logo!.key | storageFileUrl | async"
                [matTooltip]="$any(sessionService.$organization.value?.name)">
              <img id="logo" *ngIf="!sessionService.$organization.value?.logo?.key" [style.height.px]="34"
                src="assets/logos/sesio.svg" [matTooltip]="$any(sessionService.$organization.value?.name)">
              <mat-menu #organizationMenu="matMenu" class="options-hfill">
                <button *ngFor="let option of sessionService.$organizations | async" mat-menu-item
                  role="menuitemcheckbox" (click)="switchOrganization(option)">
                  <div fxLayoutAlign="space-between center" fxLayoutGap="16px">
                    <span nowrap>{{option.name}}</span>
                    <mat-icon *ngIf="option._id === sessionService.$organization.value?._id" color="accent"
                      [style.margin-right.px]="0">
                      check
                    </mat-icon>
                  </div>
                </button>
              </mat-menu>
            </div>

            <ngx-avatars *ngIf="authService.user" id="user-menu" [gravatarId]="authService.user.email"
              name="{{authService.user.firstname}} {{authService.user.lastname}}" bgColor="var(--app-color-primary)"
              fgColor="var(--app-color-primary-contrast)" size="35" [round]="true" [matMenuTriggerFor]="userMenu"
              matTooltip="[ {{authService.user.email}} ] {{authService.user.firstname}} {{authService.user.lastname}}">
            </ngx-avatars>
            <mat-menu #userMenu="matMenu">
              <button mat-menu-item color="secondary" routerLink="/account">
                <mat-icon color="secondary">person</mat-icon>
                <span translate>my-account</span>
              </button>
              <button mat-menu-item (click)="authService.signOut()">
                <mat-icon color="accent">exit_to_app</mat-icon>
                <span translate>logout</span>
              </button>
            </mat-menu>
          </div>

        </mat-toolbar>

        <div id="content">
          <router-outlet></router-outlet>
        </div>

      </mat-drawer-content>

    </mat-drawer-container>

    <div id="tooltip" @tooltip *ngIf="tooltipTop !== null" [style.top.px]="tooltipTop" fxLayoutAlign="start center">
      <span *ngIf="tooltipMessage">{{tooltipMessage | translate}}</span>
    </div>

  </ng-template>

</amplify-authenticator>

<ng-template #moduleMenuTmpl let-module="module">
  <mat-list-item [attr.tooltip]="module" intersectionObserver (intersection)="loadMenu($event)">
    <button mat-icon-button [attr.color]="module" [routerLink]="['/', module]" routerLinkActive="active"
      (isActiveChange)="$event ? active = module : null">
      <mat-icon [svgIcon]="module"></mat-icon>
    </button>
  </mat-list-item>
  <mat-divider></mat-divider>
</ng-template>

<ng-template #loadingTmpl><app-loader id="loader" [diameter]="100"></app-loader> </ng-template>
