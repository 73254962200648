/** @format */

import { PropertyMap } from 'model-mapper';

export class LocationAddress {
  @PropertyMap()
  formatted: string;

  @PropertyMap()
  number: string;

  @PropertyMap()
  street: string;

  @PropertyMap()
  city: string;

  @PropertyMap()
  postalCode: string;

  @PropertyMap()
  country: string;

  @PropertyMap()
  countryShort: string;

  @PropertyMap()
  type: string;
}

export class Location {
  @PropertyMap({ type: LocationAddress })
  address: LocationAddress;

  @PropertyMap()
  coordinates: [number, number];

  @PropertyMap()
  additional?: string;

  @PropertyMap()
  timezone?: string | null;
}
