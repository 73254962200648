/** @format */

import { each, find, map, reduce, values } from 'lodash-es';
import { Module } from './module';

export enum EquipmentFamily {
  ELEVATOR = 'elevator',
  PARKING_DOOR = 'parking-door',
  ENERGY = 'energy',
  FIRE_SAFETY = 'fire-safety',
  RELAY_ANTENNA = 'relay-antenna',
  SUPERVISION = 'supervision',
}

export const EquipmentFamilyName: { [family: string]: string } = {
  [EquipmentFamily.ELEVATOR]: $localize`Ascenseur`,
  [EquipmentFamily.PARKING_DOOR]: $localize`Porte de parking`,
  [EquipmentFamily.ENERGY]: $localize`Énergie`,
  [EquipmentFamily.FIRE_SAFETY]: $localize`Sécurité incendie`,
  [EquipmentFamily.RELAY_ANTENNA]: $localize`Antenne relais`,
  [EquipmentFamily.SUPERVISION]: $localize`Supervision`,
};

export const EquipmentFamilyColor: { [family: string]: string } = {
  [EquipmentFamily.ELEVATOR]: 'var(--app-color-elevator)',
  [EquipmentFamily.PARKING_DOOR]: 'var(--app-color-parking-door)',
  [EquipmentFamily.ENERGY]: 'var(--app-color-energy)',
  [EquipmentFamily.FIRE_SAFETY]: 'var(--app-color-fire-safety)',
  [EquipmentFamily.RELAY_ANTENNA]: 'var(--app-color-relay-antenna)',
  [EquipmentFamily.SUPERVISION]: 'var(--app-color-supervision)',
};

export const EquipmentFamilyColorContrast: { [family: string]: string } = {
  [EquipmentFamily.ELEVATOR]: 'var(--app-color-elevator-contrast)',
  [EquipmentFamily.PARKING_DOOR]: 'var(--app-color-parking-door-contrast)',
  [EquipmentFamily.ENERGY]: 'var(--app-color-energy-contrast)',
  [EquipmentFamily.FIRE_SAFETY]: 'var(--app-color-fire-safety-contrast)',
  [EquipmentFamily.RELAY_ANTENNA]: 'var(--app-color-relay-antenna-contrast)',
  [EquipmentFamily.SUPERVISION]: 'var(--app-color-supervision-contrast)',
};

export interface IEquipmentFamilyOptions {
  value: any;
  name: string;
  color: string;
}

export const EquipmentFamilyOptions = map(values(EquipmentFamily), (value) => ({
  value,
  name: EquipmentFamilyName[value],
  color: EquipmentFamilyColor[value],
}));

export const EquipmentFamilyByModule: { [module: string]: EquipmentFamily[] } = {
  [Module.ELEVATOR]: [EquipmentFamily.ELEVATOR],
  [Module.PARKING_DOOR]: [EquipmentFamily.PARKING_DOOR],
  [Module.ENERGY]: [EquipmentFamily.ENERGY],
  [Module.RELAY_ANTENNA]: [EquipmentFamily.RELAY_ANTENNA],
  [Module.FIRE_SAFETY]: [EquipmentFamily.FIRE_SAFETY],
};

export function getModuleDatagridOptions(modules: Module[]): IEquipmentFamilyOptions[] {
  return reduce(
    modules,
    (pv, cv) => {
      each(EquipmentFamilyByModule[cv], (value) => {
        if (!find(pv, { value })) {
          const option = find(EquipmentFamilyOptions, { value });
          if (option) pv.push(option);
        }
      });
      return pv;
    },
    [] as IEquipmentFamilyOptions[]
  );
}
