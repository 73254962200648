import { QuillModule as NgxQuillModule } from 'ngx-quill';
import Quill from 'quill';
import 'quill-emoji';
// @ts-ignore
import ImageResize from 'quill-image-resize-module';
const { ImageDrop } = require('quill-image-drop-module');
// @ts-ignore
// const ImageUploader = require('quill-image-uploader');
// declare const ImageUploader;
import { VideoHandler, ImageHandler } from 'ngx-quill-upload';
import 'quill-mention';
import QuillCursors from 'quill-cursors';
const Parchment = Quill.import('parchment');

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageHandler', ImageHandler);
Quill.register('modules/videoHandler', VideoHandler);
Quill.register('modules/cursors', QuillCursors);

class IndentAttributor extends Parchment.Attributor.Style {
  constructor(...args: any[]) {
    // Typescripts fix
    super(args);
  }

  add(node: any, value: any) {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }

  remove(node: any) {
    super.remove(node);
  } // Typescripts fix
}

const IndentStyle = new IndentAttributor('indent', 'text-indent', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em'],
});

Quill.register(IndentStyle, true);

const DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);

const AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);

const BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);

const ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);

const DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);

const SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);

const ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);

const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);

const FontStyle = Quill.import('attributors/style/font');
FontStyle.whitelist = ['Roboto Serif', 'Lato'];
Quill.register(FontStyle, true);

export const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['12px', '16px', '24px', '32px'];
Quill.register(SizeStyle, true);

const BlockEmbed: any = Quill.import('blots/block/embed');
class SpeechRecognitionBlot extends BlockEmbed {
  // const Inline: any = Quill.import('blots/inline');
  // class SpeechRecognitionBlot extends Inline {

  static blotName = 'speech-recognition';
  static tagName = 'i';
  static className = 'speech-recognition';

  // static create(value: { id: string; interim: string; finale: string; }) {
  // let node = document.getElementById(value.id) || super.create();
  // node.setAttribute('id', value.id);

  // const final = document.getElementById(`${value.id}-final`) || document.createElement('span');
  // final.setAttribute('id', `${value.id}-final`);
  // final.textContent = value.final;
  // node.appendChild(final);

  // const interim = document.getElementById(`${value.id}-interim`) || document.createElement('i');
  // interim.setAttribute('id', `${value.id}-interim`);
  // interim.textContent = value.interim;
  // interim.style.opacity = '0.6';
  // node.appendChild(interim);

  // node.value = value;

  // return node;
  // }

  static create(value: { id: string; interim: string; finale: string }) {
    const node = super.create();
    node.setAttribute('id', value.id);
    node.dataset.id = value.id;
    return node;
  }

  static value(domNode: any) {
    return domNode.dataset.id;
  }
}
// SpeechRecognitionBlot.blotName = 'speech-recognition';
// SpeechRecognitionBlot.tagName = 'i';
// SpeechRecognitionBlot.className = 'speech-recognition';
Quill.register(SpeechRecognitionBlot);

export const QuillModule = NgxQuillModule;
