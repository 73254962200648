import { Pipe, PipeTransform } from '@angular/core';
import { Storage } from 'aws-amplify';
import { map } from 'lodash-es';

export interface IOptions {
  level: 'public';
}

@Pipe({
  name: 'storageFileUrl',
})
export class StorageFileUrlPipe implements PipeTransform {
  async transform(path: string | string[], options?: IOptions): Promise<string | string[]> {
    if (Array.isArray(path)) {
      return Promise.all(map(path, (p) => this.get(p)));
    } else {
      return this.get(path);
    }
  }

  private async get(path: string, options?: IOptions): Promise<string> {
    return Storage.get(path, { level: options?.level || 'public' });
  }
}
