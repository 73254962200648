/** @format */

import { Options } from 'highcharts';
import { each, keys, merge } from 'lodash-es';
import moment from 'moment-timezone';
import { chromatic as colors } from './colors';

export interface IHighchartsOptions extends Options {
  dateTimeLabelFormats: {
    millisecond: { list: string[] };
    second: { list: string[] };
    minute: { list: string[] };
    hour: { list: string[] };
    day: { list: string[] };
    week: { list: string[] };
    month: { list: string[] };
  };
}

export const highchartsOptions: { [locale: string]: IHighchartsOptions } = {
  fr: {
    dateTimeLabelFormats: {
      millisecond: { list: ['%H:%M:%S.%L'] },
      second: { list: ['%H:%M:%S'] },
      minute: { list: ['%H:%M'] },
      hour: { list: ['%H:%M', '%H'] },
      day: { list: ['%A, %e. %B', '%a, %e. %b', '%E'] },
      week: { list: ['Semaine %W', 'S%W'] },
      month: { list: ['%B', '%b', '%o'] },
    },
    lang: {
      months: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Jui', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
      weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      shortWeekdays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      decimalPoint: ',',
      loading: 'Chargement...',
      noData: 'Aucune donnée',
      rangeSelectorZoom: 'Zoom',
      rangeSelectorFrom: 'De',
      rangeSelectorTo: 'À',
      resetZoom: 'Réinitialiser le zoom',
      resetZoomTitle: 'Réinitialiser le zoom niveau 1:1',
      // drillUpText: '<span class="material-icons">arrow_back</span> {series.name}',
      downloadCSV: 'Télécharger au format CSV',
      downloadJPEG: "Télécharger en tant qu'image JPEG",
      downloadPDF: 'Télécharger en tant que document PDF',
      downloadPNG: "Télécharger en tant qu'image PNG",
      downloadSVG: "Télécharger en tant qu'image SVG",
      downloadXLS: 'Télécharger au format XLS',
      printChart: 'Imprimer le graphique',
      viewData: 'Afficher sous forme de tableau',
      viewFullscreen: 'Afficher en plein écran',
      hideData: 'Masquer la donnée',
      exitFullscreen: 'Quitter le mode plein écran',
    },
  },
};

const time = { timezone: moment.tz.guess(), moment };

each(keys(highchartsOptions), (key) => merge(highchartsOptions[key], { time, colors }));
