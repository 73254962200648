/** @format */

import { EquipmentCabinCallName } from '../../_constants/equipment-cabin-call-name';
import { EquipmentDoorName } from '../../_constants/equipment-door/equipment-door-name';
import { GpioReadKind, GpioWriteKind } from '../../_constants/gpio/gpio-kind';

export enum PayloadAction {
  START = 'start',
  STOP = 'stop',
  LIVE = 'live',
  DEBUG = 'debug',
  UNSCHEDULED_REPORT = 'unscheduled-report',
  SCHEDULED_REPORT = 'scheduled-report',
  SCHEDULED_REPORT_RESPONSE = 'scheduled-report-response',
  CHANGE_STATE = 'change-state',
  CHANGE_COMPONENT_STATE = 'change-component-state',
  RUN_TEST_MODE = 'run-test-mode',
  ACTIVATE = 'activate',
  ACTIVATE_FOR_DURATION = 'activate-for-duration',
  DEACTIVATE = 'deactivate',
  DEACTIVATE_FOR_DURATION = 'deactivate-for-duration',
  IDENTIFY = 'identify',
  LOCAL_TRIGGER = 'local-trigger',
}

export type ComponentCommandStatus = 'success' | 'failure';

export type SesioPayloadOrigin = {
  kind: 'user' | 'lambda' | 'nd';
  functionName?: string;
  info?: any;
};

export interface ISesioPayload {
  'Symbol(topic)': string;
  uuid: string; // process uuid
  env: { ENV?: string; AWS_REGION?: string };
  origin: SesioPayloadOrigin;
  id: string;
  device: string; // sesio node reference
  component: string; // sesio node component reference
  time: number;
}

// COMMANDS

export interface ICommand extends ISesioPayload {
  action: PayloadAction;
}

export interface ICabinCallCommand extends ICommand {
  action: PayloadAction.ACTIVATE_FOR_DURATION;
  kind: GpioWriteKind.CABIN_CALL;
  group: EquipmentCabinCallName;
}

// COMMAND EVENT

export interface ICommandEvent extends ISesioPayload {
  commandId: string;
  action: PayloadAction;
  status: ComponentCommandStatus;
  statusCode: number;
  statusMessage?: string;
}

export interface ICabinCallCommandEvent extends ICommandEvent {
  action: PayloadAction.ACTIVATE_FOR_DURATION;
  kind: GpioWriteKind.CABIN_CALL;
  group: EquipmentCabinCallName;
}

// EVENTS

export enum DoorEventState {
  ERROR = -3,
  MIXED = -2,
  UNKNOWN = -1,
  OPEN = 1,
  CLOSED = 2,
}

export interface IDoorEvent extends ISesioPayload {
  kind: GpioReadKind.DOOR;
  group: EquipmentDoorName;
  state: DoorEventState;
  previousState: DoorEventState;
  metadata: { distance: { value: number; time: number } };
}

export interface ILidarEvent extends ISesioPayload {
  speed: number;
  distance: number;
  direction: number;
}

export interface IMouvementEvent extends ISesioPayload {
  start: number;
  startDistance: number;
  end: number;
  endDistance: number;
  speeds: { speed: number; distance: number; signalStrength: number }[];
  doorEventCount: number;
  isDoorOpen: boolean;
  cabinCallId?: string;
}

export type NodeStatusEventAction = 'scheduled-report' | 'unscheduled-report';
export enum NodeStatusEventComponentState {
  UNKNOWN = 0,
  OK = 1,
  KO = 2,
}
export interface INodeStatusEventComponent {
  component: string; // sesio node component reference
  time: number;
  state: NodeStatusEventComponentState;
}
export interface INodeStatusEvent extends ISesioPayload {
  action: NodeStatusEventAction;
  data: { [componentKind: string]: INodeStatusEventComponent };
}

export type InspectionModeEventEvent = 'click' | 'double_click' | 'long_press';
export enum InspectionModeEventState {
  STATE_IDENTIFY = -2,
  STATE_PREVIOUS = -1,

  STATE_UNKNOWN = 0,
  STATE_OK = 1,
  STATE_FAILURE = 2,
  STATE_STOP = 3,
  STATE_MAINTENANCE = 4,
  STATE_REPAIR = 5,

  STATE_ERROR = 6,
  STATE_ERROR_LIDAR = 7,
  STATE_ERROR_DOUBT_REMOVAL = 8,
  STATE_ERROR_DOOR_MONITORING = 9,
  STATE_ERROR_CONNEXION = 10,
}
export enum InspectionModeEventStateLabel {
  STATE_IDENTIFY = 'identify',
  STATE_PREVIOUS = 'previous',
  STATE_UNKNOWN = 'unknown',
  STATE_OK = 'ok',
  STATE_FAILURE = 'failure',
  STATE_STOP = 'stop',
  STATE_MAINTENANCE = 'maintenance',
  STATE_REPAIR = 'repair',

  STATE_ERROR = 'error',
  STATE_ERROR_LIDAR = 'error_lidar',
  STATE_ERROR_DOUBT_REMOVAL = 'error_doubt_removal',
  STATE_ERROR_DOOR_MONITORING = 'error_door_monitoring',
  STATE_ERROR_CONNEXION = 'error_connexion',
}
export interface IInspectionModeEvent extends ISesioPayload {
  event: InspectionModeEventEvent;
  state: InspectionModeEventState;
  state_label: InspectionModeEventStateLabel;
  previous_state: InspectionModeEventState;
  previous_state_label: InspectionModeEventStateLabel;
  action: 'change-state';
}
