/** @format */

import { get } from 'lodash-es';
import { PropertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { EquipmentFamily } from '../../_constants/equipment-family';
import { EquipmentMode } from '../../_constants/equipment-mode';
import { EquipmentState } from '../../_constants/equipment-state';
import { EquipmentStatus } from '../../_constants/equipment-status';
import { Searchable } from '../../_decorators/searchable-class.decorator';
import { embeddedSerialize, s3PathPropertyMap } from '../../_helpers/class.helper';
import { Archived } from '../archived.class';
import { Consumption } from '../consumption.class';
import { EmbeddedCustomerAccount } from '../customer-account.embedded.class';
import { EntityDocument } from '../entity-document.class';
import { EmbeddedEquipmentKind } from '../equipment-kind.embedded.class';
import { ExternalReferences } from '../external-references.class';
import { FieldUpdate } from '../field-update.class';
import { Holiday } from '../holiday.class';
import { Location } from '../location.class';
import { EmbeddedOrganizationalUnit } from '../organizational-unit.embedded.class';
import { Production } from '../production.class';
import { EmbeddedRealEstateStructure } from '../real-estate-structure.embedded.class';
import { S3File } from '../s3-file.class';
import { EmbeddedSesioNodeComponent } from '../sesio-node-component/sesio-node-component.embedded.class';
import { EmbeddedSesioNode } from '../sesio-node/sesio-node.embedded.class';
import { EmbeddedUser } from '../user.embedded.class';

export class EquipmentBlueprintCoordinates {
  @PropertyMap()
  blueprintId: string;

  @PropertyMap()
  x: number;

  @PropertyMap()
  y: number;
}

@Searchable({ sortFields: 'reference name' })
export class Equipment {
  @PropertyMap()
  _id: string;

  @PropertyMap({ type: EmbeddedCustomerAccount, serialize: embeddedSerialize })
  customerAccount?: EmbeddedCustomerAccount;

  @PropertyMap()
  organization: string;

  @PropertyMap(s3PathPropertyMap)
  s3Path: string;

  @PropertyMap()
  family: EquipmentFamily;

  @PropertyMap({ type: EmbeddedOrganizationalUnit, serialize: embeddedSerialize })
  organizationalUnit: EmbeddedOrganizationalUnit;

  @PropertyMap({ type: EmbeddedRealEstateStructure, serialize: embeddedSerialize })
  realEstateStructure?: EmbeddedRealEstateStructure;

  @PropertyMap({ type: EmbeddedSesioNode, serialize: embeddedSerialize })
  sesioNode?: EmbeddedSesioNode;

  @PropertyMap({ type: [EmbeddedSesioNodeComponent], serialize: embeddedSerialize })
  sesioNodeComponents: EmbeddedSesioNodeComponent[];

  @PropertyMap({ type: EmbeddedEquipmentKind, serialize: embeddedSerialize })
  kind: EmbeddedEquipmentKind;

  @PropertyMap()
  reference: string;

  @PropertyMap()
  name?: string;

  @PropertyMap({ type: ExternalReferences })
  externalReferences: ExternalReferences;

  @PropertyMap()
  status: EquipmentStatus;

  @PropertyMap({ type: FieldUpdate })
  statusInfo: FieldUpdate<EquipmentStatus>;

  @PropertyMap()
  state: EquipmentState;

  @PropertyMap()
  condition: number;

  @PropertyMap({ type: Location })
  location: Location;

  @PropertyMap()
  coordinates: [number, number];

  @PropertyMap({ type: EquipmentBlueprintCoordinates })
  blueprintCoordinates: EquipmentBlueprintCoordinates;

  @PropertyMap()
  brand: string;

  @PropertyMap()
  model: string;

  @PropertyMap()
  version: string;

  @PropertyMap({ type: Date })
  commissioningDate: Date;

  @PropertyMap()
  warrantyPeriod: number;

  @PropertyMap()
  consumptionNotice: number;

  @PropertyMap()
  productionNotice: number;

  @PropertyMap()
  emissionNotice: number;

  @PropertyMap({ type: [Consumption] })
  consumptions: Consumption[];

  @PropertyMap({ type: [Production] })
  productions: Production[];

  @PropertyMap()
  mode: EquipmentMode;

  @PropertyMap({ type: 'Moment' })
  serviceContractStartDate: Moment;

  @PropertyMap({ type: 'Moment' })
  serviceContractEndDate: Moment;

  @PropertyMap({ type: 'Moment' })
  lastTechnicalControlDate: Moment;

  @PropertyMap({ type: 'Moment' })
  lastMaintenanceDate: Moment;

  @PropertyMap({ type: [S3File] })
  pictures: S3File[];

  @PropertyMap({ type: [EntityDocument] })
  documents: EntityDocument[];

  @PropertyMap({ type: [EmbeddedUser], serialize: embeddedSerialize })
  assignments: EmbeddedUser[];

  @PropertyMap()
  lastOpenedFailureId: string;

  @PropertyMap({ type: Date })
  lastOpenedFailureStartDate: Date;

  @PropertyMap()
  failureCount: number;

  @PropertyMap()
  failureDuration: number;

  @PropertyMap()
  disponibilityRate: number;

  @PropertyMap()
  overrideAlert: boolean;

  @PropertyMap()
  alertEndpoint: string;

  @PropertyMap()
  alertStatuses: EquipmentStatus[];

  @PropertyMap()
  disableSerenite: boolean;

  @PropertyMap({ type: [Holiday] })
  holidays: Holiday[];

  @PropertyMap()
  metadata?: any;

  @PropertyMap({ type: Archived })
  __archived: Archived;

  public getIconFile(): S3File | undefined {
    return get(this, `kind.category.icons.${this.status}`);
  }

  public getDefaultIconUrl(): string {
    return `assets/icons/equipment-status/equipment-status-${this.status}.png`;
  }
}
