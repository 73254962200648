/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentIconPipe } from './equipment-icon.pipe';
import { EquipmentStatusPipe } from './equipment-status.pipe';

const pipes = [EquipmentIconPipe, EquipmentStatusPipe];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes,
})
export class EquipmentPipeModule {}
