/** @format */

import { PropertyMap } from 'model-mapper';
import { EmbeddedUser } from './user.embedded.class';

export class Archived {
  @PropertyMap({ default: false })
  isArchived: boolean;

  @PropertyMap({ type: Date })
  date: Date;

  @PropertyMap({ type: EmbeddedUser })
  user: EmbeddedUser;
}
