/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryDialog } from './gallery-dialog/gallery-dialog.dialog';
import { GalleryModule as NgGalleryModule } from 'ng-gallery';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [GalleryComponent, GalleryDialog],
  imports: [CommonModule, NgGalleryModule, MatIconModule, MatButtonModule, MatDialogModule],
  exports: [GalleryComponent, GalleryDialog],
})
export class GalleryModule {}
