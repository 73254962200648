/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatagridModule } from '../../datagrid/datagrid.module';
import { CabinCallDatagridComponent } from './cabin-call-datagrid.component';

@NgModule({
  declarations: [CabinCallDatagridComponent],
  imports: [CommonModule, DatagridModule],
  exports: [CabinCallDatagridComponent],
})
export class CabinCallDatagridModule {}
