/** @format */

import { map, values } from 'lodash-es';

export enum EquipmentStatus {
  OK = 'ok',
  OUT_OF_ORDER = 'out-of-order',
  STOPPED = 'stopped',
  MAINTENANCE = 'maintenance',
  REPARATION = 'reparation',
  NOFLOW = 'noflow',
  INITIALIZING = 'initializing',
  UNKNOWN = 'unknown',
  ND = 'nd',
}

export const EquipmentStatusColorName: { [key: string]: string } = {
  [EquipmentStatus.OK]: 'ok',
  [EquipmentStatus.OUT_OF_ORDER]: 'out-of-order',
  [EquipmentStatus.STOPPED]: 'stopped',
  [EquipmentStatus.MAINTENANCE]: 'maintenance',
  [EquipmentStatus.REPARATION]: 'reparation',
  [EquipmentStatus.NOFLOW]: 'noflow',
  [EquipmentStatus.INITIALIZING]: 'initializing',
  [EquipmentStatus.UNKNOWN]: 'medium',
  [EquipmentStatus.ND]: 'nd',
};
export const EquipmentStatusColor: { [key: string]: string } = {
  [EquipmentStatus.OK]: `var(--app-color-${EquipmentStatusColorName[EquipmentStatus.OK]})`,
  [EquipmentStatus.OUT_OF_ORDER]: `var(--app-color-${EquipmentStatusColorName[EquipmentStatus.OUT_OF_ORDER]})`,
  [EquipmentStatus.STOPPED]: `var(--app-color-${EquipmentStatusColorName[EquipmentStatus.STOPPED]})`,
  [EquipmentStatus.MAINTENANCE]: `var(--app-color-${EquipmentStatusColorName[EquipmentStatus.MAINTENANCE]})`,
  [EquipmentStatus.REPARATION]: `var(--app-color-${EquipmentStatusColorName[EquipmentStatus.REPARATION]})`,
  [EquipmentStatus.NOFLOW]: `var(--app-color-${EquipmentStatusColorName[EquipmentStatus.NOFLOW]})`,
  [EquipmentStatus.INITIALIZING]: `var(--app-color-${EquipmentStatusColorName[EquipmentStatus.INITIALIZING]})`,
  [EquipmentStatus.UNKNOWN]: `unknown(--app-color-${EquipmentStatusColorName[EquipmentStatus.ND]})`,
  [EquipmentStatus.ND]: `var(--app-color-${EquipmentStatusColorName[EquipmentStatus.ND]})`,
};

export const EquipmentStatusIcon: { [key: string]: string } = {
  [EquipmentStatus.OK]: 'verified_user',
  [EquipmentStatus.OUT_OF_ORDER]: 'gpp_bad',
  [EquipmentStatus.STOPPED]: 'shield_lock',
  [EquipmentStatus.MAINTENANCE]: 'health_and_safety',
  [EquipmentStatus.REPARATION]: 'admin_panel_settings',
  [EquipmentStatus.NOFLOW]: 'remove_moderator',
  [EquipmentStatus.INITIALIZING]: 'arming_countdown',
  [EquipmentStatus.UNKNOWN]: 'shield_question',
  [EquipmentStatus.ND]: 'shield',
};

export const EquipmentStatusName: { [key: string]: string } = {
  [EquipmentStatus.OK]: $localize`En fonctionnement`,
  [EquipmentStatus.OUT_OF_ORDER]: $localize`En panne`,
  [EquipmentStatus.STOPPED]: $localize`À l'arrêt`,
  [EquipmentStatus.MAINTENANCE]: $localize`En maintenance`,
  [EquipmentStatus.REPARATION]: $localize`En dépannage`,
  [EquipmentStatus.NOFLOW]: $localize`Déconnecté`,
  [EquipmentStatus.INITIALIZING]: $localize`En cours d'initialisation`,
  [EquipmentStatus.UNKNOWN]: $localize`Inconnu`,
  [EquipmentStatus.ND]: $localize`Non défini`,
};

export const EquipmentStatusIconName: { [key: string]: string } = {
  [EquipmentStatus.OK]: $localize`Icône en fonctionnement`,
  [EquipmentStatus.OUT_OF_ORDER]: $localize`Icône en panne`,
  [EquipmentStatus.STOPPED]: $localize`Icône à l'arrêt`,
  [EquipmentStatus.MAINTENANCE]: $localize`Icône en maintenance`,
  [EquipmentStatus.REPARATION]: $localize`Icône en dépannage`,
  [EquipmentStatus.NOFLOW]: $localize`Icône déconnecté`,
  [EquipmentStatus.INITIALIZING]: $localize`Icône en cours d'initialisation`,
  [EquipmentStatus.UNKNOWN]: $localize`Icône inconnu`,
  [EquipmentStatus.ND]: $localize`Icône non défini`,
};

export const EquipmentStatusOptions = map(values(EquipmentStatus), (value) => ({
  value,
  name: EquipmentStatusName[value],
  prefixIcon: EquipmentStatusIcon[value],
  color: EquipmentStatusColor[value],
}));

export const EquipmentStatusChartOptions = map(values(EquipmentStatus), (value) => ({
  value,
  name: EquipmentStatusName[value],
  icon: EquipmentStatusIcon[value],
  color: EquipmentStatusColor[value],
}));

export const EquipmentStatusDatagridOptions = map(values(EquipmentStatus), (value) => ({
  value: value,
  name: `<span nowrap>${EquipmentStatusName[value]}</span>`,
  icon: { name: EquipmentStatusIcon[value], color: EquipmentStatusColor[value] },
}));
