/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { SessionService } from '../_services/session.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(private router: Router, private authService: AuthService, private sessionService: SessionService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const user = await this.authService.getUser();
    if (!user) return this.router.createUrlTree(['/']);
    if (user.isAdministrator) return true;
    if (user.customerAccounts?.length) return true;
    const organization = this.sessionService.$organization.value;
    if (!organization) return this.router.createUrlTree(['/']);
    if (user.isOrganizationAdministrator(organization._id)) return true;
    return this.router.createUrlTree(['/']);
  }
}
