/** @format */

import { each, find, get, includes, isNil } from 'lodash-es';
import { PropertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { EquipmentFamily, EquipmentFamilyByModule } from '../_constants/equipment-family';
import { EquipmentStatus } from '../_constants/equipment-status';
import { Module } from '../_constants/module';
import { UserProfile } from '../_constants/user-profile';
import { Searchable, SearchableClass } from '../_decorators/searchable-class.decorator';
import { embeddedSerialize } from '../_helpers/class.helper';
import { Archived } from './archived.class';
import { EmbeddedCustomerAccount } from './customer-account.embedded.class';
import { Location } from './location.class';
import { EmbeddedOrganization } from './organization.embedded.class';
import { EmbeddedOrganizationalUnit } from './organizational-unit.embedded.class';
import { SearchItem } from './search.class';

export class SearchView {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  name: string;

  @PropertyMap({ type: [SearchItem], default: [] })
  items: SearchItem[];
}
export class UserOrganization {
  @PropertyMap({ type: EmbeddedOrganization })
  organization: EmbeddedOrganization;

  @PropertyMap()
  profile: UserProfile;

  @PropertyMap({ type: EmbeddedOrganizationalUnit, serialize: embeddedSerialize })
  organizationalUnit?: EmbeddedOrganizationalUnit | null;

  @PropertyMap()
  modules: Module[];
}
export class UserNotifications {
  @PropertyMap()
  equipmentStatuses: EquipmentStatus[];

  @PropertyMap()
  equipmentAlertStatuses: EquipmentStatus[];
}
export class UserCompany {
  @PropertyMap()
  name: string;

  @PropertyMap()
  position: string;

  @PropertyMap({ type: Location })
  location: Location;
}
@Searchable({ sortFields: 'firstname lastname email' })
export class User extends SearchableClass {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  sub: string;

  @PropertyMap()
  cognitoIdentityId: string;

  @PropertyMap()
  email: string;

  @PropertyMap()
  firstname: string;

  @PropertyMap()
  lastname: string;

  @PropertyMap()
  phone: string;

  @PropertyMap()
  cellphone: string;

  @PropertyMap({ type: UserCompany })
  company: UserCompany;

  @PropertyMap({ type: [UserOrganization] })
  organizations: UserOrganization[];

  @PropertyMap()
  isAdministrator: boolean;

  @PropertyMap({ type: [EmbeddedCustomerAccount], serialize: embeddedSerialize })
  customerAccounts: EmbeddedCustomerAccount[];

  @PropertyMap()
  isConfirmed: boolean;

  @PropertyMap({ type: 'Moment' })
  lastAuthenticationDate: Moment;

  @PropertyMap({ type: UserNotifications })
  notifications: UserNotifications;

  @PropertyMap({ default: 0 })
  unreadNotifications: number;

  @PropertyMap({ type: [SearchView], default: [] })
  searchViews: SearchView[];

  @PropertyMap({ type: Date })
  createdAt: Date;

  @PropertyMap({ type: Date })
  updatedAt: Date;

  @PropertyMap({ type: Archived })
  __archived: Archived;

  public getOrganization(id: string): UserOrganization | undefined {
    return find(this.organizations, (org) => org.organization._id === id);
  }

  public isOrganizationCustomerAccount(
    data: string | { customerAccountId: string } | { customerAccount: { _id: string } } | undefined | null,
  ): boolean {
    if (isNil(data)) return false;
    const id = typeof data === 'string' ? data : get(data, 'customerAccountId', get(data, 'customerAccount._id'));
    if (!id) return false;
    return !!find(this.customerAccounts, (ca) => ca._id === id);
  }

  public isOrganizationAdministrator(id?: string | null): boolean {
    if (!id) return false;
    return (
      get(
        find(this.organizations, (userOrganization) => userOrganization.organization._id === id),
        'profile',
        UserProfile.USER,
      ) === UserProfile.ADMINISTRATOR
    );
  }

  public getOrganizationModules(id?: string | null): Module[] {
    if (!id) return [];
    return get(
      find(this.organizations, (userOrganization) => userOrganization.organization._id === id),
      'modules',
      [],
    );
  }

  public getOption() {
    return {
      value: this._id,
      name: this.firstname && this.lastname ? `${this.firstname} ${this.lastname}` : `${this.email}`,
    };
  }

  public getOrganizationEquipmentFamilies(id?: string | null): EquipmentFamily[] {
    if (!id) return [];
    const modules = get(
      find(this.organizations, (userOrganization) => userOrganization.organization._id === id),
      'modules',
      [],
    );
    const equipmentFamilies: EquipmentFamily[] = [];
    each(modules, (module) =>
      each(EquipmentFamilyByModule[module], (equipmentFamily) => {
        if (!includes(equipmentFamilies, equipmentFamily)) equipmentFamilies.push(equipmentFamily);
      }),
    );
    return equipmentFamilies;
  }
}
