/** @format */

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { get } from 'lodash-es';
import { lastValueFrom } from 'rxjs';
import { INotificationData, NotificationComponent, NotificationStatus } from './notification.component';
import { NotificationModule } from './notification.module';

export interface INotificationOptions {
  duration?: number;
  icon?: string;
}

export interface ITaskNotificationOptions {
  message: string;
  status: NotificationStatus;
  progress?: number;
  error?: string;
  duration?: number;
}

@Injectable({
  providedIn: NotificationModule,
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  snackBarInstance: MatSnackBarRef<NotificationComponent> | null;

  public async open(
    message: string,
    options?: INotificationOptions & { color: string },
  ): Promise<MatSnackBarRef<NotificationComponent>> {
    return this.pushNotification({
      level: null,
      color: get(options, 'color', 'var(--app-color-primary)'),
      icon: get(options, 'icon'),
      message,
      duration: get(options, 'duration', 2000),
    });
  }

  public async info(message: string, options?: INotificationOptions): Promise<MatSnackBarRef<NotificationComponent>> {
    return this.pushNotification({
      level: 'info',
      icon: get(options, 'icon', 'info'),
      message,
      duration: get(options, 'duration', 2000),
    } as INotificationData);
  }

  public async success(
    message: string,
    options?: INotificationOptions,
  ): Promise<MatSnackBarRef<NotificationComponent>> {
    return this.pushNotification({
      level: 'success',
      icon: get(options, 'icon', 'check_circle'),
      message,
      duration: get(options, 'duration', 2000),
    } as INotificationData);
  }

  public async error(message?: string, options?: INotificationOptions): Promise<MatSnackBarRef<NotificationComponent>> {
    return this.pushNotification({
      level: 'error',
      icon: get(options, 'icon', 'error'),
      message: message || $localize`Une erreur est survenue, si cela persiste veuillez contacter un administateur.`,
      duration: get(options, 'duration', null),
    } as INotificationData);
  }

  public async addTask(...tasks: ITaskNotificationOptions[]): Promise<MatSnackBarRef<NotificationComponent>> {
    return this.pushNotification(...tasks);
  }

  private pushNotification(...data: INotificationData[]) {
    if (!this.snackBarInstance) {
      this.snackBarInstance = this.snackBar.openFromComponent(NotificationComponent, {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        panelClass: 'notification-snack-bar',
      });
      lastValueFrom(this.snackBarInstance.afterDismissed()).then(() => (this.snackBarInstance = null));
    }
    this.snackBarInstance.instance.notifications.splice(0, 0, ...data);
    return this.snackBarInstance;
  }
}
