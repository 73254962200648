/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCountUpModule } from '../count-up/count-up.module';
import { FailureCountComponent } from './failure-count-label.component';

@NgModule({
  declarations: [FailureCountComponent],
  imports: [CommonModule, AppCountUpModule],
  exports: [FailureCountComponent],
})
export class FailureCountLabelModule {}
