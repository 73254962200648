/** @format */

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntersectionObserverModule } from 'ngx-intersection-observer';
import { NgPipesModule } from 'ngx-pipes';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { AppTooltipModule } from '../../_directives/tooltip/tooltip.module';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';
import { AppTranslateModule } from '../../app-translate.module';
import { AppLoaderModule } from '../loader/loader.module';
import { QuillModule } from '../quill/quill-config';
import { DatagridCellDirective } from './datagrid-cell.directive';
import { DatagridComponent } from './datagrid.component';

@NgModule({
  declarations: [DatagridComponent, DatagridCellDirective],
  imports: [
    CommonModule,
    HttpClientModule,
    AppTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AppFlexLayoutModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    NgScrollbarModule,
    ExtentedPipesModule,
    MatRippleModule,
    MatIconModule,
    IntersectionObserverModule,
    ScrollingModule,
    DragDropModule,
    NgPipesModule,
    MatButtonModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatTooltipModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTableModule,
    MatDatepickerModule,
    QuillModule,
    AppLoaderModule,
    AppTooltipModule,
  ],
  exports: [DatagridComponent, DatagridCellDirective],
})
export class DatagridModule {}
