/** @format */

import { PropertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { S3File } from './s3-file.class';

export class EntityDocument {
  static accept = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/png',
    'image/jpeg',
    'image/jpg',
  ];

  @PropertyMap()
  name: string;

  @PropertyMap({ type: 'Moment' })
  date: Moment;

  @PropertyMap({ type: S3File })
  file: S3File;

  @PropertyMap()
  kind: string;
}
