/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';
import { AppCountUpModule } from '../count-up/count-up.module';
import { NotificationComponent } from './notification.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  imports: [
    CommonModule,
    AppFlexLayoutModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    AppCountUpModule,
    ExtentedPipesModule,
    MatProgressSpinnerModule,
    NgScrollbarModule
  ],
  declarations: [NotificationComponent],
  exports: [MatSnackBarModule],
})
export class NotificationModule {}
