/** @format */

import { PropertyMap } from 'model-mapper';
import { SearchableClass, Searchable } from '../_decorators/searchable-class.decorator';

@Searchable({ sortFields: 'firstname lastname email' })
export class EmbeddedUser extends SearchableClass {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  sub: string;

  @PropertyMap()
  email: string;

  @PropertyMap()
  firstname: string;

  @PropertyMap()
  lastname: string;

  @PropertyMap()
  phone: string;
}
