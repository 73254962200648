/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentFloorKindComponent } from './equipment-floor-kind.component';
import { MatIconModule } from '@angular/material/icon';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';

@NgModule({
  declarations: [EquipmentFloorKindComponent],
  imports: [CommonModule, MatIconModule, AppFlexLayoutModule],
  exports: [EquipmentFloorKindComponent],
})
export class EquipmentFloorKindModule {}
