import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StorageFileUrlPipe } from './storage-file.pipe';
import { GalleryStorageFileUrlPipe } from './gallery-storage-file.pipe';

@NgModule({
  declarations: [StorageFileUrlPipe, GalleryStorageFileUrlPipe],
  imports: [CommonModule],
  exports: [StorageFileUrlPipe, GalleryStorageFileUrlPipe],
})
export class AwsPipesModule {}
