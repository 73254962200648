/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPipesModule } from 'ngx-pipes';
import { AbsolutePipe } from './absolute.pipe';
import { AsyncFunctionPipe } from './async-function.pipe';
import { FormValuePipe } from './controlValue.pipe';
import { CountPipe } from './count.pipe';
import { DifferencePipe } from './difference.pipe';
import { DurationPipe } from './duration.pipe';
import { EqualsPipe } from './equals.pipe';
import { FileUrlPipe } from './file-url.pipe';
import { FilterPipe } from './filter.pipe';
import { FindPipe } from './find.pipe';
import { FindIndexPipe } from './findIndex.pipe';
import { FormArrayPipe } from './formArray.pipe';
import { GetPipe } from './get.pipe';
import { HeadPipe } from './head.pipe';
import { HumanizeDurationPipe } from './humanize-duration.pipe';
import { IncludesPipe } from './includes.pipe';
import { IsNilPipe } from './is-nil.pipe';
import { JoinPipe } from './join.pipe';
import { LastPipe } from './last.pipe';
import { MergePipe } from './merge.pipe';
import { MomentDiffPipe } from './moment-diff.pipe';
import { MomentPipe } from './moment.pipe';
import { NumberMatchPipe } from './number-match.pipe';
import { SplitInRowsPipe } from './row.pipe';
import { SafePipe } from './safe.pipe';
import { SplitPipe } from './split.pipe';
import { ToDataUrlPipe } from './to-data-url.pipe';
import { ToDatePipe } from './to-date.pipe';
import { ToPercentPipe } from './to-percent.';
import { ToStringPipe } from './to-string.pipe';

const pipes = [
  AbsolutePipe,
  AsyncFunctionPipe,
  CountPipe,
  DifferencePipe,
  DurationPipe,
  EqualsPipe,
  FileUrlPipe,
  FilterPipe,
  FindIndexPipe,
  FindPipe,
  FormArrayPipe,
  FormValuePipe,
  GetPipe,
  HeadPipe,
  HumanizeDurationPipe,
  IsNilPipe,
  JoinPipe,
  LastPipe,
  MomentDiffPipe,
  MomentPipe,
  NumberMatchPipe,
  SafePipe,
  SplitInRowsPipe,
  SplitPipe,
  ToDataUrlPipe,
  ToStringPipe,
  ToDatePipe,
  ToPercentPipe,
  MergePipe,
  IncludesPipe,
];

@NgModule({
  declarations: [pipes],
  imports: [CommonModule],
  exports: [NgPipesModule, ...pipes],
})
export class ExtentedPipesModule {}
