/** @format */

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AframeComponent } from './aframe.component';

import 'aframe';
import 'aframe-orbit-controls';
import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
// import 'aframe-troika-text';
import { Entity } from 'aframe';
import 'aframe-drag-controls';
import { Camera, Scene, Vector3, WebGLRenderer } from 'three';
import { AframeFileUrlPipe } from './aframe-file-url.pipe';

export interface IEventData {
  scene: Scene;
  camera: Camera;
  el: Entity;
}

export class AframeTransformEvent extends Event {
  constructor(public data: IEventData & { position: Vector3; rotation: Vector3; scale: Vector3 }) {
    super('aframeTransform');
    // console.log('rotate event', data);
  }
}

export class AframeClickEvent extends Event {
  constructor(public data: IEventData & { point: Vector3; distance: number }) {
    super('aframeClick');
    // console.log('click event', data);
  }

  public getPointString(): string {
    return `${this.data.point.x.toFixed(2)}, ${this.data.point.y.toFixed(2)}, ${this.data.point.z.toFixed(2)}`;
  }
}

AFRAME.registerComponent('clickable', {
  init: function () {
    let click = false;
    const mouseMovedListener = () => (click = false);
    const mouseDownListener = (e: Event) => {
      e.stopPropagation();
      click = true;
      document.addEventListener('mousemove', mouseMovedListener);
    };
    const mouseUpListener = (e: any) => {
      document.removeEventListener('mousemove', mouseMovedListener);
      if (click) {
        const renderer: WebGLRenderer | undefined = this.el.sceneEl?.renderer;
        const scene: Scene | undefined = this.el.sceneEl?.object3D as Scene;
        const camera: Camera = this.el.getObject3D('camera') as Camera;
        const point = e.detail.intersection.point;
        const distance = camera?.position.distanceTo(point);
        this.el.dispatchEvent(new AframeClickEvent({ scene, camera, point, distance, el: this.el }));
      }
      click = false;
    };
    this.el.addEventListener('mousedown', mouseDownListener);
    this.el.addEventListener('mouseup', mouseUpListener);
  },
});

AFRAME.registerComponent('transform-controls', {
  schema: {
    objects: { type: 'string', default: '[transformable]' },
  },
  init: function () {
    const renderer: WebGLRenderer | undefined = this.el.sceneEl?.renderer;
    const scene: Scene | undefined = this.el.sceneEl?.object3D as Scene;
    const camera: Camera = this.el.getObject3D('camera') as Camera;
    if (!renderer) return;
    const transformControls = new TransformControls(camera, renderer.domElement);
    transformControls.addEventListener('objectChange', (event: any) => {
      const object = transformControls.object;
      if (!object) return;
      const d = THREE.MathUtils.radToDeg;
      this.el.dispatchEvent(
        new AframeTransformEvent({
          scene,
          camera,
          el: this.el,
          position: object.position,
          rotation: new Vector3(d(object.rotation.x), d(object.rotation.y), d(object.rotation.z)),
          scale: object.scale,
        }),
      );
    });
    transformControls.addEventListener('dragging-changed', (event: any) => {
      // lookControlsEnabled = !event.value;
    });
    scene.add(transformControls);

    // let click = false;
    // const mouseMovedListener = () => (click = false);
    // const mouseDownListener = (e: Event) => {
    //   e.stopPropagation();
    //   click = true;
    //   document.addEventListener('mousemove', mouseMovedListener);
    // };
    // const mouseUpListener = (e: any) => {
    //   document.removeEventListener('mousemove', mouseMovedListener);
    //   if (click) {
    //     const sceneElmt = document.querySelector('a-scene');
    //     const camera = (sceneElmt?.camera as any)?.el;
    //     // this.el.dispatchEvent(new AframeClickEvent({ scene: sceneElmt.object3D, camera, point, distance, el: this.el }));
    //   }
    //   click = false;
    // };
    // this.el.addEventListener('mousedown', mouseDownListener);
    // this.el.addEventListener('mouseup', mouseUpListener);
  },
});

// AFRAME.registerComponent('rotable', {
//   init: function () {
//     let drag = false;
//     const mouseMovedListener = (e: MouseEvent) => {
//       e.stopPropagation();
//       this.el.dispatchEvent(new AframeRotateEvent({ x: e.movementX, y: e.movementY }));
//     };
//     const mouseDownListener = (e: Event) => {
//       e.stopPropagation();
//       drag = true;
//       document.addEventListener('mousemove', mouseMovedListener);
//     };
//     const mouseUpListener = (e: any) => {
//       drag = false;
//       document.removeEventListener('mousemove', mouseMovedListener);
//     };
//     this.el.addEventListener('mousedown', mouseDownListener);
//     this.el.addEventListener('mouseup', mouseUpListener);
//   },
// });

// AFRAME.registerComponent('draggable', DraggableComponent);

// @ts-ignore
// import registerClickDrag from './aframe-drag-and-drop';
// registerClickDrag(aframe);

// AFRAME.registerComponent('has-draggable-components', {
//   init: function () {
//     console.log('has-draggable-components', this.el);
//     this.el.addEventListener('drag-controls:changed', (event: any) => {
//       console.log('has-draggable-components', event);
//       // event.target.setAttribute('orbit-controls', 'enabled', !event.detail.active);
//       event.target.setAttribute('look-controls', 'enabled', !event.detail.active);
//     });
//   },
// });

// AFRAME.registerComponent('drag-rotate', {

//   ifMouseDown: false,
//   x_cord: 0,
//   y_cord: 0,
//   schema: { speed: { default: 1 } },

//   init: function () {
//     this.ifMouseDown = false;
//     this.x_cord = 0;
//     this.y_cord = 0;
//     this.el.addEventListener('mousedown', this.OnDocumentMouseDown.bind(this));
//     this.el.addEventListener('mouseup', this.OnDocumentMouseUp.bind(this));
//     document.addEventListener('mousemove', this.OnDocumentMouseMove.bind(this));
//   },

//   OnDocumentMouseDown: function (event: any) {
//     this.ifMouseDown = true;
//     this.x_cord = event.clientX;
//     this.y_cord = event.clientY;
//   },

//   OnDocumentMouseUp: function () {
//     this.ifMouseDown = false;
//   },

//   OnDocumentMouseMove: function (event: any) {
//     if (this.ifMouseDown) {
//       var temp_x = event.clientX - this.x_cord;
//       var temp_y = event.clientY - this.y_cord;
//       if (Math.abs(temp_y) < Math.abs(temp_x)) {
//         this.el.object3D.rotateY((temp_x * this.data.speed) / 1000);
//       } else {
//         this.el.object3D.rotateX((temp_y * this.data.speed) / 1000);
//       }
//       this.x_cord = event.clientX;
//       this.y_cord = event.clientY;
//     }
//   },
// });

@NgModule({
  declarations: [AframeComponent, AframeFileUrlPipe],
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [AframeFileUrlPipe],
})
export class AframeModule {
  constructor() {}
}
