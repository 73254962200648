/** @format */

import { PropertyMap } from 'model-mapper';
import { SesioNodeComponentKind } from '../../_constants/sesio-node-component/sesio-node-component-kind';
import { SesioNodeComponentStatus } from '../../_constants/sesio-node-component/sesio-node-component-status';
import { Archived } from '../archived.class';

export class EmbeddedSesioNodeComponentNode {
  @PropertyMap()
  _id: String;

  @PropertyMap()
  reference: string;
}
export class EmbeddedSesioNodeComponent {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  reference: string;

  @PropertyMap()
  label?: string;

  @PropertyMap()
  status: SesioNodeComponentStatus;

  @PropertyMap()
  kind: SesioNodeComponentKind;

  @PropertyMap({ type: EmbeddedSesioNodeComponentNode })
  node: EmbeddedSesioNodeComponentNode;

  @PropertyMap({ type: Archived })
  __archived: Archived;
}
