/** @format */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toDate' })
export class ToDatePipe implements PipeTransform {
  transform(data: any): Date | null {
    if (data instanceof Date) return data;
    if (typeof data === 'string') return new Date(data);
    if (typeof data === 'number') return new Date(data);
    if (typeof data?.valueOf === 'function') return new Date(data.valueOf());
    return null;
  }
}
