/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutDirective } from './flex-layout.directive';
import { FlexDirective } from './flex.directive';

@NgModule({
  declarations: [FlexLayoutDirective, FlexDirective],
  imports: [CommonModule],
  exports: [FlexLayoutDirective, FlexDirective],
})
export class AppFlexLayoutModule {}
