/** @format */

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { translations } from '@aws-amplify/ui-angular';
import { I18n } from 'aws-amplify';
import { reduce } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { SessionData } from './app.session.data';

@NgModule({
  imports: [HttpClientModule],
  exports: [],
})
export class AppAmplifyModule {
  constructor(private logger: NGXLogger) {
    this.setLocale('fr');
  }

  public setLocale(locale: 'fr' | 'en') {
    I18n.putVocabularies(translations);
    const frVocabulary = [
      {
        value: $localize`Votre code est en route. Pour vous connecter, entrez le code que nous avons envoyé par e-mail`,
        voc: 'Your code is on the way. To log in, enter the code we emailed to',
      },
      { value: $localize`E-mail`, voc: 'Email' },
      { value: $localize`Entrez votre nom`, voc: 'Enter your Family Name' },
      { value: $localize`Entrez votre prénom`, voc: 'Enter your Given Name' },
      { value: $localize`Entrez votre e-mail`, voc: 'Enter your Email' },
      { value: $localize`Entrez votre e-mail`, voc: 'Enter your email' },
      { value: $localize`Entrez votre mot de passe`, voc: 'Enter your Password' },
      { value: $localize`Nom`, voc: 'Family Name' },
      { value: $localize`Prénom`, voc: 'Given Name' },
      { value: $localize`E-mail ou mot de passe incorrecte`, voc: 'Incorrect username or password.' },
      { value: $localize`Vos mots de passe doivent correspondre`, voc: 'Your passwords must match' },
      { value: $localize`Confirmez votre mot de passe`, voc: 'Please confirm your Password' },
      { value: $localize`Réinitialisation du mot de passe`, voc: 'Reset Password' },
      { value: $localize`Cela peut prendre une minute pour arriver.`, voc: 'It may take a minute to arrive.' },
      {
        value: $localize`Votre code est en route. Pour vous connecter, entrez le code que nous avons envoyé par SMS`,
        voc: 'Your code is on the way. To log in, enter the code we texted to',
      },
      { value: $localize`E-mail ou mot de passe incorrecte`, voc: 'User does not exist.' },
      { value: $localize`Nous vous avons envoyé un e-mail`, voc: 'We Emailed You' },
      {
        value: $localize`Le mot de passe utilisateur ne peut pas être réinitialisé dans l'état actuel.`,
        voc: 'User password cannot be reset in the current state.',
      },
    ];
    I18n.putVocabulariesForLanguage(
      locale,
      reduce(frVocabulary, (pv, cv) => ((pv[cv.voc] = cv.value), pv), {} as any),
    );
    I18n.setLanguage(locale);
    SessionData.$locale.next(locale);
    I18n.setLanguage(locale);
  }
}

// Suite à votre première connexion à l'application sésiO et votre modification de mot de passe, nous devons vérifier votre adresse email.
// Cette étape n'est à effectuer qu'une seule fois.
// Pour cela, veuillez cocher "email" ci-dessous puis cliquez sur le bouton Vérifier.
// Vous recevrez un code par email qu'il vous faudra renseigner dans la prochaine étape.

// Veuillez indiquer ci-dessous le code reçu par email (pensez à vérifier les "courriers indésirables (spam)) et cliquez sur le bouton Soumettre.
// Votre adresse email sera définitivement vérifiée et vous pourrez vous connecter à votre compte sésiO
