/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(start: number | Date | Moment | string, end?: number | Date | Moment | string | null): number | null {
    const startTime = this.getValueOf(start);
    if (startTime === null) {
      console.error(`DurationPipe failed to convert start ${start}`);
      return null;
    }
    const endTime = this.getValueOf(end);
    if (endTime === null) {
      console.error(`DurationPipe failed to convert end ${end}`);
      return null;
    }
    return endTime - startTime;
  }

  getValueOf(date?: number | Date | Moment | string | null): number | null {
    if (!date) return Date.now();
    switch (typeof date) {
      case 'number':
        return date;
      case 'string':
        return new Date(date).valueOf();
      default:
        if (typeof date.valueOf === 'function') return date.valueOf();
        return null;
    }
  }
}
