/** @format */

import { API, graphqlOperation } from 'aws-amplify';
import { get, isNil, merge } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import { map, Observable } from 'rxjs';
import { CacheChanged } from '../../API';
import { onUpdateCacheChanged, onUpsertEntityProperty } from '../../graphql/subscriptions';
import { MethodLogger } from './logger.service';

export interface IProperty<T> {
  entityID: string;
  entityModel: string;
  propertyName: string;
  value: T;
  time: Date;
  createdAt: Date;
  updatedAt: Date;
}

export class GraphqlServiceClass {
  @MethodLogger()
  public onCachedUpdated(entityID: string): Observable<CacheChanged> {
    const gqlhttpServiceArguments: any = { entityID };
    return new Observable<any>((observer) => {
      (API.graphql(graphqlOperation(onUpdateCacheChanged, gqlhttpServiceArguments)) as any).subscribe(observer);
    }).pipe(map((data) => get(data, 'value.data.onUpdateCacheChanged')));
  }

  @MethodLogger()
  public onPropertyUpsert<T = any>(
    entityID: string,
    propertyName: string,
    mapper?: ModelMapper<T>,
  ): Observable<IProperty<T>> {
    const gqlhttpServiceArguments: any = { entityID, propertyName };
    return new Observable<any>((observer) => {
      (API.graphql(graphqlOperation(onUpsertEntityProperty, gqlhttpServiceArguments)) as any).subscribe(observer);
    }).pipe(
      map((data) => get(data, 'value.data.onUpsertEntityProperty')),
      map((data) => {
        const parsedValue = isNil(data.value) ? data.value : JSON.parse(data.value);
        const value = isNil(parsedValue) ? parsedValue : mapper ? mapper.map(parsedValue) : parsedValue;
        return merge(data, {
          value,
          time: new Date(data.time),
          createdAt: new Date(data.createdAt),
          updatedAt: new Date(data.updatedAt),
        });
      }),
    );
  }
}

export const GraphqlService = new GraphqlServiceClass();
