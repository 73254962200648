/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';

@Pipe({ name: 'numberMatch' })
export class NumberMatchPipe implements PipeTransform {
  transform(value: number | null | undefined, map: { min: number; max: number; value: any }[]): any {
    if (isNil(value)) return null;
    const m = map.find((m) => (isNil(m.min) || m.min <= value) && (isNil(m.max) || m.max > value));
    return m?.value;
  }
}
