/** @format */

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { API, Auth } from 'aws-amplify';
import { get, has, merge, set } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import { v4 } from 'uuid';
import { environment } from '../../environments/environment';
import { BadRequestException } from '../_classes/request-exception/bad-request.exception.class';
import { FailedDependencyException } from '../_classes/request-exception/failed-dependency.exception.class';
import { S3File } from '../_classes/s3-file.class';
import { NotificationService } from '../_components/notification/notification.service';
import { SessionData } from '../app.session.data';
import { FileService } from './file.service';
import { MethodLogger } from './logger.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public API = API;

  private fileService = inject(FileService);
  private navigationService = inject(NavigationService);
  private notificationService = inject(NotificationService);

  @MethodLogger()
  public async get(path: string, queryStringParameters?: any): Promise<any> {
    return this.API.get(environment.apiName!, path, {
      queryStringParameters: this.buildQueryString(queryStringParameters),
    }).catch((err) => this.handleError(err));
  }

  @MethodLogger()
  public async post(path: string, body: any = {}, queryStringParameters?: any): Promise<any> {
    return this.API.post(environment.apiName!, path, {
      body,
      queryStringParameters: this.buildQueryString(queryStringParameters),
    }).catch((err) => this.handleError(err));
  }

  @MethodLogger()
  public async patch(path: string, body: any = {}, queryStringParameters?: any): Promise<any> {
    return this.API.patch(environment.apiName!, path, {
      body,
      queryStringParameters: this.buildQueryString(queryStringParameters),
    }).catch((err) => this.handleError(err));
  }

  @MethodLogger()
  public async delete(path: string, queryStringParameters?: any): Promise<any> {
    return this.API.del(environment.apiName!, path, {
      queryStringParameters: this.buildQueryString(queryStringParameters),
    }).catch((err) => this.handleError(err));
  }

  public async processFile(data: S3File, prefix: string, name?: string): Promise<S3File> {
    if (data && !data.key && data.file) {
      if (!name) name = v4();
      return this.fileService
        .pushFile(`${prefix}/${name}`, data.file)
        .then((file) => merge(data, { file: null }, file));
    }
    return data;
  }

  public async processFiles(data: S3File[], prefix: string): Promise<S3File[]> {
    const promises: Promise<S3File>[] = [];
    for (let d of data || []) {
      promises.push(this.processFile(d, prefix));
    }
    return Promise.all(promises);
  }

  public async processEmbeddedFile<T extends { file: S3File }>(data: T, prefix: string, name?: string): Promise<T> {
    await this.processFile(data?.file, prefix, name);
    return data;
  }

  public async processEmbeddedFiles<T extends { file: S3File }>(data: T[], prefix: string): Promise<T[]> {
    const promises: Promise<T>[] = [];
    for (let d of data || []) {
      promises.push(this.processEmbeddedFile(d, prefix));
    }
    return Promise.all(promises);
  }

  public processEmbeddedEntity(data: any, entity: string) {
    if (has(data, entity)) {
      const id = get(data, `${entity}._id`, null);
      set(data, `${entity}Id`, id);
      delete data[entity];
    }
  }

  private buildQueryString(queryStringParameters: any): any {
    if (!this.navigationService.isOrganizationScoped) return queryStringParameters;
    if (!queryStringParameters?.organizationId) {
      queryStringParameters = merge({}, queryStringParameters, {
        organizationId: SessionData.$organization.value?._id,
      });
    }
    return queryStringParameters;
  }

  private async handleError(err: HttpErrorResponse): Promise<any> {
    const status = get(err, 'status', get(err, 'response.status', 'unknown'));
    switch (status) {
      case 400:
        console.warn(err);
        this.notificationService.error(new ModelMapper(BadRequestException).map(get(err, 'response.data')).toHtml());
        throw err;
      case 401:
        return Auth.signOut();
      case 403:
        return this.navigationService.navigate('/');
      case 424:
        console.warn(err);
        this.notificationService.error(
          new ModelMapper(FailedDependencyException).map(get(err, 'response.data')).toHtml(),
        );
        throw err;
      default:
        this.notificationService.error(`Erreur serveur, si cela reproduit veuillez contacter un administrateur`);
        throw err;
    }
  }
}
