<div *ngIf="ready && elevator?.metadata" id="container" [class.loading]="loading">
  <a-scene id="aframe" vr-mode-ui="enabled: false" embedded background="color: #333"
    loading-screen="dotsColor: #ffffff; backgroundColor: #000000" (loaded)="loaded($event)"
    [attr.inspector]="fullscreen ? 'https://cdn.jsdelivr.net/gh/aframevr/aframe-inspector@master/dist/aframe-inspector.min.js' : undefined">

    <a-assets>
      <a-asset-item id="lift-cabin-obj" src="assets/objs/sesiO-lift-cabin.obj"></a-asset-item>
      <a-asset-item id="lift-cabin-mtl" src="assets/objs/sesiO-lift-cabin.mtl"></a-asset-item>
      <a-asset-item id="lift-center-pulley-obj" src="assets/objs/sesiO-lift-center-pulley.obj"></a-asset-item>
      <a-asset-item id="lift-center-pulley-mtl" src="assets/objs/sesiO-lift-center-pulley.mtl"></a-asset-item>
      <a-asset-item id="lift-door-pulley-obj" src="assets/objs/sesiO-lift-door-pulley.obj"></a-asset-item>
      <a-asset-item id="lift-door-pulley-mtl" src="assets/objs/sesiO-lift-door-pulley.mtl"></a-asset-item>
      <a-asset-item id="lift-left-door-obj" src="assets/objs/sesiO-lift-left-door.obj"></a-asset-item>
      <a-asset-item id="lift-left-door-mtl" src="assets/objs/sesiO-lift-left-door.mtl"></a-asset-item>
      <a-asset-item id="lift-right-door-obj" src="assets/objs/sesiO-lift-right-door.obj"></a-asset-item>
      <a-asset-item id="lift-right-door-mtl" src="assets/objs/sesiO-lift-right-door.mtl"></a-asset-item>
      <a-asset-item id="lift-floor-obj" src="assets/objs/sesiO-lift-floor.obj"></a-asset-item>
      <a-asset-item id="lift-floor-mtl" src="assets/objs/sesiO-lift-floor.mtl"></a-asset-item>
      <a-asset-item id="lift-wall-obj" src="assets/objs/sesiO-lift-wall.obj"></a-asset-item>
      <a-asset-item id="lift-wall-mtl" src="assets/objs/sesiO-lift-wall.mtl"></a-asset-item>
      <a-asset-item id="lift-cable-obj" src="assets/objs/sesiO-lift-cable.obj"></a-asset-item>
      <a-asset-item id="lift-cable-mtl" src="assets/objs/sesiO-lift-cable.mtl"></a-asset-item>
    </a-assets>

    <a-entity id="light-ambient"
      [attr.light]="'type: ambient; color: ' + (elevator.status === EquipmentStatus.STOPPED ? '#000' : '#fff') + '; intensity: 1.5;'"></a-entity>

    <a-camera id="camera" position="-45 190 75" rotation="0 -45 0" wasd-controls-enabled="false"
      look-controls-enabled="false">
      <a-entity cursor="rayOrigin :mouse" raycaster="objects: .collidable"></a-entity>
      <a-entity id="light-spot" light="type: spot; castShadow: true; angle: 40; decay: 1; penumbra: 0.1"></a-entity>
    </a-camera>

    <!-- Elevator -->
    <a-entity id="elevator">
      <a-obj-model id="cabin" src="#lift-cabin-obj" mtl="#lift-cabin-mtl" shadow></a-obj-model>
      <a-entity id="cabin-spot"
        light="type: spot; color: orange; castShadow: true; intensity: 3; distance: 300; angle: 17; penumbra: 0.1;"
        position="189 225 -231" rotation="-76 -175 -169"></a-entity>

      <a-obj-model id="pulley" src="#lift-center-pulley-obj" mtl="#lift-center-pulley-mtl"
        position="196.250 282.301 -241.620"
        [attr.animation]="'property: rotation; from: 0 0 0; to: 0 0 360; dir: ' + (direction >= 0 ? 'normal' : 'reverse') + '; loop: ' + (currentSpeed !== 0 ? 'true' : 'false') + '; dur: 3000; easing: linear;'"
        shadow>
      </a-obj-model>

      <a-obj-model id="door-pulley-left" src="#lift-door-pulley-obj" mtl="#lift-door-pulley-mtl" position="135 258 -107"
        [attr.animation]="[EquipmentDoorState.OPEN].includes(doorState) ? 'property: rotation; from: 0 0 0; to: 0 0 720; loop: false; dur: ' + (ready ? 2000 : 0) + '; easing: linear;' : null"
        [attr.animation__closing]="![EquipmentDoorState.OPEN].includes(doorState) ? 'property: rotation; from: 0 0 0; to: 0 0 -720; loop: false; dur: ' + (ready ? 2000 : 0) + '; easing: linear;' : null"
        shadow>
      </a-obj-model>
      <a-obj-model id="door-pulley-right" src="#lift-door-pulley-obj" mtl="#lift-door-pulley-mtl"
        position="166 258 -109"
        [attr.animation]="[EquipmentDoorState.OPEN].includes(doorState) ? 'property: rotation; from: 0 0 0; to: 0 0 360; loop: false; dur: ' + (ready ? 1000 : 0) + '; easing: linear;' : null"
        [attr.animation__closing]="![EquipmentDoorState.OPEN].includes(doorState) ? 'property: rotation; from: 0 0 0; to: 0 0 -360; loop: false; dur: ' + (ready ? 1000 : 0) + '; easing: linear;' : null"
        shadow></a-obj-model>
      <a-obj-model id="left-door" src="#lift-left-door-obj" mtl="#lift-left-door-mtl" position="0 0 1"
        [attr.animation]="[EquipmentDoorState.OPEN].includes(doorState) ? 'property: position; to: 72 0 1; loop: false; dur: ' + (ready ? 2000 : 0) + '; easing: linear;' : null"
        [attr.animation__closing]="![EquipmentDoorState.OPEN].includes(doorState) ? 'property: position; from: 72 0 1; to: 0 0 1; loop: false; dur: ' + (ready ? 2000 : 0) + '; easing: linear;' : null"
        shadow>
      </a-obj-model>
      <a-obj-model id="right-door" src="#lift-right-door-obj" mtl="#lift-right-door-mtl" position="0 0 1"
        [attr.animation]="[EquipmentDoorState.OPEN].includes(doorState) ? 'property: position; to: 36 0 1; loop: false; dur: ' + (ready ? 1000 : 0) + '; easing: linear;' : null"
        [attr.animation__closing]="![EquipmentDoorState.OPEN].includes(doorState) ? 'property: position; from: 36 0 1; to: 0 0 1; loop: false; dur: ' + (ready ? 1000 : 0) + '; easing: linear;' : null"
        shadow>
      </a-obj-model>

      <a-box id="box" color="white" depth="10" height="10" width="10" position="134 297 -155" shadow
        [attr.animation]="'property: components.material.material.color; type: color; from: #fff; to: ' + BoxColor[elevator.status] + '; dur: 250; dir: alternate; loop: true;'">
        <a-entity *ngIf="elevator.status !== EquipmentStatus.OK" id="light-ambient-state"
          animation="property: rotation; to: 0 -360 0; loop: true; dur: 2000; easing: linear;">
          <a-entity [attr.light]="'type: spot; color: ' + BoxSpotColor[elevator.status] + '; intensity: 1;'">
          </a-entity>
          <a-entity [attr.light]="'type: spot; color: ' + BoxSpotColor[elevator.status] + '; intensity: 1;'"
            rotation="0 180 0"></a-entity>
        </a-entity>
      </a-box>
    </a-entity>


    <!-- Floors -->
    <a-entity *ngIf=" elevator.metadata?.floors" id="floors">
      <a-entity [attr.animation]="'property: position; to: 0 ' + ((currentFloorIndex || 0) * -300)
          + ' 0; loop: false; dur: ' + (ready ? 2100 : 0) + '; easing: linear;'" material="shader: flat">
        <a-entity *ngFor=" let floor of elevator.metadata!.floors; index as index"
          [attr.position]="'0 ' + (index * 300) + ' 0'">
          <a-obj-model src="#lift-floor-obj" mtl="#lift-floor-mtl">
          </a-obj-model>
          <a-obj-model src="#lift-wall-obj" mtl="#lift-wall-mtl" shadow></a-obj-model>
          <a-obj-model src="#lift-cable-obj" mtl="#lift-cable-mtl" shadow></a-obj-model>
          <a-box color="#fff" opacity="0.15" depth="2" height="4" width="0.5" position="193.508 161.369 -223.915"
            scale="441.896 -75.745 -121.980" shadow="cast: false; receive: false;"></a-box>

          <ng-container *ngIf="floor.shortName.length <= 2">
            <a-entity id="text"
              [attr.text]="'value: ' + floor.shortName + '; color: #ffffff; font: roboto; width: 140; wrapCount: 2; baseline: center; align: right; lineHeight: 36;'"
              geometry="primitive: plane" position="344 190 -70" rotation="0 -90 0" shadow></a-entity>
          </ng-container>

          <ng-container *ngIf="floor.shortName.length > 2">
            <a-entity id="text"
              [attr.text]="'value: ' + floor.shortName + '; color: #ffffff; font: roboto; width: 140; wrapCount: 4; baseline: center; align: right; lineHeight: 36;'"
              geometry="primitive: plane" position="344 190 -70" rotation="0 -90 0" shadow></a-entity>
          </ng-container>

        </a-entity>
      </a-entity>
    </a-entity>

  </a-scene>

  <button id="scene-fullscreen-button" mat-icon-button color="white" (click)="openFullscreenScene()">
    <ng-container [ngSwitch]="fullscreen">
      <mat-icon *ngSwitchCase="true">close_fullscreen</mat-icon>
      <mat-icon *ngSwitchCase="false">fullscreen</mat-icon>
    </ng-container>
  </button>

  <!-- <div *ngIf="!loading && camera" id="video" [class.fullscreen]="fullscreen" [class.zoomed]="videoZoomed"
    (click)="videoStatus === 'playing' ? videoZoomed = !videoZoomed : null">
    <button class="video-action-btn" *ngIf="videoStatus === 'stopped'" mat-icon-button (click)="startVideo()">
      <mat-icon>play_circle</mat-icon>
    </button>
    <button class="video-action-btn" *ngIf="videoStatus === 'playing'" mat-icon-button (click)="stopVideo()">
      <mat-icon>stop_circle</mat-icon>
    </button>
    <div *ngIf="videoStatus === 'loading'" id="noise-wrapper">
      <div id="noise"></div>
    </div>
    <video #video id="cabin-video" playsinline intersectionObserver (intersection)="loadVideo()"></video>
    <span class="video-feed-info" *ngIf="videoStatus === 'error'" color="danger" translate>no-video-feed</span>
    <span class="video-feed-info" *ngIf="videoStatus === 'loading'" color="white" translate>loading-video-feed</span>
    <span class="video-latency" *ngIf="latency" color="white" [translateParams]="{ value: latency | round }"
      translate>video-latency</span>
  </div> -->

  <div *ngIf="fullscreen" id="metrics" fxLayout="column" fxLayoutAlign="start end">
    <app-chart [options]="speedChartOptions" (ready)="loadSpeedChartData($event)">
    </app-chart>
  </div>

  <app-loader id="loader" [diameter]="100" *ngIf="loading">
  </app-loader>

</div>
