/** @format */

import { PropertyMap } from 'model-mapper';

export type HolidayKind = 'fixed' | 'flexible';

export class HolidayFixedDate {
  @PropertyMap()
  month: number;

  @PropertyMap()
  dayOfMonth: number;
}

export class Holiday {
  @PropertyMap()
  name: string;

  @PropertyMap()
  kind: HolidayKind;

  @PropertyMap({ type: HolidayFixedDate, default: null })
  date: HolidayFixedDate;

  @PropertyMap({ type: [Date], default: null })
  dates: Date[];
}
