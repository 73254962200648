/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(data: any[] | null | undefined, filterData: any): any[] {
    return filter(data, filterData);
  }
}
