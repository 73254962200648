/** @format */

import { PropertyMap } from 'model-mapper';
import { EquipmentStatus } from '../_constants/equipment-status';
import { Holiday } from './holiday.class';

export class ElevatorSignatureSettings {
  @PropertyMap()
  suspiciousThreshold: number;

  @PropertyMap()
  failureDelay: number; // in minutes
}

export class SignatureSettings {
  @PropertyMap({ type: ElevatorSignatureSettings })
  elevator: ElevatorSignatureSettings;
}

export class SettingsNotifications {
  @PropertyMap()
  elevatorAlertEnpoint: string;

  @PropertyMap()
  elevatorAlertStatuses: EquipmentStatus[];
}

export class SettingsSerenite {
  @PropertyMap()
  username: string;

  @PropertyMap()
  password: string;

  @PropertyMap({ type: Date })
  fetchedConfigurationDate: Date;

  @PropertyMap({ type: Date })
  fetchedEquipmentDate: Date;
}

export class OrganizationSettings {
  @PropertyMap({ type: SignatureSettings })
  signature: SignatureSettings;

  @PropertyMap()
  notifications: SettingsNotifications;

  @PropertyMap({ type: [Holiday] })
  holidays: Holiday[];

  @PropertyMap({ type: SettingsSerenite })
  serenite: SettingsSerenite;
}
