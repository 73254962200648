/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { fade } from '../../_constants/animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [fade],
})
export class LoaderComponent implements OnInit {
  @Input('absolute')
  set setAbsolute(absolute: boolean | string) {
    this.isAbsolute = typeof absolute === 'string' || absolute === true;
  }
  isAbsolute = false;

  @Input('backdrop')
  set setBackdrop(backdrop: boolean | string) {
    this.hasBackdrop = typeof backdrop === 'string' || backdrop === true;
  }
  hasBackdrop = false;

  @Input('diameter')
  set setDiameter(diameter: string | number) {
    if (typeof diameter === 'number') this.diameter = `${diameter}px`;
    else this.diameter = diameter;
  }
  diameter: string = '40px';

  constructor() {}

  ngOnInit() {}
}
