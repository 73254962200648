/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { EquipmentStatusComponent } from './equipment-status.component';

@NgModule({
  declarations: [EquipmentStatusComponent],
  imports: [CommonModule, AppFlexLayoutModule, MatIconModule],
  exports: [EquipmentStatusComponent],
})
export class EquipmentStatusModule {}
