/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { includes } from 'lodash-es';
import { Module } from '../_constants/module';
import { AuthService } from '../_services/auth.service';
import { SessionService } from '../_services/session.service';

@Injectable({
  providedIn: 'root',
})
export class ModuleGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private sessionService: SessionService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const user = await this.authService.getUser();
    if (!user) return this.router.createUrlTree(['/forbidden']);
    if (user.isAdministrator) return true;
    const organization = this.sessionService.$organization.value;
    if (!organization) return this.router.createUrlTree(['/forbidden']);
    const module: Module = route.data['module'];
    if (!module) return this.router.createUrlTree(['/forbidden']);
    if (user.isOrganizationAdministrator(organization._id)) return true;
    if (includes(user.getOrganizationModules(organization._id), module)) return true;
    return this.router.createUrlTree(['/forbidden']);
  }
}
