/** @format */

import { keys, map, reduce, upperCase } from 'lodash-es';
import { catchError, defer, finalize, Observable, tap } from 'rxjs';

export type LogLevel = 'debug' | 'info' | 'warn' | 'error';

export const LogColors = {
  Blue: '\x1b[34m',
  Green: '\x1b[32m',
  Yellow: '\x1b[33m',
  Red: '\x1b[31m',
  Reset: '\x1b[0m',

  debug: (msg: string): string => {
    return `${LogColors.Blue}${msg}${LogColors.Reset}`;
  },

  info: (msg: string): string => {
    return `${LogColors.Green}${msg}${LogColors.Reset}`;
  },

  warn: (msg: string): string => {
    return `${LogColors.Yellow}${msg}${LogColors.Reset}`;
  },

  error: (msg: string): string => {
    return `${LogColors.Red}${msg}${LogColors.Reset}`;
  },
};

export class AppLogger {
  protected path: string;

  constructor(
    protected name: string,
    protected parent?: string,
  ) {
    this.path = parent ? `${parent}::${name}` : `Sesio::${name}`;
  }

  debug(message: string, ...args: any): void {
    this.log('debug', message, ...args);
  }

  info(message: string, ...args: any): void {
    this.log('info', message, ...args);
  }

  warn(message: string, ...args: any): void {
    this.log('warn', message, ...args);
  }

  error(message: string | Error | unknown, ...args: any): void {
    this.log('error', message, ...args);
  }

  sub(name: string): AppLogger {
    return new AppLogger(name, this.path);
  }

  private log(level: LogLevel, ...args: any): void {
    const prefix = `${new Date().toISOString()} ${upperCase(level)}`;
    const path = `[${this.path}]`;
    switch (level) {
      case 'debug':
        return console.debug(LogColors[level](prefix), path, ...args);
      case 'info':
        return console.info(LogColors[level](prefix), path, ...args);
      case 'warn':
        return console.warn(LogColors[level](prefix), path, ...args);
      case 'error':
        return console.error(LogColors[level](prefix), path, ...args);
      default:
        console.log(prefix, path, ...args);
    }
  }
}

export interface IMethodLoggerOptions {
  level?: LogLevel;
}

export function MethodLogger(options: IMethodLoggerOptions = { level: 'debug' }): MethodDecorator {
  if (!options.level) options.level = 'debug';

  return (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    const className = target.constructor.name;
    const methodName = propertyKey.toString();

    descriptor.value = function (...args: unknown[]) {
      const start = Date.now();
      const logger = getLogger(className, methodName, this);
      const cleanArgs = cleanupArgs(args);
      try {
        const res = originalMethod.apply(this, args);
        return overrideResult(options, logger, cleanArgs, res, start);
      } catch (err: any) {
        logger.error('exception', err, { args, duration: Date.now() - start });
        throw err;
      }
    };
  };
}

function cleanupArg(arg: any): any {
  if (arg instanceof Promise) return '[Promise]';
  if (arg instanceof Observable) return '[Observable]';
  if (typeof arg === 'function') return '[Callback]';
  return arg;
}

function cleanupArgs(args: any): any {
  if (Array.isArray(args)) {
    return map(args, (arg: any) => cleanupArg(arg));
  }
  return reduce(keys(args), (pv: any, key: string) => ((pv[key] = cleanupArg(args[key])), pv), {} as any);
}

function getLogger(className: string, methodName: string, target: any): AppLogger {
  let logger: AppLogger = target.logger;
  if (!logger) return new AppLogger(methodName, `Sesio::${className}`);
  return logger.sub(methodName);
}

function overridePromiseResult(options: IMethodLoggerOptions, logger: AppLogger, args: any, res: Promise<any>) {
  const start = Date.now();
  res.then(
    (result) => {
      logger[options.level!]('resolve', args, result, `in ${Date.now() - start} ms`);
      return result;
    },
    (err: Error) => {
      logger.error('reject', args, err, `in ${Date.now() - start} ms`);
      return err;
    },
  );
  return res;
}

function overrideObservableResult(options: IMethodLoggerOptions, logger: AppLogger, args: any, res: Observable<any>) {
  return defer(() => {
    logger[options.level!]('subscribe', args);
    return res.pipe(
      tap((result) => logger[options.level!]('data', args, result)),
      catchError((err: Error) => {
        logger.error('error', args, err);
        throw err;
      }),
    );
  }).pipe(finalize(() => logger[options.level!]('finalize', { args })));
}

function overrideResult(options: IMethodLoggerOptions, logger: AppLogger, args: any, result: any, start: number) {
  if (result instanceof Promise) return overridePromiseResult(options, logger, args, result);
  if (result instanceof Observable) return overrideObservableResult(options, logger, args, result);
  logger[options.level!]('call', args, result, `in ${Date.now() - start} ms`);
  return result;
}
