/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XlsxViewerComponent } from './xlsx-viewer.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppTranslateModule } from '../../app-translate.module';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AwsPipesModule } from '../../_pipes/aws-pipes/aws-pipes.module';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';

@NgModule({
  imports: [
    CommonModule,
    AppFlexLayoutModule,
    AppTranslateModule,
    NgxDocViewerModule,
    MatProgressSpinnerModule,
    NgScrollbarModule,
    AwsPipesModule,
    ExtentedPipesModule,
  ],
  declarations: [XlsxViewerComponent],
  exports: [XlsxViewerComponent],
})
export class XlsxViewerModule {}
