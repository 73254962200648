/** @format */

import { keys } from 'lodash-es';
import { ModelMapper, PropertyMappingTree } from 'model-mapper';
import 'reflect-metadata';
import { embeddedMap, getPropertyNameFromEmbeddedId } from '../_helpers/class.helper';

export abstract class SearchableClass {
  abstract _id: string;
  static sortFields?: string;
  static fields?: string;
}

export interface ISearchableOptions {
  sortFields: string;
}

export function Searchable(options?: ISearchableOptions): ClassDecorator {
  return (target: any) => {
    target.sortFields = options?.sortFields;
    target.fields = getTreeFields(new ModelMapper(target).getPropertyMappingTree());
  };
}

function getTreeFields(tree: PropertyMappingTree, prefix?: string): string[] {
  const fields: string[] = [];
  for (let key of keys(tree)) {
    const info = tree[key];
    if (info.propertyMapping) {
      const embeddedFields = getTreeFields(info.propertyMapping, prefix ? `${prefix}.${key}` : key);
      fields.push(...embeddedFields);
    } else if (info.map === embeddedMap) {
      fields.push(`${getPropertyNameFromEmbeddedId(key)}._id`);
    } else {
      const valuePath = info.source ? info.source : key;
      fields.push(prefix ? `${prefix}.${valuePath}` : `${valuePath}`);
    }
  }
  return fields;
}
