import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisponibilityRateColorPipe } from './disponibility-rate-color.pipe';

@NgModule({
  declarations: [DisponibilityRateColorPipe],
  imports: [CommonModule],
  exports: [DisponibilityRateColorPipe],
})
export class ColorPipeModule {}
