/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { ColorPipeModule } from '../../_pipes/color-pipe/color-pipe.module';
import { AppCountUpModule } from '../count-up/count-up.module';
import { EquipmentStatusModule } from '../equipment-status/equipment-status.module';
import { AppLoaderModule } from '../loader/loader.module';
import { StarRateModule } from '../star-rate/star-rate.module';
import { EquipmentStatusDialogComponent } from './equipment-status-dialog.component';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';

@NgModule({
  declarations: [EquipmentStatusDialogComponent],
  imports: [
    CommonModule,
    AppFlexLayoutModule,
    AppLoaderModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    AppCountUpModule,
    StarRateModule,
    ColorPipeModule,
    MatDialogModule,
    EquipmentStatusModule,
    ExtentedPipesModule
  ],
  exports: [EquipmentStatusDialogComponent],
})
export class EquipmentStatusDialogModule {}
