/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CountUpModule as NgxCountUpModule } from 'ngx-countup';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { AppLoaderModule } from '../loader/loader.module';
import { CountUpComponent } from './count-up.component';

@NgModule({
  imports: [CommonModule, NgxCountUpModule, AppFlexLayoutModule, AppLoaderModule],
  declarations: [CountUpComponent],
  exports: [CountUpComponent],
})
export class AppCountUpModule {}
