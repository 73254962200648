/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ConfirmDialogDirective } from './confirm-dialog.directive';

@NgModule({
  declarations: [ConfirmDialogComponent, ConfirmDialogDirective],
  imports: [CommonModule, MatDialogModule, MatButtonModule, ExtentedPipesModule, MatIconModule, AppFlexLayoutModule],
  exports: [ConfirmDialogDirective, ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
