/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({ name: 'isNil' })
export class IsNilPipe implements PipeTransform {
  transform(data: any): boolean {
    return isNil(data);
  }
}
