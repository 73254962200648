/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import humanizeDuration, { HumanizerOptions } from 'humanize-duration';
import { merge } from 'lodash-es';
import { Moment } from 'moment';

@Pipe({ name: 'humanizeDuration' })
export class HumanizeDurationPipe implements PipeTransform {
  transform(data: number | Date | Moment | null | undefined, options?: HumanizerOptions): string | null {
    if (typeof data === 'number') {
      return humanizeDuration(data, merge({ language: 'fr', maxDecimalPoints: 0 }, options));
    }
    if (typeof data?.valueOf === 'function') {
      return humanizeDuration(Date.now() - data.valueOf(), merge({ language: 'fr', maxDecimalPoints: 0 }, options));
    }
    return null;
  }
}
