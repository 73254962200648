/** @format */

import { map, values } from 'lodash-es';

export enum UserProfile {
  ADMINISTRATOR = 'administrator',
  USER = 'user',
  TECHNICIAN = 'technician',
}

export const UserProfileName: { [profile: string]: string } = {
  [UserProfile.USER]: $localize`Utilisateur`,
  [UserProfile.ADMINISTRATOR]: $localize`Administrateur`,
  [UserProfile.TECHNICIAN]: $localize`Technicien`,
};

export const UserProfileOptions = map(values(UserProfile), (value) => ({
  value,
  name: UserProfileName[value],
}));
