/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { round } from 'lodash-es';

@Pipe({ name: 'toPercent' })
export class ToPercentPipe implements PipeTransform {
  transform(
    data: number,
    { suffix, defaultValue, roundValue }: { suffix?: string; defaultValue?: number; roundValue: number } = {
      suffix: ' %',
      defaultValue: 0,
      roundValue: 0,
    },
  ): string {
    return `${data ? round(data * 100, roundValue) : defaultValue}${suffix}`;
  }
}
