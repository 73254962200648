/** @format */

import { Injectable } from '@angular/core';
import { get, has, join, map, merge, set } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import { Observable, from } from 'rxjs';
import { SearchItem } from '../_classes/search.class';
import { SearchView, User } from '../_classes/user.class';
import { EmbeddedUser } from '../_classes/user.embedded.class';
import { IDatatableOptions } from '../_components/datagrid/datatable.class';
import { Module } from '../_constants/module';
import { UserProfile } from '../_constants/user-profile';
import { HttpService } from './http.service';

export interface InviteUser {
  email: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
}

export interface InviteUserToCustomerAccount extends InviteUser {
  customerAccountId: string;
}

export interface InviteUserToOrganization extends InviteUser {
  organizationId: string;
  profile: UserProfile;
  modules: Module[];
}

export interface IUserFilter {
  search?: string;
  organizationId?: string;
  customerAccountId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  protected pathBase = '/user';
  protected mapper = new ModelMapper(User);

  constructor(private httpService: HttpService) {}

  async search<EmbeddedUser>(
    limit: number,
    skip: number,
    filter: IUserFilter,
    type: new () => EmbeddedUser,
  ): Promise<{ data: EmbeddedUser[]; matchCount: number }>;
  async search(
    limit: number,
    skip: number,
    filter: IUserFilter,
    fields: string,
    sorts: string,
  ): Promise<{ data: User[]; matchCount: number }>;
  async search(
    limit: number,
    skip: number,
    filter: IUserFilter,
    data?: (new () => any) | ModelMapper<any> | string,
    sorts?: string,
  ): Promise<{ data: any[]; matchCount: number }> {
    const path = `${this.pathBase}/search`;
    const query: any = { skip, limit };
    if (typeof data === 'string') {
      merge(query, this.getFilterQuery(filter, data, sorts));
      return this.httpService.get(path, query).then((res) => ({
        matchCount: res.matchCount,
        data: map(res.data, (d: any) => this.mapper.map(d)),
      }));
    }
    if (typeof data === 'function') {
      const mapper = new ModelMapper(data);
      return this.httpService.get(path, query).then((res) => ({
        matchCount: res.matchCount,
        data: map(res.data, (d: any) => mapper.map(d)),
      }));
    }
    merge(query, this.getFilterQuery(filter, (this.mapper.type as any).fields, (this.mapper.type as any).sortFields));
    return this.httpService.get(path, query).then((res) => ({
      matchCount: res.matchCount,
      data: map(res.data, (d: any) => this.mapper.map(d)),
    }));
  }

  async list(): Promise<User[]> {
    const path = `${this.pathBase}`;
    const query: any = {};
    return this.httpService.get(path, query).then((data) => map(data, (d) => this.mapper.map(d)));
  }

  async count(filter?: { sesioPage?: boolean }): Promise<number> {
    const path = `${this.pathBase}/count`;
    const query: any = {};
    if (filter?.sesioPage) query.sesioPage = true;
    return this.httpService.get(path, query);
  }

  async me(): Promise<User> {
    const path = `${this.pathBase}/me`;
    return this.httpService.get(path).then((data) => this.mapper.map(data));
  }

  async getFromEmail(email: string): Promise<EmbeddedUser | null> {
    const path = `${this.pathBase}/email/${email}`;
    return this.httpService.get(path).then((data) => (data ? new ModelMapper(EmbeddedUser).map(data) : null));
  }

  async meUpdate(data: any): Promise<boolean> {
    const path = `${this.pathBase}/me`;
    return this.httpService.patch(path, data);
  }

  async setAllNotificationsRead(): Promise<boolean> {
    const path = `${this.pathBase}/all-notification-read`;
    return this.httpService.patch(path);
  }

  async updateNotifications(data: any): Promise<boolean> {
    const path = `${this.pathBase}/notifications`;
    return this.httpService.patch(path, data);
  }

  async addSearchView(name: string, items: SearchItem[]): Promise<SearchView> {
    const path = `${this.pathBase}/me/search-view`;
    return this.httpService.post(path, { name, items }).then((data) => new ModelMapper(SearchView).map(data));
  }

  async deleteSearchView(_id: string): Promise<boolean> {
    const path = `${this.pathBase}/me/search-view/${_id}`;
    return this.httpService.delete(path);
  }

  get(id: string): Promise<User>;
  get(id: string, type: new () => EmbeddedUser): Promise<EmbeddedUser>;
  get(id: string, fields: string): Promise<Partial<User>>;
  get(id: string, data?: string | (new () => EmbeddedUser)): Promise<User | EmbeddedUser | Partial<User>> {
    const path = `${this.pathBase}/${id}`;
    if (typeof data === 'string') {
      return this.httpService.get(path, this.getFilterQuery(undefined, data)).then((data) => this.mapper.map(data));
    }
    if (data instanceof EmbeddedUser) {
      const mapper = new ModelMapper(EmbeddedUser);
      return this.httpService
        .get(path, this.getFilterQuery(undefined, EmbeddedUser.fields))
        .then((data) => mapper.map(data));
    }
    return this.httpService
      .get(path, this.getFilterQuery(undefined, User.fields))
      .then((data) => this.mapper.map(data));
  }

  async inviteToCustomerAccount(data: InviteUserToCustomerAccount): Promise<User> {
    const path = `${this.pathBase}/invite-to-customer-account`;
    return this.httpService.post(path, data).then((data) => this.mapper.map(data));
  }

  async removeFromCustomerAccount(id: string, customerAccountId: string): Promise<boolean> {
    const path = `${this.pathBase}/${id}/remove-from-customer-account`;
    return this.httpService.patch(path, { customerAccountId });
  }

  async addToOrganization(id: string, organizationId: string): Promise<boolean> {
    const path = `${this.pathBase}/${id}/add-to-organization`;
    return this.httpService.patch(path, { organizationId });
  }

  async inviteToOrganization(data: InviteUserToOrganization): Promise<User> {
    const path = `${this.pathBase}/invite-to-organization`;
    return this.httpService.post(path, data).then((data) => this.mapper.map(data));
  }

  async removeFromOrganization(id: string, organizationId: string): Promise<boolean> {
    const path = `${this.pathBase}/${id}/remove-from-organization`;
    return this.httpService.patch(path, { organizationId });
  }

  async resendInvite(id: string): Promise<boolean> {
    const path = `${this.pathBase}/${id}/resend-invite`;
    return this.httpService.post(path);
  }

  async update(id: string, data: any): Promise<boolean> {
    const path = `${this.pathBase}/${id}`;
    return this.httpService.patch(path, await this.processData(data));
  }

  async setOrganizationAdministrator(id: string, isAdministrator: boolean): Promise<boolean> {
    const path = `${this.pathBase}/${id}/set-organization-administrator`;
    return this.httpService.patch(path, { isAdministrator });
  }

  async setOrganizationModules(id: string, organizationId: string, modules: Module[]): Promise<boolean> {
    const path = `${this.pathBase}/${id}/set-organization-modules`;
    return this.httpService.patch(path, { modules, organizationId });
  }

  async setOrganizationRestriction(
    id: string,
    organizationId: string,
    organizationalUnitId: string | null,
  ): Promise<boolean> {
    const path = `${this.pathBase}/${id}/set-organization-restriction`;
    return this.httpService.patch(path, { organizationalUnitId, organizationId });
  }

  async setOrganizationProfile(id: string, organizationId: string, profile: UserProfile): Promise<boolean> {
    const path = `${this.pathBase}/${id}/set-organization-profile`;
    return this.httpService.patch(path, { profile, organizationId });
  }

  datatable(query: IDatatableOptions, filter?: IUserFilter): Observable<any> {
    const path = `${this.pathBase}/datatable`;
    return from(this.httpService.post(path, { query, filter: this.getFilterQuery(filter) }));
  }

  private async processData(data: any): Promise<any> {
    if (has(data, 'organizations')) {
      set(
        data,
        'organizations',
        map(get(data, 'organizations'), (d) => ({
          organizationId: d.organization._id,
          isOwner: d.isOwner,
          isAdministrator: d.isAdministrator,
        })),
      );
    }
    return this.mapper.serialize(data);
  }

  private getFilterQuery(filter?: IUserFilter, fields?: string | string[], sorts?: string): any {
    const query: any = {};
    if (filter?.search) query.search = filter.search;
    if (filter?.organizationId) query.organizationId = filter.organizationId;
    if (filter?.customerAccountId) query.customerAccountId = filter.customerAccountId;
    if (fields) query.fields = Array.isArray(fields) ? join(fields, ' ') : fields;
    if (sorts) query.sorts = Array.isArray(sorts) ? join(sorts, ' ') : sorts;
    return query;
  }
}
