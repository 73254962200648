/** @format */

import { PropertyMap } from 'model-mapper';
import { SearchableClass, Searchable } from '../_decorators/searchable-class.decorator';
import { embeddedMap } from '../_helpers/class.helper';

@Searchable({ sortFields: 'name' })
export class EmbeddedCustomerAccount extends SearchableClass {
  @PropertyMap()
  public _id: string;

  @PropertyMap()
  public name: string;

  @PropertyMap()
  public slug: string;

  @PropertyMap({ map: embeddedMap })
  public ownerId: string;
}
