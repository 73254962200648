/** @format */

import { inject, Injectable } from '@angular/core';
import { get, map, merge } from 'lodash-es';
import { SubSink } from 'subsink';
import { Organization } from '../_classes/organization.class';
import { SessionData } from '../app.session.data';
import { CrudServiceBuild } from './crud.service';
import { GraphqlService } from './graphql.service';
import { SessionService } from './session.service';

export interface IOrganizationFilter {
  search?: string;
  customerAccountId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends CrudServiceBuild<Organization, IOrganizationFilter>(
  Organization,
  '/organization',
) {
  private sessionService = inject(SessionService);

  constructor() {
    super();
    this.subscribeCache();
  }

  async syncSerenite(id: string): Promise<boolean> {
    const path = `${this.path}/${id}/sync-serenite`;
    return this.httpService.patch(path);
  }

  async syncSereniteEquipments(id: string): Promise<boolean> {
    const path = `${this.path}/${id}/sync-serenite-equipments`;
    return this.httpService.patch(path);
  }

  private organizationSubSink = new SubSink();
  private subscribeCache() {
    SessionData.$organizations.subscribe((organizations: Organization[]) => {
      this.organizationSubSink.unsubscribe();
      this.organizationSubSink.add(
        ...map(organizations, (organization) =>
          GraphqlService.onCachedUpdated(organization._id).subscribe(async (data) => {
            merge(organization, await this.get(organization._id));
            if (SessionData.$organization.value?._id === organization._id) {
              this.sessionService.setOrganization(organization);
            }
          }),
        ),
      );
    });
  }

  override async processData(data: any, action: 'create' | 'update'): Promise<any> {
    const s3Path = get(data, 's3Path');
    await this.httpService.processFile(get(data, 'logo'), s3Path, 'logo');
    return super.processData(data, action);
  }
}
