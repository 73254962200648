/** @format */

import { get, split } from 'lodash-es';
import { ModelMapper, PropertyMap } from 'model-mapper';

export class FieldUpdateOrigin {
  @PropertyMap()
  kind: 'lambda' | 'user' | 'nd';

  @PropertyMap({ map: (source: FieldUpdateOrigin, value: any): string => get(split(value, '-'), 1) })
  functionName: string;

  @PropertyMap({
    source: 'info',
    map: (source: FieldUpdateOrigin, value: any): string => {
      if (source.kind === 'user') return value?.email;
      if (source.kind === 'lambda') {
        switch (value) {
          case 'sqssereniteconnector':
            return $localize`sérénité 24h24`;
          default:
            return $localize`sésiO AI`;
        }
      }
      return value || $localize`non défini`;
    },
  })
  value?: any;

  @PropertyMap()
  info?: any;
}

export function HumanizeFieldUpdateOrigin(data: FieldUpdateOrigin | any): string {
  if (!data) return $localize`non défini`;
  const fieldUpdateOrigin = data instanceof FieldUpdateOrigin ? data : new ModelMapper(FieldUpdateOrigin).map(data);
  return fieldUpdateOrigin.value;
}

export function OpenFailureOrigin(data: any): string {
  if (!data) return $localize`non défini`;
  if (data.kind === 'user') {
    if (!data.info?.email) return $localize`non défini`;
    return $localize`Déclaration de panne manuelle par utilisateur sésiO (${data.info.email})`;
  }
  if (data.kind === 'lambda') {
    switch (data.info) {
      case 'sqssereniteconnector':
        return $localize`Déclaration de panne par Sérénité 24h24`;
      default:
        return $localize`Détection de la panne par sésiO AI`;
    }
  }
  return data.info || $localize`non défini`;
}
export function OpenFailureOriginTransformer(source: any, value: any, target: any, property: string) {
  return OpenFailureOrigin(source.openOrigin);
}

export function CloseFailureOrigin(data: any): string {
  if (!data) return '-';
  if (data.kind === 'user') {
    if (!data.info?.email) return $localize`non défini`;
    return $localize`Fermeture manuelle par utilisateur sésiO (${data.info.email})`;
  }
  if (data.kind === 'lambda') {
    switch (data.info) {
      case 'sqssereniteconnector':
        return $localize`Intervention Technicien via Sérénité 24h24`;
      default:
        return $localize`Détection du fonctionnement par sésiO AI`;
    }
  }
  return data.info || $localize`non défini`;
}
export function CloseFailureOriginTransformer(source: any, value: any, target: any, property: string) {
  return CloseFailureOrigin(source.openOrigin);
}
