/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppFlexLayoutModule } from '../../../_directives/flex-layout/flex-layout.module';
import { ExtentedPipesModule } from '../../../_pipes/extented-pipes/extended-pipes.module';
import { ChartModule } from '../../chart/chart.module';
import { ConfirmDialogModule } from '../../confirm-dialog/confirm-dialog.module';
import { DatagridModule } from '../../datagrid/datagrid.module';
import { AppLoaderModule } from '../../loader/loader.module';
import { FailureDatagridDialogComponent } from './failure-datagrid-dialog/failure-datagrid-dialog.component';
import { FailureDatagridComponent } from './failure-datagrid.component';

@NgModule({
  declarations: [FailureDatagridComponent, FailureDatagridDialogComponent],
  imports: [
    CommonModule,
    AppFlexLayoutModule,
    DatagridModule,
    MatIconModule,
    MatTooltipModule,
    ConfirmDialogModule,
    MatDialogModule,
    MatButtonModule,
    ExtentedPipesModule,
    ChartModule,
    AppLoaderModule,
    MatTableModule,
  ],
  exports: [FailureDatagridComponent],
})
export class FailureDatagridModule {}
