/** @format */

import { Type, inject } from '@angular/core';
import { each, get, head, isNil, join, keys, map, merge, omit, set, unset } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import { Observable, from } from 'rxjs';
import { SearchItem } from '../_classes/search.class';
import { IDatatableOptions, IDatatableRecords } from '../_components/datagrid/datatable.class';
import { SearchableClass } from '../_decorators/searchable-class.decorator';
import { HttpService } from './http.service';

export type FilterType = {
  search?: string | SearchItem[];
  organizationIds?: string[];
};

export type PartialType<TClass> = Partial<TClass> & { _id: string };

export interface ICrudService<TClass extends { _id: string }, ReqFilter extends FilterType = FilterType> {
  path: string;
  mapper: ModelMapper<TClass>;
  requiredFields: string[];
  notUpdatableFields: string[];
  httpService: HttpService;

  list<T>(filter?: ReqFilter): Promise<TClass[]>;
  list<T>(filter: ReqFilter, type: new () => T): Promise<T[]>;
  list(filter: ReqFilter, fields: string, sorts: string): Promise<TClass[]>;
  list(filter?: ReqFilter, data?: (new () => any) | ModelMapper<any> | string, sorts?: string): Promise<any[]>;

  search<TClass>(limit: number, skip: number, filter: ReqFilter): Promise<{ data: TClass[]; matchCount: number }>;
  search<T>(
    limit: number,
    skip: number,
    filter: ReqFilter,
    type: new () => T,
  ): Promise<{ data: T[]; matchCount: number }>;
  search(
    limit: number,
    skip: number,
    filter: ReqFilter,
    fields: string,
    sorts: string,
  ): Promise<{ data: any[]; matchCount: number }>;
  search(
    limit: number,
    skip: number,
    filter: ReqFilter,
    data?: (new () => any) | string,
    sorts?: string,
  ): Promise<{ data: any[]; matchCount: number }>;

  findOne<T = TClass>(filter: ReqFilter, model?: new () => T): Promise<T | undefined>;

  count(filter?: ReqFilter): Promise<number>;

  unique(field: string, value: string, id?: string): Promise<boolean>;

  nextValue(field: string, prefix: string, id?: string): Promise<boolean>;

  get(id: string): Promise<TClass>;
  get<T extends SearchableClass>(id: string, type: new () => T): Promise<T>;
  get(id: string, fields: string): Promise<PartialType<TClass>>;
  get<T extends SearchableClass>(id: string, data?: string | (new () => T)): Promise<PartialType<TClass> | T>;

  create(data: any): Promise<TClass>;
  update(id: string, data: any): Promise<boolean>;

  upsert(data: any): Promise<TClass | boolean>;

  isRemovable(id: string): Promise<{ removable: boolean; reason?: string }>;

  delete(id: string): Promise<boolean>;

  archive(id: string): Promise<boolean>;

  datatable(query: IDatatableOptions, filter?: ReqFilter): Observable<IDatatableRecords<any>>;

  buildType(data: any): TClass;

  validateData(data: any, action: 'create' | 'update'): Promise<any>;

  processData(data: any, action: 'create' | 'update'): Promise<any>;

  getFilterQuery(filter?: ReqFilter, fields?: string | string[], sorts?: string): any;

  setFilterQueryEntry(filter: ReqFilter, query: any, key: string, value: any): void;
}

export function CrudServiceBuild<TClass extends { _id: string }, ReqFilter extends FilterType = FilterType>(
  target: Type<TClass>,
  path: string,
): Type<ICrudService<TClass, ReqFilter>> {
  const mapper = new ModelMapper(target);
  const tree = mapper.getPropertyMappingTree();
  const requiredFields: string[] = [];
  const notUpdatableFields: string[] = [];
  each(keys(tree), (key) => {
    if (tree[key].metadata?.required === true) requiredFields.push(key);
    if (tree[key].metadata?.updatable === false) notUpdatableFields.push(key);
  });

  class CrudServiceClass {
    path: string = path;
    mapper: ModelMapper<TClass> = mapper;
    requiredFields: string[] = requiredFields;
    notUpdatableFields: string[] = notUpdatableFields;
    httpService: HttpService = inject(HttpService);

    async list<T>(filter?: ReqFilter): Promise<TClass[]>;
    async list<T>(filter: ReqFilter, type: new () => T): Promise<T[]>;
    async list(filter: ReqFilter, fields: string, sorts: string): Promise<TClass[]>;
    async list(filter?: ReqFilter, data?: (new () => any) | ModelMapper<any> | string, sorts?: string): Promise<any[]> {
      const path = `${this.path}/list`;
      const query: any = {};
      let builder: (data: any) => any = (d) => this.buildType(d);
      if (typeof data === 'string') {
        merge(query, this.getFilterQuery(filter, data, sorts));
      } else if (typeof data === 'function') {
        const mapper = new ModelMapper(data);
        merge(query, this.getFilterQuery(filter, (mapper.type as any).fields, (mapper.type as any).sortFields));
        builder = (d) => mapper.map(d);
      } else {
        merge(
          query,
          this.getFilterQuery(filter, (this.mapper.type as any).fields, (this.mapper.type as any).sortFields),
        );
      }
      return this.httpService.post(path, query).then((data) => map(data, builder));
    }

    async search<TClass>(
      limit: number,
      skip: number,
      filter: ReqFilter,
    ): Promise<{ data: TClass[]; matchCount: number }>;
    async search<T>(
      limit: number,
      skip: number,
      filter: ReqFilter,
      type: new () => T,
    ): Promise<{ data: T[]; matchCount: number }>;
    async search(
      limit: number,
      skip: number,
      filter: ReqFilter,
      fields: string,
      sorts: string,
    ): Promise<{ data: TClass[]; matchCount: number }>;
    async search(
      limit: number,
      skip: number,
      filter: ReqFilter,
      data?: (new () => any) | string,
      sorts?: string,
    ): Promise<{ data: any[]; matchCount: number }> {
      const path = `${this.path}/search`;
      const query: any = { skip, limit };
      let builder: (data: any) => any = (d) => this.buildType(d);
      if (typeof data === 'string') {
        merge(query, this.getFilterQuery(filter, data, sorts));
      } else if (typeof data === 'function') {
        const mapper = new ModelMapper(data);
        merge(query, this.getFilterQuery(filter, (mapper.type as any).fields, (mapper.type as any).sortFields));
        builder = (d) => mapper.map(d);
      } else {
        merge(
          query,
          this.getFilterQuery(filter, (this.mapper.type as any).fields, (this.mapper.type as any).sortFields),
        );
      }
      return this.httpService.post(path, query).then((res) => ({
        matchCount: res.matchCount,
        data: map(res.data, builder),
      }));
    }

    async findOne<T = TClass>(filter: ReqFilter, model?: new () => T): Promise<T | undefined> {
      const search: { data: T[]; matchCount: number } = await (model
        ? this.search(1, 0, filter, model)
        : this.search(1, 0, filter));
      return head(get(search, 'data'));
    }

    async count(filter?: ReqFilter): Promise<number> {
      const path = `${this.path}/count`;
      return this.httpService.get(path, this.getFilterQuery(filter, undefined, undefined));
    }

    async unique(field: string, value: string, id?: string): Promise<boolean> {
      const path = `${this.path}/unique`;
      const query: any = { field, value };
      if (id) query.id = id;
      return this.httpService.get(path, query);
    }

    async nextValue(field: string, prefix: string, id?: string): Promise<boolean> {
      const path = `${this.path}/next-value`;
      const query: any = { field, prefix };
      if (id) query.id = id;
      return this.httpService.get(path, query);
    }

    get(id: string): Promise<TClass>;
    get<T extends SearchableClass>(id: string, type: new () => T): Promise<T>;
    get(id: string, fields: string): Promise<PartialType<TClass>>;
    get<T extends SearchableClass>(id: string, data?: string | (new () => T)): Promise<PartialType<TClass> | T> {
      const path = `${this.path}/${id}/get`;
      const query = {};
      let builder: (data: any) => any = (d) => this.buildType(d);

      if (typeof data === 'string') {
        merge(query, this.getFilterQuery(undefined, data, undefined));
      } else if (typeof data === 'function') {
        const mapper = new ModelMapper(data);
        merge(query, this.getFilterQuery(undefined, (mapper.type as any).fields, undefined));
        builder = (d) => mapper.map(d);
      } else {
        merge(query, this.getFilterQuery(undefined, (this.mapper.type as any).fields, undefined));
      }
      return this.httpService.post(path, query).then(builder);
    }

    async create(data: any): Promise<TClass> {
      const path = `${this.path}`;
      data = await this.validateData(data, 'create');
      data = await this.processData(data, 'create');
      return this.httpService.post(path, data).then((data) => this.buildType(data));
    }

    async update(id: string, data: any): Promise<boolean> {
      const path = `${this.path}/${id}`;
      data = await this.validateData(data, 'update');
      data = await this.processData(data, 'update');
      return this.httpService.patch(path, data);
    }

    async upsert(data: any): Promise<TClass | boolean> {
      if (data._id) return await this.update(data._id, omit(data, '_id'));
      return await this.create(data);
    }

    async isRemovable(id: string): Promise<{ removable: boolean; reason?: string }> {
      const path = `${this.path}/${id}/is-removable`;
      return this.httpService.get(path);
    }

    async delete(id: string): Promise<boolean> {
      const path = `${this.path}/${id}`;
      return this.httpService.delete(path);
    }

    async archive(id: string): Promise<boolean> {
      const path = `${this.path}/${id}/archive`;
      return this.httpService.patch(path);
    }

    datatable(query: IDatatableOptions, filter?: ReqFilter): Observable<IDatatableRecords<PartialType<TClass>>> {
      const path = `${this.path}/datatable`;
      return from(
        this.httpService
          .post(path, { query, filter: this.getFilterQuery(filter, undefined, undefined) })
          .then((res: IDatatableRecords<PartialType<TClass>>) => {
            res.data = map(res.data, (d) => this.buildType(d));
            return res;
          }),
      );
    }

    buildType(data: any): TClass {
      return this.mapper.map(data);
    }

    async validateData(data: any, action: 'create' | 'update'): Promise<any> {
      for (let field of this.requiredFields) {
        if (isNil(get(data, field))) throw new Error(`missing required ${field} value !`);
      }
      return data;
    }

    async processData(data: any, action: 'create' | 'update'): Promise<any> {
      if (action === 'update') for (let field of this.notUpdatableFields) unset(data, field);
      return this.mapper.serialize(data);
    }

    getFilterQuery(filter?: ReqFilter, fields?: string | string[], sorts?: string): any {
      const query: any = {};
      if (typeof filter?.search === 'string' && filter.search.length) query.search = filter.search;
      else if (Array.isArray(filter?.search) && filter?.search.length) query.search = filter.search;
      if (filter?.organizationIds) query.organizationIds = filter.organizationIds;
      if (fields) query.fields = Array.isArray(fields) ? join(fields, ' ') : fields;
      if (sorts) query.sorts = sorts;
      each(keys(filter), (key) => {
        const value = get(filter, key);
        if (value === undefined) return;
        if (Array.isArray(value) && value.length === 0) return;
        this.setFilterQueryEntry(filter!, query, key, value);
      });
      return query;
    }

    setFilterQueryEntry(filter: ReqFilter, query: any, key: string, value: any): void {
      set(query, key, value);
    }
  }

  return CrudServiceClass as any;
}
