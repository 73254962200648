/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { includes } from 'lodash-es';

@Pipe({ name: 'includes' })
export class IncludesPipe implements PipeTransform {
  transform(data: any[], search: any): boolean {
    return includes(data, search);
  }
}
