<div *ngIf="notifications; else loadingTmpl" id="container" fxLayout="column" fxLayoutGap="8px">

  <mat-toolbar mat-dialog-header>
    <button mat-dialog-back-btn mat-button mat-dialog-close>
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-icon color="medium">chevron_left</mat-icon>
        <span color="medium" light uppercase i18n>Retour</span>
      </div>
    </button>

    <div mat-dialog-header-title fxLayoutGap="16px">
      <mat-icon color="primary">notifications</mat-icon>
      <span color="primary" i18n>Notifications</span>
    </div>


    <button mat-dialog-close-btn mat-button mat-dialog-close color="medium">
      <div fxLayout="column" fxLayoutAlign="center center">
        <span class="sup" color="medium" light i18n>Fermer</span>
        <mat-icon color="medium" fontSet="material-icons-outlined">cancel_outline</mat-icon>
      </div>
    </button>

  </mat-toolbar>

  <ng-container *ngFor="let notification of notifications">
    <ng-container [ngSwitch]="notification.kind">
      <ng-container *ngSwitchCase="NotificationKind.EQUIPMENT_STATUS">
        <ng-container *ngTemplateOutlet="equipmentStatusTmpl; context: { notification: notification }"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <button mat-button [disabled]="loading || !nextToken" (click)="loadMore()">Charger plus</button>

</div>

<ng-template #equipmentStatusTmpl let-notification="notification">
  <div class="notification notification-{{notification.kind}}" [class.read]="notification.read" fxLayout="column"
    (click)="click(notification)" matRipple>
    <div matListItemTitle class="title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px"
      [style.color]="EquipmentStatusColor[notification.context.status]">
      <mat-icon [color]="EquipmentStatusColorName[notification.context.status]">
        {{EquipmentStatusIcon[notification.context.status]}}
      </mat-icon>
      <span>{{EquipmentStatusName[notification.context.status]}}</span>
      <span class="time">{{notification.time | date:'medium'}}</span>
    </div>
    <div matListItemLine class="info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <div fxLayout="row" fxLayoutGap="4px">
        <span>{{notification.context.kindName}}</span>
        <span bold color="primary">{{notification.context.reference}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="4px">
        <span i18n>Mis à jour le : </span>
        <span bold color="primary">{{notification.context.updatedAt | date:'medium'}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="4px">
        <span i18n>Mis à jour par : </span>
        <span bold color="primary" [ngSwitch]="notification.context.origin?.kind">
          <span *ngSwitchCase="'user'">
            {{notification.context.origin.info.email}}
          </span>
          <span *ngSwitchDefault>
            {{notification.context.origin?.info}}
          </span>
        </span>
      </div>
      <div fxLayout="row" fxLayoutGap="4px">
        <span i18n>Statut précédent : </span>
        <span bold [attr.color]="EquipmentStatusColorName[notification.context.previousStatus]">
          {{EquipmentStatusName[notification.context.previousStatus]}}
        </span>
      </div>
      <div fxLayout="row" fxLayoutGap="4px">
        <span i18n>Dernière position : </span>
        <span bold>
          {{notification.context.lastPositionName}}
        </span>
      </div>
      <div *ngFor="let doorState of notification.context.lastDoorStates | values" fxLayout="row" fxLayoutGap="4px">
        <span i18n> {{EquipmentDoorNameName[doorState.doorName]}} : </span>
        <span bold>
          {{EquipmentDoorStateName[doorState.state]}}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingTmpl>
  <app-loader id="loader" @fadeOut absolute [diameter]="100"></app-loader>
</ng-template>
