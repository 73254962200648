/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppTranslateModule } from '../../app-translate.module';
import { ElevatorFloorFromDistancePipe } from './elevator-floor-from-distance.pipe';
import { ElevatorFloorPipe } from './elevator-floor.pipe';
import { ElevatorLastMouvementPipe } from './elevator-last-mouvement.pipe';
import { EquipmentDisponibilityRatePipe } from './equipment-disponibility-rate.pipe';
import { EquipmentFailureCountPipe } from './equipment-failure-count.pipe';

@NgModule({
  declarations: [
    ElevatorFloorPipe,
    ElevatorLastMouvementPipe,
    ElevatorFloorFromDistancePipe,
    EquipmentDisponibilityRatePipe,
    EquipmentFailureCountPipe,
  ],
  imports: [CommonModule, AppTranslateModule],
  exports: [
    ElevatorFloorPipe,
    ElevatorLastMouvementPipe,
    ElevatorFloorFromDistancePipe,
    EquipmentDisponibilityRatePipe,
    EquipmentFailureCountPipe,
  ],
})
export class EquipmentModule {}
