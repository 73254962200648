/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import {
  EquipmentStatus,
  EquipmentStatusIcon,
  EquipmentStatusColorName,
  EquipmentStatusColor,
} from '../../_constants/equipment-status';

@Pipe({
  name: 'equipmentStatus',
})
export class EquipmentStatusPipe implements PipeTransform {
  transform(status: EquipmentStatus, kind?: 'color' | 'color-name' | 'icon'): string | undefined {
    switch (kind) {
      case 'color':
        return EquipmentStatusColor[status];
      case 'icon':
        return EquipmentStatusIcon[status];
      case 'color-name':
        return EquipmentStatusColorName[status];
      default:
        return;
    }
  }
}
