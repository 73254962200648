/** @format */

import { PropertyMap } from 'model-mapper';
import { IException } from './exception.interface';

export class BadRequestExceptionEntry {
  @PropertyMap()
  public property: string;

  @PropertyMap()
  public target: any;

  @PropertyMap()
  public constraints: { [contraint: string]: string };

  @PropertyMap({ type: [BadRequestExceptionEntry] })
  public children: BadRequestExceptionEntry[];

  public toString(): string {
    let toString = `${this.property}`;
    if (this.children?.length) this.children.forEach((c) => (toString += `.${c.toString()}`));
    else {
      const info = Object.values(this.constraints || {})
        .filter((v) => v?.length)
        .join(', ');
      if (info.length) toString += `: ${info}`;
    }
    return toString;
  }
}

export class BadRequestException implements IException {
  @PropertyMap()
  public message: string;

  @PropertyMap({ type: [BadRequestExceptionEntry], default: [] })
  public error: BadRequestExceptionEntry[];

  public toHtml(): string {
    const message = $localize`Une erreur est survenue, si cela persiste veuillez contacter un administateur.`;
    const errors = Array.isArray(this.error) ? this.error : [];
    const error = errors.map((error) => error?.toString()).join('</br>');
    return `${message}</br><i>${error}</i>`;
  }
}
