import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  TranslateModule,
  TranslateParser,
  TranslateDefaultParser,
  TranslateLoader,
  TranslateCompiler,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { merge } from 'lodash-es';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class CustomTranslateParser extends TranslateDefaultParser {
  override interpolate(expr: string | Function, params?: any): string {
    params = merge({ count: 0 }, params || {}); // default count plurial to 0
    return super.interpolate(expr, params || {});
  }
}

export const AppTranslateModuleForRoot = TranslateModule.forRoot({
  defaultLanguage: 'fr',
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient],
  },
  parser: {
    provide: TranslateParser,
    useClass: CustomTranslateParser,
  },
  compiler: {
    provide: TranslateCompiler,
    useClass: TranslateMessageFormatCompiler,
  },
});

export const AppTranslateModule = TranslateModule.forChild({
  defaultLanguage: 'fr',
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient],
  },
  parser: {
    provide: TranslateParser,
    useClass: CustomTranslateParser,
  },
  compiler: {
    provide: TranslateCompiler,
    useClass: TranslateMessageFormatCompiler,
  },
});
