/** @format */

import { BehaviorSubject, Subject } from 'rxjs';
import { Organization } from './_classes/organization.class';
import { User } from './_classes/user.class';

export interface ISessionData {
  $locale: BehaviorSubject<string>;
  $organizations: BehaviorSubject<Organization[]>;
  $organization: BehaviorSubject<Organization | null | undefined>;
  $user: Subject<User | null | undefined>;
  user: User | null | undefined;
}

export const SessionData: ISessionData = {
  $locale: new BehaviorSubject<string>('fr'),
  $organizations: new BehaviorSubject<Organization[]>([]),
  $organization: new BehaviorSubject<Organization | null | undefined>(undefined),
  $user: new Subject<User | null | undefined>(),
  user: null,
};
