/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onUpsertEntityProperty = /* GraphQL */ `subscription OnUpsertEntityProperty($entityID: ID!, $propertyName: String!) {
  onUpsertEntityProperty(entityID: $entityID, propertyName: $propertyName) {
    id
    entityID
    entityModel
    time
    propertyName
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpsertEntityPropertySubscriptionVariables,
  APITypes.OnUpsertEntityPropertySubscription
>;
export const onCreateEntityProperty = /* GraphQL */ `subscription OnCreateEntityProperty(
  $filter: ModelSubscriptionEntityPropertyFilterInput
) {
  onCreateEntityProperty(filter: $filter) {
    id
    entityID
    entityModel
    time
    propertyName
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEntityPropertySubscriptionVariables,
  APITypes.OnCreateEntityPropertySubscription
>;
export const onUpdateEntityProperty = /* GraphQL */ `subscription OnUpdateEntityProperty(
  $filter: ModelSubscriptionEntityPropertyFilterInput
) {
  onUpdateEntityProperty(filter: $filter) {
    id
    entityID
    entityModel
    time
    propertyName
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEntityPropertySubscriptionVariables,
  APITypes.OnUpdateEntityPropertySubscription
>;
export const onDeleteEntityProperty = /* GraphQL */ `subscription OnDeleteEntityProperty(
  $filter: ModelSubscriptionEntityPropertyFilterInput
) {
  onDeleteEntityProperty(filter: $filter) {
    id
    entityID
    entityModel
    time
    propertyName
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEntityPropertySubscriptionVariables,
  APITypes.OnDeleteEntityPropertySubscription
>;
export const onCreateCacheChanged = /* GraphQL */ `subscription OnCreateCacheChanged(
  $filter: ModelSubscriptionCacheChangedFilterInput
) {
  onCreateCacheChanged(filter: $filter) {
    id
    name
    time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCacheChangedSubscriptionVariables,
  APITypes.OnCreateCacheChangedSubscription
>;
export const onUpdateCacheChanged = /* GraphQL */ `subscription OnUpdateCacheChanged(
  $filter: ModelSubscriptionCacheChangedFilterInput
) {
  onUpdateCacheChanged(filter: $filter) {
    id
    name
    time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCacheChangedSubscriptionVariables,
  APITypes.OnUpdateCacheChangedSubscription
>;
export const onDeleteCacheChanged = /* GraphQL */ `subscription OnDeleteCacheChanged(
  $filter: ModelSubscriptionCacheChangedFilterInput
) {
  onDeleteCacheChanged(filter: $filter) {
    id
    name
    time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCacheChangedSubscriptionVariables,
  APITypes.OnDeleteCacheChangedSubscription
>;
export const onCreateNotification = /* GraphQL */ `subscription OnCreateNotification(
  $filter: ModelSubscriptionNotificationFilterInput
  $owner: String
) {
  onCreateNotification(filter: $filter, owner: $owner) {
    id
    owner
    time
    kind
    read
    context
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNotificationSubscriptionVariables,
  APITypes.OnCreateNotificationSubscription
>;
export const onUpdateNotification = /* GraphQL */ `subscription OnUpdateNotification(
  $filter: ModelSubscriptionNotificationFilterInput
  $owner: String
) {
  onUpdateNotification(filter: $filter, owner: $owner) {
    id
    owner
    time
    kind
    read
    context
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNotificationSubscriptionVariables,
  APITypes.OnUpdateNotificationSubscription
>;
export const onDeleteNotification = /* GraphQL */ `subscription OnDeleteNotification(
  $filter: ModelSubscriptionNotificationFilterInput
  $owner: String
) {
  onDeleteNotification(filter: $filter, owner: $owner) {
    id
    owner
    time
    kind
    read
    context
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationSubscriptionVariables,
  APITypes.OnDeleteNotificationSubscription
>;
