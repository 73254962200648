/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentDisponibilityInfoComponent } from './equipment-disponibility-info.component';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { DisponibilityRateLabelModule } from '../disponibility-rate-label/disponibility-rate-label.module';

@NgModule({
  declarations: [EquipmentDisponibilityInfoComponent],
  imports: [CommonModule, AppFlexLayoutModule, DisponibilityRateLabelModule],
  exports: [EquipmentDisponibilityInfoComponent],
})
export class EquipmentDisponibilityInfoModule {}
