/** @format */

import { PropertyMap } from 'model-mapper';
import { FieldUpdateOrigin } from './field-update-origin.class';

export class FieldUpdate<T> {
  @PropertyMap()
  value: T | null;

  @PropertyMap()
  previousValue: T | null;

  @PropertyMap({ type: Date })
  updatedAt: Date;

  @PropertyMap({ type: Date })
  previousUpdatedAt: Date;

  @PropertyMap()
  duration: number;

  @PropertyMap({ type: FieldUpdateOrigin })
  updateOrigin: FieldUpdateOrigin;
}
