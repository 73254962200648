/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanzoomComponent } from './panzoom.component';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { NgxObserversModule } from 'ngx-observers';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [PanzoomComponent],
  imports: [CommonModule, AppFlexLayoutModule, NgxObserversModule, MatButtonModule, MatIconModule],
  exports: [PanzoomComponent],
})
export class PanzoomModule {}
