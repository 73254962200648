/** @format */

import { PropertyMap } from 'model-mapper';
import { ConnectivityStatus } from '../../_constants/connectivity-status';
import { SesioNodeKind } from '../../_constants/sesio-node/sesio-node-kind';
import { SesioNodeStatus } from '../../_constants/sesio-node/sesio-node-status';
import { Archived } from '../archived.class';

class SesioNodeConnectivity {
  @PropertyMap()
  status: ConnectivityStatus;

  @PropertyMap({ type: Date })
  statusDate: Date;
}
export class EmbeddedSesioNode {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  reference: string;

  @PropertyMap()
  label: string;

  @PropertyMap()
  customerAccountIdentifier: string;

  @PropertyMap()
  status: SesioNodeStatus;

  @PropertyMap({ type: SesioNodeConnectivity })
  connectivity: SesioNodeConnectivity;

  @PropertyMap()
  kind: SesioNodeKind;

  @PropertyMap({ type: Archived })
  __archived: Archived;

  get telemetryTopic(): string | undefined {
    if (!this.reference) return undefined;
    return `devices/${this.reference}/telemetry`;
  }
}
