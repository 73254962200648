/** @format */

import { PropertyMap } from 'model-mapper';
import { SearchKey } from '../_constants/search';

export class SearchOption {
  @PropertyMap()
  key: SearchKey;

  @PropertyMap()
  value: any;
}

export class SearchDisplayOption extends SearchOption {
  @PropertyMap()
  displayKey: string;

  @PropertyMap()
  displayValue: string;
}

export class SearchItem {
  @PropertyMap()
  key: SearchKey;

  @PropertyMap()
  values: any[];
}

export class SearchDisplayItem extends SearchItem {
  @PropertyMap()
  displayKey: string;

  @PropertyMap()
  displayValues: string[];
}
