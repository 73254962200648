/** @format */

import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IntersectionObserverEvent } from 'ngx-intersection-observer/lib/intersection-observer-event.model';
import { v4 as uuidv4 } from 'uuid';

import * as Highcharts from 'highcharts';
import * as Gantt from 'highcharts/highcharts-gantt';
import * as Highstock from 'highcharts/highstock';

import More from 'highcharts/highcharts-more';
import Accessibility from 'highcharts/modules/accessibility';
import Boost from 'highcharts/modules/boost';
import Bullet from 'highcharts/modules/bullet';
import Drilldown from 'highcharts/modules/drilldown';
import ExportData from 'highcharts/modules/export-data';
import Exporting from 'highcharts/modules/exporting';
import noData from 'highcharts/modules/no-data-to-display';
import ParallelCoordinates from 'highcharts/modules/parallel-coordinates';
import SolidGauge from 'highcharts/modules/solid-gauge';
import Xrange from 'highcharts/modules/xrange';
Accessibility(Highcharts);
Accessibility(Highstock);
Accessibility(Gantt);
Boost(Highcharts);
Boost(Highstock);
Boost(Gantt);
noData(Highcharts);
noData(Highstock);
noData(Gantt);
More(Highcharts);
More(Highstock);
More(Gantt);
Exporting(Highcharts);
Exporting(Highstock);
Exporting(Gantt);
ExportData(Highcharts);
ExportData(Highstock);
ExportData(Gantt);
SolidGauge(Highcharts);
Xrange(Highcharts);
Xrange(Highstock);
Xrange(Gantt);
ParallelCoordinates(Highcharts);
ParallelCoordinates(Highstock);
ParallelCoordinates(Gantt);
Drilldown(Highcharts);
Drilldown(Highstock);
Drilldown(Gantt);
Bullet(Highcharts);
Bullet(Highstock);
Bullet(Gantt);

import { each, last, merge } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { take } from 'rxjs/operators';
import { Extremes } from '../../../_classes/extremes.class';
import { highchartsOptions } from '../../../_constants/highcharts';
import { fade } from '../../../_constants/animations';

export type ChartExtremes = Extremes | { start: Date; end: Date } | { start: number; end: number };

export type ChartKind = 'highcharts' | 'highstock' | 'gantt';

const DEFAULT_OPTIONS = {
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadPDF', 'downloadXLS'],
      },
    },
  },
};

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  animations: [fade],
})
export class ChartComponent implements OnInit {
  public id = `chart-${uuidv4()}`;

  @Output()
  public ready: EventEmitter<ChartComponent> = new EventEmitter();

  @Input('kind')
  public set setKind(kind: ChartKind) {
    this.kind = kind;
  }
  private kind: ChartKind = 'highcharts';

  @Input()
  options: Highcharts.Options | Highstock.Options | undefined;

  // @Input('loading')
  public loading = true;

  public chart: Highcharts.Chart | Highstock.Chart | undefined;

  private changeDetectorRef = inject(ChangeDetectorRef);

  constructor(private translate: TranslateService) {}

  async ngOnInit(): Promise<void> {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
    Highstock.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
    Gantt.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
  }

  async loadChart($event: IntersectionObserverEvent): Promise<void> {
    if (!$event.intersect || this.chart) return;
    const options = merge({}, DEFAULT_OPTIONS, this.options);
    switch (this.kind) {
      case 'highstock':
        this.chart = Highstock.stockChart(this.id, options);
        break;
      case 'gantt':
        this.chart = Gantt.ganttChart(this.id, options);
        break;
      default:
        this.chart = Highcharts.chart(this.id, options);
        break;
    }
    this.ready.emit(this);
  }

  public redraw(animation = true) {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.redraw());
      return;
    }
    this.chart.redraw(animation);
  }

  public reflow() {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.reflow());
      return;
    }
    this.chart.reflow();
  }

  public setLoading(loading: boolean): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setLoading(loading));
      return;
    }
    // next tick
    setTimeout(() => {
      this.loading = loading;
      this.changeDetectorRef.detectChanges();
    });
  }

  public setTitle(title: string) {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setTitle(title));
      return;
    }
    this.chart.setTitle({ text: title });
  }

  public setSeries(series: Highcharts.SeriesOptionsType[] | Highstock.SeriesOptionsType[]): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setSeries(series));
      return;
    }
    while (this.chart.series.length) {
      this.chart.series[0].remove();
    }
    series.forEach((s) => this.chart!.addSeries(s));
    this.chart.redraw();
  }

  public setSerieData(data: any, serieIndex = 0, options?: { redraw?: boolean; updatePoints?: boolean }): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setSerieData(data, serieIndex, options));
      return;
    }
    const serie = this.chart.series[serieIndex];
    if (!serie) return;
    serie.setData(data, options?.redraw, options?.updatePoints);
  }

  public addSeriesAsDrilldown(point: Highcharts.Point, options: Highcharts.SeriesOptionsType): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.addSeriesAsDrilldown(point, options));
      return;
    }
    this.chart.addSeriesAsDrilldown(point, options);
  }

  public addPoint(
    data: any,
    serieIndex = 0,
    options?: { redraw?: boolean; shift?: boolean; animation?: boolean },
  ): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.addPoint(data, serieIndex));
      return;
    }
    this.chart.series[serieIndex]?.addPoint(data, options?.redraw, options?.shift, options?.animation);
  }

  public getPointsLength(serieIndex = 0): number | undefined {
    return this.chart?.series[serieIndex].points.length;
  }

  public getPoints(serieIndex = 0): Highcharts.Point[] | undefined {
    return this.chart?.series[serieIndex]?.points;
  }

  public getPoint(pointIndex: number, serieIndex = 0): Highcharts.Point | undefined {
    return this.chart?.series[serieIndex].points[pointIndex];
  }

  public getLastPoint(serieIndex = 0): Highcharts.Point | undefined {
    return last(this.chart?.series[serieIndex].points);
  }

  public removePoint(pointIndex: number, serieIndex = 0, options?: { redraw?: boolean }): void {
    this.chart?.series[serieIndex].removePoint(pointIndex, options?.redraw);
  }

  public popPoint(serieIndex = 0, options?: { redraw?: boolean }): void {
    if (this.chart) {
      const pointIndex = this.chart?.series[serieIndex].points.length - 1;
      this.chart?.series[serieIndex].points[pointIndex].remove(options?.redraw);
    }
  }

  public async getXAxis(index = 0): Promise<Highcharts.Axis | undefined> {
    if (!this.chart) {
      lastValueFrom(this.ready.pipe(take(1))).then(() => this.getXAxis(index));
      return;
    }
    return this.chart?.xAxis[index];
  }

  public async getYAxis(index = 0): Promise<Highcharts.Axis | undefined> {
    if (!this.chart) {
      lastValueFrom(this.ready.pipe(take(1))).then(() => this.getYAxis(index));
      return;
    }
    return this.chart?.yAxis[index];
  }

  public getExtremes(): Extremes[] {
    const extremes: Extremes[] = [];
    if (this.chart) {
      each(this.chart.xAxis, (axis) => extremes.push(new ModelMapper(Extremes).map(axis.getExtremes())));
    }
    return extremes;
  }

  public setExtremes(extremes: ChartExtremes, redraw = false): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setExtremes(extremes, redraw));
      return;
    }
    each(this.chart.xAxis, (axis) => axis.setExtremes(extremes.start?.valueOf(), extremes.end?.valueOf(), redraw));
  }

  public setXCategories(categories: string[], redraw?: boolean): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setXCategories(categories, redraw));
      return;
    }
    each(this.chart.xAxis, (axis) => axis.setCategories(categories, redraw));
  }

  public setYCategories(categories: string[], redraw?: boolean): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setYCategories(categories, redraw));
      return;
    }
    each(this.chart.yAxis, (axis) => axis.setCategories(categories, redraw));
  }
}
