<ng-scrollbar id="scroll" track="vertical" visibility="hover" [autoHeightDisabled]="false">
  <div id="notifications" fxLayout="column" fxLayoutGap="16px">
    <ng-container *ngFor="let notification of notifications; index as index">
      <ng-container *ngTemplateOutlet="notificationTmpl; context: { notification, index }"></ng-container>
    </ng-container>
  </div>
</ng-scrollbar>

<ng-template #notificationTmpl let-notification="notification" let-index="index">
  <div id="notification" @fade fxLayout="column" class="level-{{notification.level}}"
    [style.--notification-color]="notification.color">
    <div fxLayoutAlign="space-between center" fxLayoutGap="8px">
      <div fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon *ngIf="notification.icon">{{notification.icon}}</mat-icon>
        <ng-container *ngTemplateOutlet="statusTmpl; context: { status: notification.status }"></ng-container>
        <span *ngIf="notification.message" [innerHTML]="notification.message | safe:'html'"></span>
      </div>
      <button id="close-btn" mat-icon-button size="small" color="medium" (click)="removeNotification(index)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <span *ngIf="notification.error" color="danger" [innerHTML]="notification.error | safe:'html'"></span>

    <div *ngIf="notification.duration && (!notification.status || notification.status === 'done')" id="indicator"
      [style.animation-duration]="notification.duration + 'ms'" (animationend)="removeNotification(index)"></div>
  </div>
</ng-template>

<ng-template #statusTmpl let-status="status">
  <ng-container [ngSwitch]="status">
    <mat-icon *ngSwitchCase="'queued'" color="medium">schedule</mat-icon>
    <mat-progress-spinner *ngSwitchCase="'wip'" diameter="24" mode="indeterminate"
      color="primary"></mat-progress-spinner>
    <mat-icon *ngSwitchCase="'done'" color="success">task_alt</mat-icon>
    <mat-icon *ngSwitchCase="'error'" color="danger">error</mat-icon>
  </ng-container>
</ng-template>
