import { trigger, transition, style, animate, query, group } from '@angular/animations';

export const fade = trigger('fade', [
  transition(':enter', [
    style({ opacity: 0 }), // initial
    animate('0.2s', style({ opacity: 1 })) // final
  ]),
  transition(':leave', [
    style({ opacity: 1 }), // initial
    animate('0.2s', style({ opacity: 0 })) // final
  ])
]);

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    style({ opacity: 0 }), // initial
    animate('0.2s', style({ opacity: 1 })) // final
  ])
]);

export const fadeOut = trigger('fadeOut', [
  transition(':leave', [
    style({ opacity: 1 }), // initial
    animate('0.2s', style({ opacity: 0 })) // final
  ])
]);

export const fadeInDelay = trigger('fadeInDelay', [
  transition(':enter', [
    style({ opacity: 0 }), // initial
    animate('0.2s 0.2s', style({ opacity: 1 })) // final
  ])
]);

export const fadeOutDelay = trigger('fadeOutDelay', [
  transition(':leave', [
    style({ opacity: 1 }), // initial
    animate('0.2s 0.2s', style({ opacity: 0 })) // final
  ])
]);

export const slideLeftTransitions = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
      optional: true,
    }),
  ]),
];

export const slideRightTransitions = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
      optional: true,
    }),
  ]),
];

export const slideTopTransitions = [
  query(':enter, :leave', style({ position: 'absolute', height: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateY(100%)' }), animate('.3s ease-out', style({ transform: 'translateY(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateY(0%)' }), animate('.3s ease-out', style({ transform: 'translateY(-100%)' }))], {
      optional: true,
    }),
  ]),
];

export const slideBottomTransitions = [
  query(':enter, :leave', style({ position: 'absolute', height: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateY(-100%)' }), animate('.3s ease-out', style({ transform: 'translateY(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateY(0%)' }), animate('.3s ease-out', style({ transform: 'translateY(100%)' }))], {
      optional: true,
    }),
  ]),
];
