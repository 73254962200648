/** @format */

import { endsWith, filter, get, isNil, keys, map, merge } from 'lodash-es';
import { ModelMapper, PropertyMappingTree } from 'model-mapper';

export function embeddedSerialize(source: any, value: any, target: any, property: string) {
  if (value !== undefined) {
    // TODO add is array propertyField
    if (Array.isArray(value)) {
      const propertyName = endsWith(property, 's') ? `${property.slice(0, -1)}Ids` : `${property}Ids`;
      target[propertyName] = filter(map(value, '_id'), (id) => !!id);
      return undefined;
    } else if (value === null) {
      target[`${property}Id`] = null;
      return undefined;
    } else if (value._id) {
      target[`${property}Id`] = value._id;
      return undefined;
    }
  }
  return value;
}

export function embeddedMap(source: any, value: any, target: any, property: string) {
  if (value !== undefined) {
    const propertyName = getPropertyNameFromEmbeddedId(property);
    const val = get(source, propertyName);
    if (Array.isArray(val)) return filter(map(val, '_id'), (id) => !!id);
    if (val?._id) return val._id;
  }
  return value;
}

export function getPropertyNameFromEmbeddedId(property: string) {
  return endsWith(property, 's') ? `${property.slice(0, -3)}s` : `${property.slice(0, -2)}`;
}

export function flatTransformer(source: any, value: any, target: any, property: string) {
  if (value) return flatten(value);
  return value;
}

function flatten(obj: any, prefix?: string) {
  if (isNil(obj)) return obj;
  const flat: any = {};
  for (let key of Object.keys(obj)) {
    const path = prefix ? `${prefix}.${key}` : key;
    if (Array.isArray(obj[key])) flat[path] = obj[key];
    else if (!isNil(obj[key]) && typeof obj[key] === 'object') {
      merge(flat, flatten(obj[key], path));
    } else flat[path] = obj[key];
  }
  return flat;
}

export function getFields(mapper: ModelMapper<any>): string[];
export function getFields(type: new () => any): string[];
export function getFields(data: any): string[] {
  let mapper: ModelMapper<any>;
  if (data instanceof ModelMapper) mapper = data;
  else mapper = new ModelMapper(data);
  return getTreeFields(mapper.getPropertyMappingTree());
}

function getTreeFields(tree: PropertyMappingTree, prefix?: string): string[] {
  const fields: string[] = [];
  for (let key of keys(tree)) {
    const info = tree[key];
    if (info.propertyMapping) {
      const embeddedFields = getTreeFields(info.propertyMapping, prefix ? `${prefix}.${key}` : key);
      fields.push(...embeddedFields);
    } else if (info.map === embeddedMap) {
      fields.push(`${getPropertyNameFromEmbeddedId(key)}._id`);
    } else {
      const valuePath = info.source ? info.source : key;
      fields.push(prefix ? `${prefix}.${valuePath}` : `${valuePath}`);
    }
  }
  return fields;
}

export const s3PathPropertyMap = { metadata: { required: true, updatable: false } };
