/** @format */

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [HttpClientModule],
  exports: [],
})
export class AppIconModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    iconRegistry.addSvgIcon('menu-expand', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu-expand.svg`));
    iconRegistry.addSvgIcon(
      'menu-minimize',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu-minimize.svg`)
    );
    iconRegistry.addSvgIcon('elevator', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/equipment-family/elevator.svg`));
    iconRegistry.addSvgIcon('parking-door', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/equipment-family/parking-door.svg`));
    iconRegistry.addSvgIcon('energy', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/equipment-family/energy.svg`));
    iconRegistry.addSvgIcon('fire-safety', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/equipment-family/fire-safety.svg`));
    iconRegistry.addSvgIcon(
      'relay-antenna',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/relay-antenna.svg`)
    );
    iconRegistry.addSvgIcon('orga', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/orga.svg`));
    iconRegistry.addSvgIcon('visa', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/visa.svg`));
    iconRegistry.addSvgIcon('mastercard', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/mastercard.svg`));
    iconRegistry.addSvgIcon('thermometer', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/thermometer.svg`));
    iconRegistry.addSvgIcon('cloudy', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/cloudy.svg`));
    iconRegistry.addSvgIcon('sun', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/sun.svg`));
    iconRegistry.addSvgIcon('wind', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/wind.svg`));
    iconRegistry.addSvgIcon('2d', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/2d.svg`));
    iconRegistry.addSvgIcon('3d', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/3d.svg`));
  }
}
