/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { join } from 'lodash';

@Pipe({ name: 'join' })
export class JoinPipe implements PipeTransform {
  transform(data: any, separator: string = ', '): any {
    return join(data, separator);
  }
}
