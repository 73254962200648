/** @format */

import { PropertyMap } from 'model-mapper';
import { IException } from './exception.interface';

type FailedDependencyExceptionOrigin = 'serenite';

export class FailedDependencyExceptionError {
  @PropertyMap()
  public message: string;

  @PropertyMap()
  public status: 401;
}

export class FailedDependencyException implements IException {
  @PropertyMap()
  public origin: FailedDependencyExceptionOrigin;

  @PropertyMap({ type: FailedDependencyExceptionError })
  public error?: FailedDependencyExceptionError;

  public toHtml(): string {
    switch (this.origin) {
      case 'serenite':
        const message = $localize`Une erreur est survenue lors de l'appel à l'api sérénité 24h24.`;
        let error: string = this.error?.message || $localize`erreur inconnue, veuillez contacter un administrateur`;
        if (this.error?.status === 401)
          error = $localize`les informations d'identification sérénité 24h24 sont incorrect, veuillez contacter l'administrateur de votre organisation`;
        return `${message}</br><i>${error}</i>`;

      default:
        return $localize`Une erreur est survenue, si cela persiste veuillez contacter un administateur.`;
    }
  }
}
