/** @format */

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntersectionObserverModule } from 'ngx-intersection-observer';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { EquipmentPipeModule } from '../../_pipes/equipment/equipment.pipe.module';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';
import { AframeModule } from '../aframe/aframe.module';
import { ChartModule } from '../chart/chart.module';
import { AppCountUpModule } from '../count-up/count-up.module';
import { EquipmentFloorKindModule } from '../equipment-floor-kind/equipment-floor-kind.module';
import { AppLoaderModule } from '../loader/loader.module';
import { ElevatorIndicatorsComponent } from './elevator-indicators/elevator-indicators.component';
import { Elevatorlive2dComponent } from './elevator-live-2d/elevatorlive2d.component';
import { ElevatorLive3dComponent } from './elevator-live-3d/elevator-live3d.component';
import { ElevatorSceneFullscreenDialogDialog } from './elevator-scene-fullscreen-dialog/elevator-scene-fullscreen-dialog.dialog';
import { ElevatorSceneComponent } from './elevator-scene/elevator-scene.component';

@NgModule({
  declarations: [
    ElevatorSceneComponent,
    ElevatorSceneFullscreenDialogDialog,
    Elevatorlive2dComponent,
    ElevatorIndicatorsComponent,
    ElevatorLive3dComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    ChartModule,
    ExtentedPipesModule,
    AframeModule,
    IntersectionObserverModule,
    AppLoaderModule,
    AppCountUpModule,
    AppFlexLayoutModule,
    EquipmentFloorKindModule,
    MatTooltipModule,
    MatTabsModule,
    MatGridListModule,
    MatRippleModule,
    EquipmentPipeModule,
  ],
  exports: [
    ElevatorSceneComponent,
    ElevatorSceneFullscreenDialogDialog,
    Elevatorlive2dComponent,
    ElevatorLive3dComponent,
    ElevatorIndicatorsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ElevatorModule {}
