import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toDataUrl' })
export class ToDataUrlPipe implements PipeTransform {
  async transform(file: File): Promise<string | null> {
    if (!file) return null;
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(file);
    });
  }
}
