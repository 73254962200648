/** @format */

import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  appName: 'Sesio',
  production: true,
  region: 'eu-west-1',
  env: 'production',
  mapboxAccessToken: 'pk.eyJ1IjoiZWhlcnZlIiwiYSI6ImNsOHlrZGFlcDA5NmUzdnBrOHR3bHdxY3gifQ.1tU0KXJak2Dh1yqA6sOpZQ',
};
