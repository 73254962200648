/** @format */

import { PropertyMap } from 'model-mapper';
import { Module } from '../_constants/module';
import { Searchable } from '../_decorators/searchable-class.decorator';
import { embeddedSerialize, s3PathPropertyMap } from '../_helpers/class.helper';
import { Archived } from './archived.class';
import { EmbeddedCustomerAccount } from './customer-account.embedded.class';
import { ExternalReferences } from './external-references.class';
import { Location } from './location.class';
import { OrganizationSettings } from './organization-settings.class';
import { S3File } from './s3-file.class';
@Searchable({ sortFields: 'name' })
export class Organization {
  @PropertyMap()
  _id: string;

  @PropertyMap({ type: EmbeddedCustomerAccount, serialize: embeddedSerialize })
  customerAccount: EmbeddedCustomerAccount;

  @PropertyMap({ type: ExternalReferences })
  externalReferences?: ExternalReferences;

  @PropertyMap()
  name: string;

  @PropertyMap(s3PathPropertyMap)
  s3Path: string;

  @PropertyMap({ type: S3File })
  logo: S3File;

  @PropertyMap({ type: Location })
  location: Location;

  @PropertyMap()
  creator: string;

  @PropertyMap({ default: [] })
  modules: Module[];

  @PropertyMap() // in octets
  edmUsed: number;

  @PropertyMap({ type: OrganizationSettings })
  settings: OrganizationSettings;

  @PropertyMap({ type: Archived })
  __archived: Archived;
}
