/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IntersectionObserverModule } from 'ngx-intersection-observer';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';
import { AppCountUpModule } from '../count-up/count-up.module';
import { ElevatorInfoModule } from '../elevator-info/elevator-info.module';
import { AppLoaderModule } from '../loader/loader.module';
import { UserNotificationsDialogComponent } from './user-notifications-dialog.component';

@NgModule({
  declarations: [UserNotificationsDialogComponent],
  imports: [
    CommonModule,
    AppFlexLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    IntersectionObserverModule,
    MatListModule,
    MatRippleModule,
    AppCountUpModule,
    ElevatorInfoModule,
    MatDialogModule,
    MatToolbarModule,
    AppLoaderModule,
    ExtentedPipesModule,
  ],
  exports: [UserNotificationsDialogComponent],
})
export class UserNotificationDialogModule {}
