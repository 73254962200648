/** @format */

import { map, values } from 'lodash-es';
import { DoorEventState } from '../../_services/mqtt/node-mqtt.interface';
import { EquipmentDoorName } from './equipment-door-name';

export enum EquipmentDoorState {
  OPEN = 'open',
  CLOSED = 'closed',
  UNKNOWN = 'unknown',
  ERROR = 'error',
}

export const EquipmentDoorStateName: { [key: string]: string } = {
  [EquipmentDoorState.OPEN]: $localize`Porte ouverte`,
  [EquipmentDoorState.CLOSED]: $localize`Porte fermée`,
  [EquipmentDoorState.UNKNOWN]: $localize`Porte en mouvement`,
  [EquipmentDoorState.ERROR]: $localize`Porte en erreur`,
};

export const EquipmentDoorStateOptions = map(values(EquipmentDoorState), (value) => ({
  value,
  name: EquipmentDoorStateName[value],
}));

export const EquipmentDoorStateDatagridOptions = map(values(EquipmentDoorState), (value) => ({
  name: EquipmentDoorStateName[value],
  value: value,
}));

export const EquipmentDoorStateNameLabel = {
  [EquipmentDoorName.DOOR_1]: {
    [EquipmentDoorState.OPEN]: $localize`<span light>Porte de service 1</span> ouverte`,
    [EquipmentDoorState.CLOSED]: $localize`<span light>Porte de service 1</span> fermée`,
    [EquipmentDoorState.UNKNOWN]: $localize`<span light>Porte de service 1</span> en mouvement`,
    [EquipmentDoorState.ERROR]: $localize`<span light>Porte de service 1</span> en erreur`,
  },
  [EquipmentDoorName.DOOR_2]: {
    [EquipmentDoorState.OPEN]: $localize`<span light>Porte de service 2</span> ouverte`,
    [EquipmentDoorState.CLOSED]: $localize`<span light>Porte de service 2</span> fermée`,
    [EquipmentDoorState.UNKNOWN]: $localize`<span light>Porte de service 2</span> en mouvement`,
    [EquipmentDoorState.ERROR]: $localize`<span light>Porte de service 2</span> en erreur`,
  },
};

export function getEquipmentDoorState(state: DoorEventState): EquipmentDoorState | null {
  switch (state) {
    case DoorEventState.ERROR:
      return EquipmentDoorState.ERROR;
    case DoorEventState.UNKNOWN:
      return EquipmentDoorState.UNKNOWN;
    case DoorEventState.OPEN:
      return EquipmentDoorState.OPEN;
    case DoorEventState.CLOSED:
      return EquipmentDoorState.CLOSED;
    default:
      return null;
  }
}
