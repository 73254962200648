/** @format */

import { PropertyMap } from 'model-mapper';
import { Searchable, SearchableClass } from '../_decorators/searchable-class.decorator';
import { EmbeddedEquipmentCategory } from './equipment-category.embedded.class';

@Searchable({ sortFields: 'category.family category.name name' })
export class EmbeddedEquipmentKind extends SearchableClass {
  @PropertyMap()
  _id: string;

  @PropertyMap({ type: EmbeddedEquipmentCategory })
  category: EmbeddedEquipmentCategory;

  @PropertyMap()
  name: string;
}
