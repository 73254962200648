/** @format */

import { PropertyMap } from 'model-mapper';
import { RealEstateStructureFamily } from '../_constants/real-estate-structure-family';
import { Color } from './color.class';
import { EmbeddedOrganizationalUnitKind } from './organizational-unit-kind.embedded.class';

export class EmbeddedRealEstateStructureKind {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  family: RealEstateStructureFamily;

  @PropertyMap()
  name: string;

  @PropertyMap({ type: Color })
  color: Color;

  @PropertyMap()
  parents: (string | null)[];

  @PropertyMap({ type: EmbeddedOrganizationalUnitKind })
  organizationalUnitKind: EmbeddedOrganizationalUnitKind;
}
