/** @format */

import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { fade } from '../../_constants/animations';

export type NotificationLevel = null | 'info' | 'success' | 'error';

export type NotificationStatus = 'queued' | 'wip' | 'done' | 'error';

export interface INotificationData {
  level?: NotificationLevel;
  message: string;
  color?: string;
  icon?: string;
  duration?: number;

  status?: NotificationStatus;
  progress?: number;
  error?: string;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [fade],
})
export class NotificationComponent implements OnInit {
  notifications: INotificationData[] = [];

  constructor(public snackBarRef: MatSnackBarRef<NotificationComponent>) {}

  ngOnInit() {}

  removeNotification(index: number) {
    this.notifications.splice(index, 1);
    if (this.notifications.length === 0) this.snackBarRef.dismiss();
  }
}
