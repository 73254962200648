/** @format */

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { head, isEqual, last, split } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public currentUrl: string;
  public targetUrl: string | null;
  public isOrganizationScoped = false;

  private history: string[] = [];

  constructor(
    private router: Router,
    private location: Location,
  ) {
    this.trackNavigation();
  }

  public back(): void {
    this.history.pop();
    if (this.history.length > 0) this.navigate(last(this.history)!);
    else this.navigate('/');
  }

  public navigate(commands: string, extras?: NavigationExtras): Promise<boolean>;
  public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean>;
  public navigate(commands: any, extras?: NavigationExtras): Promise<boolean> {
    if (typeof commands === 'string') return this.router.navigateByUrl(commands, extras);
    return this.router.navigate(commands, extras);
  }

  private trackNavigation() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.targetUrl = event.url;
        this.isOrganizationScoped = !this.targetUrl.startsWith('/administration/sesio/');
      }
      if (event instanceof NavigationEnd) {
        this.targetUrl = null;
        this.currentUrl = event.urlAfterRedirects;
        this.isOrganizationScoped = !this.currentUrl.startsWith('/administration/sesio/');
      }
    });
    this.location.onUrlChange((url, state): any => {
      const lastUrl = last(this.history);
      if (!lastUrl) return this.history.push(url);
      const lastPath = head(split(lastUrl, '?'))!;
      const path = head(split(url, '?'))!;
      if (isEqual(lastPath, path)) this.history.pop();
      this.history.push(url);
    });
  }
}
