/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { OrganizationalUnitCellComponent } from './organizational-unit-cell.component';

@NgModule({
  declarations: [OrganizationalUnitCellComponent],
  imports: [CommonModule, AppFlexLayoutModule],
  exports: [OrganizationalUnitCellComponent],
})
export class OrganizationalUnitCellModule {}
