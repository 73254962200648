/** @format */

import { Injectable } from '@angular/core';
import { isEqual, isNil } from 'lodash-es';
import { skip } from 'rxjs';
import { Organization } from '../_classes/organization.class';
import { SessionData } from '../app.session.data';
import { MethodLogger } from './logger.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private ORGANIZATION_STORAGE_KEY = 'currentOrganization';

  constructor(private storageService: StorageService) {
    this.init();
  }

  $locale = SessionData.$locale;
  @MethodLogger()
  setLocale(value: string) {
    if (SessionData.$locale.value !== value) SessionData.$locale.next(value);
  }

  $organization = SessionData.$organization;
  @MethodLogger()
  setOrganization(value: Organization | null | undefined) {
    if (isEqual(SessionData.$organization.value, value)) return;
    if (isNil(SessionData.$organization.value) && isNil(value)) return;
    SessionData.$organization.next(value);
  }

  $organizations = SessionData.$organizations;
  @MethodLogger()
  setOrganizations(value: Organization[]) {
    if (isEqual(SessionData.$organizations.value, value)) return;
    SessionData.$organizations.next(value);
  }

  @MethodLogger()
  public async clearSession() {
    SessionData.$organizations.next([]);
    SessionData.$organization.next(null);
  }

  public async load() {
    return this.storageService.get(this.ORGANIZATION_STORAGE_KEY).then((data) => this.setOrganization(data));
  }

  private init() {
    SessionData.$organization.pipe(skip(1)).subscribe((data) => {
      if (data) this.storageService.set(this.ORGANIZATION_STORAGE_KEY, data);
      else this.storageService.remove(this.ORGANIZATION_STORAGE_KEY);
    });
  }
}
