import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Elevator } from '../../../_classes/equipment/equipment.elevator.class';
import { ElevatorSceneComponent } from '../elevator-scene/elevator-scene.component';

interface IData {
  elevator: Elevator;
  fullscreen: boolean;
}

@Component({
  selector: 'app-elevator-scene-fullscreen-dialog',
  templateUrl: './elevator-scene-fullscreen-dialog.dialog.html',
  styleUrls: ['./elevator-scene-fullscreen-dialog.dialog.scss']
})
export class ElevatorSceneFullscreenDialogDialog implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ElevatorSceneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IData

  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
