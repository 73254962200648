/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getEntityProperty = /* GraphQL */ `query GetEntityProperty($id: ID!) {
  getEntityProperty(id: $id) {
    id
    entityID
    entityModel
    time
    propertyName
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEntityPropertyQueryVariables,
  APITypes.GetEntityPropertyQuery
>;
export const listEntityProperties = /* GraphQL */ `query ListEntityProperties(
  $filter: ModelEntityPropertyFilterInput
  $limit: Int
  $nextToken: String
) {
  listEntityProperties(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      entityID
      entityModel
      time
      propertyName
      value
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEntityPropertiesQueryVariables,
  APITypes.ListEntityPropertiesQuery
>;
export const entityPropertyByEntityId = /* GraphQL */ `query EntityPropertyByEntityId(
  $entityID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEntityPropertyFilterInput
  $limit: Int
  $nextToken: String
) {
  entityPropertyByEntityId(
    entityID: $entityID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      entityID
      entityModel
      time
      propertyName
      value
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EntityPropertyByEntityIdQueryVariables,
  APITypes.EntityPropertyByEntityIdQuery
>;
export const entityPropertyByPropertyName = /* GraphQL */ `query EntityPropertyByPropertyName(
  $propertyName: String!
  $sortDirection: ModelSortDirection
  $filter: ModelEntityPropertyFilterInput
  $limit: Int
  $nextToken: String
) {
  entityPropertyByPropertyName(
    propertyName: $propertyName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      entityID
      entityModel
      time
      propertyName
      value
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EntityPropertyByPropertyNameQueryVariables,
  APITypes.EntityPropertyByPropertyNameQuery
>;
export const getCacheChanged = /* GraphQL */ `query GetCacheChanged($id: ID!) {
  getCacheChanged(id: $id) {
    id
    name
    time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCacheChangedQueryVariables,
  APITypes.GetCacheChangedQuery
>;
export const listCacheChangeds = /* GraphQL */ `query ListCacheChangeds(
  $filter: ModelCacheChangedFilterInput
  $limit: Int
  $nextToken: String
) {
  listCacheChangeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      time
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCacheChangedsQueryVariables,
  APITypes.ListCacheChangedsQuery
>;
export const cacheByName = /* GraphQL */ `query CacheByName(
  $name: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCacheChangedFilterInput
  $limit: Int
  $nextToken: String
) {
  cacheByName(
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      time
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CacheByNameQueryVariables,
  APITypes.CacheByNameQuery
>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    id
    owner
    time
    kind
    read
    context
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      time
      kind
      read
      context
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const notificationsByUser = /* GraphQL */ `query NotificationsByUser(
  $owner: ID!
  $time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByUser(
    owner: $owner
    time: $time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      time
      kind
      read
      context
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationsByUserQueryVariables,
  APITypes.NotificationsByUserQuery
>;
