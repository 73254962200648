/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HumanizeDurationComponent } from './humanize-duration.component';
import { AppLoaderModule } from '../loader/loader.module';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';

@NgModule({
  imports: [CommonModule, AppFlexLayoutModule, AppLoaderModule],
  declarations: [HumanizeDurationComponent],
  exports: [HumanizeDurationComponent],
})
export class AppHumanizeDurationModule {}
