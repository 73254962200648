/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PicturGridComponent } from './picture-grid.component';
import { AppTranslateModule } from '../../app-translate.module';
import { AwsPipesModule } from '../../_pipes/aws-pipes/aws-pipes.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { GalleryModule } from '../gallery/gallery.module';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';
import { MatDialogModule } from '@angular/material/dialog';
import { IntersectionObserverModule } from 'ngx-intersection-observer';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';

@NgModule({
  imports: [
    CommonModule,
    AppTranslateModule,
    ExtentedPipesModule,
    AwsPipesModule,
    MatGridListModule,
    AppFlexLayoutModule,
    GalleryModule,
    MatDialogModule,
    IntersectionObserverModule,
  ],
  declarations: [PicturGridComponent],
  exports: [PicturGridComponent],
})
export class PictureGridModule {}
