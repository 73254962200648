/** @format */

import { map, values } from 'lodash-es';

export enum Module {
  ELEVATOR = 'elevator',
  PARKING_DOOR = 'parking-door',
  ENERGY = 'energy',
  RELAY_ANTENNA = 'relay-antenna',
  FIRE_SAFETY = 'fire-safety',
}

export const ModuleName: { [module: string]: string } = {
  [Module.ELEVATOR]: $localize`Ascenseur`,
  [Module.PARKING_DOOR]: $localize`Porte de parking`,
  [Module.ENERGY]: $localize`Énergie`,
  [Module.RELAY_ANTENNA]: $localize`Antenne relais`,
  [Module.FIRE_SAFETY]: $localize`Sécurité incendie`,
};

export const ModuleDescription = {
  [Module.ELEVATOR]: $localize`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  [Module.PARKING_DOOR]: $localize`Integer lorem ligula, dignissim nec varius id, maximus quis augue. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus sit amet purus mauris. Aliquam vehicula id metus eget suscipit. Etiam sagittis turpis non augue maximus mattis nec non lorem. Suspendisse sodales quis urna eget lacinia. Pellentesque sem sem, molestie at vulputate eget, bibendum auctor ante. Curabitur sit amet est nisl. Etiam nec lacus non elit pellentesque vestibulum ut eu sapien.`,
  [Module.ENERGY]: $localize`Suspendisse potenti. Morbi ullamcorper neque nec varius pretium. Proin dui odio, fringilla sed eros accumsan, mollis lobortis ligula. Suspendisse at nibh varius, auctor odio ac, lobortis felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vel rhoncus ex, id auctor elit. In pharetra orci ac consectetur suscipit. Aenean volutpat mi at turpis bibendum pulvinar. Nam feugiat enim ut est tempor, et gravida nunc egestas. Suspendisse potenti. Sed ac aliquam nisl. Nulla efficitur augue id lectus tincidunt interdum. Etiam tempus dolor quis sapien tempus molestie.`,
  [Module.RELAY_ANTENNA]: $localize`Nullam dapibus massa lorem, id auctor risus dictum vitae. Curabitur nisl ex, gravida ut enim non, bibendum rhoncus velit. Etiam quis dolor vitae nibh condimentum sollicitudin molestie eget metus. Proin lectus magna, laoreet vitae varius ac, fringilla quis ligula. Ut laoreet eget leo a vestibulum. Curabitur lobortis orci sit amet vehicula posuere. Aenean velit felis, maximus et congue sed, faucibus a lorem. Curabitur interdum justo quam, at gravida lorem scelerisque sed. Duis varius justo a purus auctor semper. Sed quis sodales urna, a faucibus magna. In hac habitasse platea dictumst.`,
  [Module.FIRE_SAFETY]: $localize`Nulla facilisi. Pellentesque pretium faucibus porta. Morbi in felis erat. Phasellus et ipsum eu nulla iaculis maximus id vel lectus. Praesent id nisl lacus. Duis iaculis lacinia lacus aliquet elementum. Proin at nisi pretium, sollicitudin velit vel, elementum diam. Sed feugiat tellus nec mollis hendrerit. Nunc eu aliquam neque, ac vestibulum arcu. Aenean sed turpis lacus. Sed scelerisque vulputate cursus. Donec dictum velit odio, ut semper tellus gravida vitae. Sed eget risus diam. Ut congue vitae nibh id blandit. Cras ac orci gravida, euismod purus non, rutrum lacus.`,
};

export const ModuleOptions = map(values(Module), (value) => ({
  name: ModuleName[value],
  value,
  color: `var(--app-color-${value})`,
  prefixSvgIcon: value,
}));

export const ModuleDatagridOptions = map(values(Module), (value) => ({
  name: ModuleName[value],
  value,
  icon: { name: value, color: `var(--app-color-${value})`, svg: true },
}));
