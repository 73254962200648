/** @format */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from 'aws-amplify';
import { get, map as lmap } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import moment from 'moment';
import { map, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { S3File } from '../_classes/s3-file.class';
import { ITaskNotificationOptions, NotificationService } from '../_components/notification/notification.service';
import { SessionService } from './session.service';

export type FileUploadProgressDataStatus = 'new' | 'progress' | 'done';
export type FileUploadProgressData = {
  filename: string;
  loaded: number;
  total: number;
  status: FileUploadProgressDataStatus;
};
export type FileUploadProgress = BehaviorSubject<FileUploadProgressData>;
export interface IGetFileUrlOptions {
  level: 'public';
}
@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private notificationService: NotificationService,
  ) {}

  public async getFileContent(file: S3File | string, options?: IGetFileUrlOptions): Promise<Blob> {
    const key = typeof file === 'string' ? file : file.key;
    return Storage.get(key, { level: options?.level || 'public', download: true }).then((result: any) => result?.Body);
  }

  public async getFilesUrl(prefix: string, options?: IGetFileUrlOptions): Promise<string[]> {
    return Promise.all(
      lmap(get(await Storage.list(prefix, { level: options?.level || 'public', pageSize: 'ALL' }), 'results'), (elmt) =>
        Storage.get(get(elmt, 'key')!, { level: options?.level || 'public' }),
      ),
    );
  }

  public async getFileUrl(file: S3File, options?: IGetFileUrlOptions): Promise<string> {
    return Storage.get(file.key, { level: options?.level || 'public' });
  }

  public async pushFile(path: string, file: File): Promise<S3File> {
    // const fileProgress = new BehaviorSubject<FileUploadProgressData>({
    //   status: 'new',
    //   filename: file.name,
    //   loaded: 0,
    //   total: file.size,
    // });
    // await this.notificationService.filesUpload(fileProgress);
    const task: ITaskNotificationOptions = {
      message: $localize`Téléchargement du fichier ${file.name}`,
      status: 'queued',
    };
    this.notificationService.addTask(task);
    try {
      const key = `${this.sessionService.$organization.value?.s3Path}/${path}`;
      await Storage.put(key, file, {
        contentType: file.type,
        progressCallback(data: any) {
          task.status = 'wip';
          task.progress = data.loaded / data.total;
          // fileProgress.next({ filename: file.name, loaded: data.loaded, total: data.total, status: 'progress' });
        },
      });
      task.status = 'done';
      task.progress = 1;
      return new ModelMapper(S3File).map({
        key,
        filename: file.name,
        name: file.name,
        size: file.size,
        type: file.type,
        uploadDate: moment(),
      });
    } catch (err: any) {
      task.status = 'error';
      task.error = err.message;
      throw err;
      // } finally {
      // fileProgress.next({
      //   filename: file.name,
      //   loaded: fileProgress.value.loaded,
      //   total: fileProgress.value.total,
      //   status: 'done',
      // });
      // fileProgress.complete();
    }
  }
  public async deleteFile(key: string): Promise<boolean> {
    return await Storage.remove(key).then((res) => !!res);
  }

  public getJsonFromFile(file: File): Observable<any> {
    return new Observable((subscriber) => {
      let fileReader: FileReader = new FileReader();
      fileReader.onloadend = function (x) {
        try {
          subscriber.next(JSON.parse(fileReader.result as string));
        } catch (err) {
          subscriber.error(err);
        }
        subscriber.complete();
      };
      fileReader.readAsText(file);
    });
  }

  public getJsonFromUrl(url: string): Observable<any> {
    return this.http.get<any>(url).pipe(map((data) => (typeof data === 'string' ? JSON.parse(data) : data)));
  }

  public getBase64FromUrl(url: string): Observable<string> {
    return this.http
      .get(url, {
        observe: 'body',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((arrayBuffer) =>
          btoa(
            Array.from(new Uint8Array(arrayBuffer))
              .map((b) => String.fromCharCode(b))
              .join(''),
          ),
        ),
      );
  }
}
