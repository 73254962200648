/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisponibilityRateLabelComponent } from './disponibility-rate-label.component';
import { ColorPipeModule } from '../../_pipes/color-pipe/color-pipe.module';
import { AppCountUpModule } from '../count-up/count-up.module';

@NgModule({
  declarations: [DisponibilityRateLabelComponent],
  imports: [CommonModule, ColorPipeModule, AppCountUpModule],
  exports: [DisponibilityRateLabelComponent],
})
export class DisponibilityRateLabelModule {}
