/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { merge } from 'lodash-es';

@Pipe({ name: 'merge' })
export class MergePipe implements PipeTransform {
  transform(data: any, toMergeData: any): any {
    return merge(data, toMergeData);
  }
}
