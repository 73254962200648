/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash';

@Pipe({ name: 'find' })
export class FindPipe implements PipeTransform {
  transform(data: any[] | null | undefined, filter: any): any {
    if (typeof filter === 'string') return find(data, (d) => d === filter);
    return find(data, filter);
  }
}
