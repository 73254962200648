/** @format */

import { PropertyMap } from 'model-mapper';
import { ConsumptionEntryKind } from '../_constants/consumption-entry-kind';
import { S3File } from './s3-file.class';

export class ProductionEntry {
  @PropertyMap()
  kind: ConsumptionEntryKind;

  @PropertyMap()
  value: number;

  @PropertyMap({ type: S3File })
  file?: S3File;
}

export class Production {
  @PropertyMap()
  year: number;

  @PropertyMap()
  value: number;

  @PropertyMap({ type: [ProductionEntry] })
  entries: ProductionEntry[];
}
