/** @format */

import { PropertyMap } from 'model-mapper';

export class ExternalReferences {
  @PropertyMap()
  sereniteReference: number;

  @PropertyMap()
  isSereniteEnabled: boolean;
}
